import {
  formatGatewayError,
  formatAxiosError,
  formatPlayerError,
  formatGenericError
} from './format/format'

export default {
  /**
   * Set Player Error
   * @param getters
   * @param dispatch
   * @param commit
   * @param rootGetters
   * @param error
   * @param bypass
   */
  setPlayerError ({ getters, dispatch, commit, rootGetters }, { error, bypass = false }) {
    const playerError = formatPlayerError(error, {
      portalVersion: this.$config.appVersion,
      nbInSession: getters.getErrorCount + 1,
      ...rootGetters['route/getPageInfo']
    })

    if (!bypass) {
      commit('setError', playerError)
    }

    dispatch('user/postErrorLogs', { error: playerError }, { root: true })
  },
  /**
   * Set Axios Error
   * @param getters
   * @param dispatch
   * @param commit
   * @param rootGetters
   * @param error
   * @param bypass
   */
  setAxiosError ({ getters, dispatch, commit, rootGetters }, { error, bypass = false }) {
    const axiosError = formatAxiosError(error, {
      portalVersion: this.$config.appVersion,
      nbInSession: getters.getErrorCount + 1,
      ...rootGetters['route/getPageInfo']
    })

    if (!bypass) {
      commit('setError', axiosError)
    }

    dispatch('user/postErrorLogs', { error: axiosError }, { root: true })
  },
  /**
   * Set Gateway Error
   * @param getters
   * @param dispatch
   * @param commit
   * @param rootGetters
   * @param error
   * @param bypass
   */
  setGatewayError ({ getters, dispatch, commit, rootGetters }, { error, bypass = false }) {
    const gatewayError = formatGatewayError(error, {
      portalVersion: this.$config.appVersion,
      nbInSession: getters.getErrorCount + 1,
      ...rootGetters['route/getPageInfo']
    })

    if (!bypass) {
      commit('setError', gatewayError)
    }

    dispatch('user/postErrorLogs', { error: gatewayError }, { root: true })
  },
  /**
   * Set Generic Error
   * @param getters
   * @param dispatch
   * @param commit
   * @param rootGetters
   * @param error
   * @param bypass
   */
  setGenericError ({ getters, dispatch, commit, rootGetters }, { error, bypass = false }) {
    const genericError = formatGenericError(error, {
      portalVersion: this.$config.appVersion,
      nbInSession: getters.getErrorCount + 1,
      ...rootGetters['route/getPageInfo']
    })

    if (!bypass) {
      commit('setError', genericError)
    }

    dispatch('user/postErrorLogs', { error: genericError }, { root: true })
  }
}
