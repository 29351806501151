import urlJoin from 'url-join'
import _get from 'lodash/get'

export default {
  /**
   * Fetch channels from TV Gateway
   * @param everywherePopulation
   * @param devices
   * @returns {Q.Promise<never>}
   */
  getChannels (everywherePopulation = 'OTT_Metro', devices = null) {
    const path = urlJoin(_get(this.$config, 'confetti.public.api.gateway.paths.channels'))
    const options = {
      params: {
        everywherePopulation,
        devices
      },
      cache: {
        exclude: { query: false }
      }
    }
    const method = 'api.gateway.channels.getChannels()'

    return this.$http.gateway.pds.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method, type: this.$httpUtils.API_TYPE.GATEWAY }))
  }
}
