import general from './general'
import accessibilite from './accessibilite'
import home from './home'
import evening from './evening'
import programs from './programs'
import player from './player'
import fip from './fip'
import errors from './errors'
import headerMessages from './headerMessages'
import modals from './modals'
import freeAccessChannel from './freeAccessChannel'
import npvr from './npvr'
import grid from './grid'
import socialMetaTags from './socialMetaTags'

export default {
  general,
  accessibilite,
  home,
  evening,
  programs,
  player,
  fip,
  modals,
  errors,
  headerMessages,
  freeAccessChannel,
  npvr,
  grid,
  socialMetaTags
}
