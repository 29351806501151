import _get from 'lodash/get'
import { DateTime } from 'luxon'
import npvrConstants from '../../components/npvr/shared/npvr.config'
import { isLocked } from '../../components/live/shared/bouquets/bouquets.utils'

/**
 * check if program and recording match with their ids
 * @param {*} program
 * @param {*} recording
 * @returns
 */
function _isRecordingMatchingByProgram (program, recording) {
  const programId = program.id?.toString()
  return recording.programId === programId
}

function _isProgramCoveredByRecordingTimelapse (program, recording) {
  const progStart = program.diffusionDate * 1000 // program's start time in ms
  const progEnd = progStart + (program.duration * 1000) // program's end time in ms
  const recordStart = recording.startTime // recording's start time in ms
  const recordEnd = recording.endTime // recording's end time in ms
  const now = Date.now() // currentTime in ms
  return (progStart > 0 && progStart >= recordStart && progStart < recordEnd) ||
    (progEnd > 0 && progEnd > recordStart && progEnd <= recordEnd) ||
    (progStart <= 0 && now >= recordStart && now <= recordEnd) // specific case for timeBased without program (trace urban)
}

/**
 * check if program and recording match with their channel id and if recording timelapse covers program
 * @param {*} program
 * @param {*} channel
 * @param {*} recording
 * @returns
 */
function _isRecordingMatchingByChannel (program, channel, recording) {
  const channelId = channel.technicalChannels?.live?.[0]?.usi
  const recordingChannelId = recording.channel?.liveChannelUsi
  return channelId === recordingChannelId &&
    _isProgramCoveredByRecordingTimelapse(program, recording)
}

export default {
  /**
   * Returns user information
   * @param {*} state Store state
   */
  getUser (state) {
    return state.user
  },
  /**
   * returns the user's customerOrangePopulation information
   * @param {*} state
   */
  getCustomerOrangePopulation (state) {
    return state.user.customerOrangePopulation
  },
  /**
   * Returns raw data for recordings status.
   * Use getStatusSafe instead for pre-checked values.
   * @param {*} state Store state
   */
  getStatusRaw (state) {
    return state.status
  },
  /**
   * Returns pre-checked recordings status data or null if data is not safe.
   * @param {*} state Store state
   */
  getStatusSafe (state) {
    const hasRecorded = !isNaN(state.status.recorded) && state.status.recorded >= 0
    const hasScheduled = !isNaN(state.status.scheduled) && state.status.scheduled >= 0
    const hasSubscribed = !isNaN(state.status.subscribed) && state.status.subscribed > 0

    return hasRecorded && hasScheduled && hasSubscribed ? state.status : null
  },
  /**
   * Returns the storage space used in percentage.
   * @param {*} state Store state
   * @param {*} getters Npvr getters
   */
  getSpaceUsed (state, getters) {
    const statusSafe = getters.getStatusSafe
    return statusSafe ? ((statusSafe.recorded + statusSafe.scheduled) / statusSafe.subscribed) * 100 : null
  },
  /**
   * Return recordings
   * @param state
   * @returns {*}
   */
  getRecordings (state) {
    return state.recordings
  },
  /**
   * return layout from wich we're comming
   * @param state
   * @param fromLayout
   */
  getFromLayout (state) {
    return state.fromLayout
  },

  /**
   * Return filtered available recordings
   * @param state
   * @returns {*}
   */
  getAvailableRecordings (state) {
    return state.recordings
      .filter(recording => recording.status === npvrConstants.RECORDING_STATE.IN_RECORDING || recording.status === npvrConstants.RECORDING_STATE.RECORDED)
      .sort((a, b) => DateTime.fromMillis(b.startTime) - DateTime.fromMillis(a.startTime))
  },

  /**
   * Return filtered scheduled recordings
   * @param state
   * @returns {T[]}
   */
  getScheduledRecordings (state) {
    return state.recordings
      .filter(recording => recording.status === npvrConstants.RECORDING_STATE.FUTURE_RECORDING)
      .sort((a, b) => DateTime.fromMillis(a.startTime) - DateTime.fromMillis(b.startTime))
  },

  /**
   * Check npvr subscription
   * @param state
   * @returns {boolean}
   */
  isNpvrSubscribed (state) {
    return _get(state, 'user.rights.options', []).some(option => npvrConstants.NPVR_OPTIONS.includes(option))
  },

  /**
   * check if we already have the recordings in store
   * @param {*} state
   */
  recordingsHaveBeenCalled (state) {
    return state.recordings.length > 0
  },
  /**
   * check if all playHistory have already been called
   * @param {*} state
   */
  playHistoryHaveBeenCalled (state) {
    return state.playHistoryHaveBeenCalled
  },
  /**
   * get the given program or channel the associated recording
   * @param {*} state
   * @param {String} programId
   * @param {String} channelId
   * @returns {Object}
   */
  getProgramRecording (state) {
    return (program, channel) => {
      const recording = state.recordings?.find((recording) => {
        return recording.type === npvrConstants.RECORDING_TYPES.EPGBASED
          ? _isRecordingMatchingByProgram(program, recording)
          : _isRecordingMatchingByChannel(program, channel, recording)
      })
      return recording
    }
  },
  /**
   * Check if not subscribed for a specific channel
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @returns {function(*=): *}
   */
  notSubscribed (state, getters, rootState, rootGetters) {
    return channel => (
      channel &&
      !!rootGetters['core/getProfile'].isAuthenticated &&
      isLocked(getters.getUserPackages, channel.packages)
    )
  },
  /**
   * Get all packages from rights
   * @param state
   */
  getUserPackages (state) {
    return _get(state, 'user.rights.packages', [])
  }
}
