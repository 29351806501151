import { makeCompteOneRedirectUrl } from '../../../shared/utils/compteOneRedirect.utils'

/**
 * Permet de savoir si une chaine est souscrite ou pas
 * @param {[]} userBouquets
 * @param {[]} channelBouquets
 * @returns {boolean}
 */
export function isLocked (userBouquets, channelBouquets) {
  return !channelBouquets.some(channelBouquet => userBouquets.includes(channelBouquet))
}

/**
 * Renvoi le bon lien vers la boutique pour un bouquet commercial
 * @param {boolean} isSosh
 * @param mco
 * @param isMobile
 * @param extInstanceId
 * @param editoChannelId
 * @param confetti
 * @returns {string}
 */
export function getUrlBoutique (isSosh, mco, isMobile, extInstanceId, editoChannelId, confetti) {
  const urlBoutiqueCompteOne = confetti.public.boutique.urlBoutiqueCompteOne

  if (isMobile) {
    const shopUrl = isSosh ? confetti.public.boutique.shopMobileSosh : confetti.public.boutique.shopMobileOrange
    return makeCompteOneRedirectUrl(urlBoutiqueCompteOne, shopUrl, extInstanceId, false)
  } else {
    if (mco === 'OCA') {
      return isSosh ? confetti.public.boutique.shopInternetSoshOCAorORE : confetti.public.boutique.shopInternetOrangeOCA
    }
    if (mco === 'ORE') {
      return isSosh ? confetti.public.boutique.shopInternetSoshOCAorORE : confetti.public.boutique.shopInternetOrangeORE
    }

    const shopUrl = isSosh ? confetti.public.boutique.shopInternetSosh + editoChannelId : confetti.public.boutique.shopInternetOrange + editoChannelId
    return makeCompteOneRedirectUrl(urlBoutiqueCompteOne, shopUrl, extInstanceId)
  }
}
