import utils from './utils'

function onClickShowMore (page, zone) {
  return {
    track_page: page,
    track_zone: zone,
    track_nom: 'voir_plus'
  }
}

/**
 * Assistance Stats
 * @param page
 * @returns {{track_page: string, track_zone: string, track_nom: string}}
 */
function onClickAssistance (page) {
  return {
    track_page: utils.getTrackPage(page),
    track_zone: 'assistance_sav',
    track_nom: 'lien'
  }
}

/**
 * On Scroll top
 * @param page
 * @returns {{track_page: string, track_zone: string, track_nom: string}}
 */
function onClickScrollTopButton (page) {
  let trackZone

  switch (page) {
    case 'home':
    case 'ce-soir':
    case 'enregistrements-disponibles':
    case 'enregistrements-programmes':
      trackZone = 'mosaique'
      break
    case 'programmes':
      trackZone = 'grille'
      break
  }

  return {
    track_page: utils.getTrackPage(page),
    track_zone: trackZone,
    track_nom: 'retour_haut'
  }
}

/**
 * When Out of Eu modal is open
 * @returns {{area: string, cible: string, zone: string, page: string, type: string}}
 */
function onTriggerOutOfEu () {
  return {
    page: 'toutes_pages',
    zone: 'blocage_ue',
    area: 'popin',
    cible: '',
    type: 'affichage'
  }
}

function onTriggerStbOnly (channel) {
  return {
    page: 'toutes_pages',
    zone: 'popin',
    area: 'blocage-non_droit-stb',
    cible: channel.idEPG + '-' + channel.name,
    type: 'affichage'
  }
}

/**
 * Click on help in Out of EU
 * @param $config
 * @returns {{track_page: string, track_cible: (string), track_zone: string, track_nom: string, track_type: string}}
 */
function onClickOutOfEuHelp (supportUrl) {
  return {
    track_page: 'toutes_pages',
    track_zone: 'blocage_ue',
    track_nom: 'popin',
    track_type: 'clic-assist',
    track_cible: supportUrl
  }
}

function onClickICloudPrivateRelayHelp (supportUrl) {
  return {
    track_page: 'all',
    track_zone: 'popin',
    track_nom: 'blocage_ue',
    track_type: 'clic-relais_prive',
    track_cible: supportUrl,
    track_detail: 'clic-relais_prive-blocage_ue-popin-all'
  }
}

/**
 * When authentication modal opens
 * @returns {{area: string, zone: string, page: string, type: string}}
 */
function onTriggerNotAuthenticated () {
  return {
    page: 'npvr',
    zone: 'popin',
    area: 'authent',
    type: 'affichage'
  }
}

/**
 * Click on redirect in authentication modal
 * @returns {{track_page: string, track_zone: string, track_nom: string, track_type: string}}
 */
function onClickNotAuthenticatedRedirect () {
  return {
    track_page: 'npvr',
    track_nom: 'authent',
    track_zone: 'popin',
    track_type: 'valider'
  }
}

function onClickCancelRecording () {
  return {
    track_page: 'npvr',
    track_nom: 'modifier_horaire_fin',
    track_zone: 'popin',
    track_type: 'clic_annuler'
  }
}

function onClickConfirmRecording () {
  return {
    track_page: 'npvr',
    track_nom: 'modifier_horaire_fin',
    track_zone: 'popin',
    track_type: 'clic_valider'
  }
}

function onClickCancelDeleteRecord () {
  return {
    track_page: 'npvr',
    track_nom: 'supprimer_enreg',
    track_zone: 'popin',
    track_type: 'clic_annuler'
  }
}

function onClickConfirmDeleteRecord () {
  return {
    track_page: 'npvr',
    track_nom: 'supprimer_enreg',
    track_zone: 'popin',
    track_type: 'clic_valider'
  }
}

function onClickConfirmInvitDeleteRecord () {
  return {
    track_page: 'npvr',
    track_zone: 'popin',
    track_nom: 'incit_supprimer_enreg',
    track_type: 'click_ok'
  }
}

function onClickCancelInvitDeleteRecord () {
  return {
    track_page: 'npvr',
    track_zone: 'popin',
    track_nom: 'incit_supprimer_enreg',
    track_type: 'annuler'
  }
}

function onOpenInvitDeleteRecordModal () {
  return {
    page: 'npvr',
    zone: 'popin',
    area: 'incit_supprimer_enreg',
    type: 'affichage'
  }
}

function onManageQuotaExceeded () {
  return {
    track_page: 'npvr',
    track_nom: 'espace_disque_faible',
    track_zone: 'popin',
    track_type: 'clic_gerer'
  }
}

function onOpenQuotaExceeded () {
  return {
    page: 'npvr',
    zone: 'popin',
    area: 'espace_disque_faible',
    type: 'affichage'
  }
}

function onUpdateQuotaExceeded () {
  return {
    track_page: 'npvr',
    track_nom: 'espace_disque_faible',
    track_zone: 'popin',
    track_type: 'clic_augmenter'
  }
}

function onLoadPwa () {
  return {
    track_page: 'maintenant',
    track_zone: 'pwa',
    track_nom: 'lancement',
    track_type: 'icone'
  }
}

// ---------------------------------------

export default {
  onClickAssistance,
  onClickScrollTopButton,
  onTriggerOutOfEu,
  onTriggerStbOnly,
  onClickOutOfEuHelp,
  onClickICloudPrivateRelayHelp,
  onTriggerNotAuthenticated,
  onClickNotAuthenticatedRedirect,
  onClickCancelRecording,
  onClickConfirmRecording,
  onClickShowMore,
  onClickCancelDeleteRecord,
  onClickConfirmDeleteRecord,
  onClickConfirmInvitDeleteRecord,
  onClickCancelInvitDeleteRecord,
  onOpenInvitDeleteRecordModal,
  onManageQuotaExceeded,
  onOpenQuotaExceeded,
  onUpdateQuotaExceeded,
  onLoadPwa
}
