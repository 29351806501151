import Vue from 'vue'
import conversionTimestamp from './conversionTimestamp/conversionTimestamp.filter'
import capitalizeFLetter from './capitalizeFLetter/capitalizeFLetter.filter'
import convertFullStringDateTimeTimestamp from './convertFullStringDateTimeTimestamp/convertFullStringDateTimeTimestamp.filter'
import convertDateTimeToFullString from './convertDateTimeToFullString/convertDateTimeToFullString'
import getTwoDigitNumber from './getTwoDigitNumber/getTwoDigitNumber.filter'
import getNDigitNumber from './getNDigitNumber/getNDigitNumber.filter'
import getThreeDigitNumber from './getThreeDigitNumber/getThreeDigitNumber.filter'
import formatTitleUrl from './formatTitleUrl/formatTitleUrl'
import getFormatedDate from './getFormatedDate/getFormatedDate.filter'

Vue.filter('conversionTimestamp', conversionTimestamp)
Vue.filter('capitalizeFLetter', capitalizeFLetter)
Vue.filter('convertFullStringDateTimeTimestamp', convertFullStringDateTimeTimestamp)
Vue.filter('convertDateTimeToFullString', convertDateTimeToFullString)
Vue.filter('getNDigitNumber', getNDigitNumber)
Vue.filter('getTwoDigitNumber', getTwoDigitNumber)
Vue.filter('getThreeDigitNumber', getThreeDigitNumber)
Vue.filter('formatTitleUrl', formatTitleUrl)
Vue.filter('getFormatedDate', getFormatedDate)
