import _get from 'lodash/get'

export default {
  /**
   * Get Page
   * @param state
   */
  getPage (state) {
    return _get(state, 'meta.oData.page')
  }
}
