import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'
import { RECORDINGS_CONFIG } from '../shared/player.config'

export default {
  data () {
    return {
      player: '',
      recording: {}
    }
  },
  computed: {
    ...mapGetters({
      isAppleOS: 'useragent/isAppleOS',
      isSafari: 'useragent/isSafari',
      uniqueId: 'user/getUniqueId'
    })
  },
  mounted () {
    this.$root.$on('play-recording', this.init)
  },
  methods: {
    init ({ recording }) {
      this.recording = { ...recording, duration: recording.duration / 1000 }

      if (this.player === '') {
        this.player = () => import(/* webpackChunkName: "npvr-player" */ './playerNpvr').then(m => m.default || m)
      } else {
        this.play()
      }
    },
    play () {
      this.sendUrlRecording(this.user, this.recording)
    },
    sendUrlRecording (user, recording) {
      const npvrTechnicalChannelId = _get(recording, 'npvrTechnicalChannelId', '')

      let definition = RECORDINGS_CONFIG.DEFAULT.HD
      if (_isEmpty(definition)) {
        definition = RECORDINGS_CONFIG.DEFAULT.DEFINITION
      }

      let region = _get(user, 'mco', RECORDINGS_CONFIG.DEFAULT.REGION)
      if (_isEmpty(region)) {
        region = RECORDINGS_CONFIG.DEFAULT.REGION
      }

      const params = {
        npvrTechnicalChannelId,
        definition,
        region,
        deviceId: this.uniqueId,
        deviceModel: this.isAppleOS || this.isSafari ? RECORDINGS_CONFIG.DEFAULT.DEVICE_MODEL_SAFARI : RECORDINGS_CONFIG.DEFAULT.DEVICE_MODEL
      }

      this.$store.dispatch('npvr/getPlayRecording', {
        recordingId: _get(recording, 'recordingId'),
        params
      }).then((streamOptions) => {
        this.startPlayerWithUrlRecording({ streamOptions, recording })
      }).catch(() => {
        this.$refs.player.close()
      })
    },
    startPlayerWithUrlRecording ({ streamOptions, recording }) {
      if (streamOptions) {
        const recordingConfig = {
          url: streamOptions.recordingUrl,
          sessionId: streamOptions.sessionId
        }

        if (streamOptions.drmToken) {
          recordingConfig.drmToken = streamOptions.drmToken
        }

        this.$refs.player.play(recordingConfig, recording)
      }
    }
  }
}
