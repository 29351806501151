export default {
  title: 'Programme TV complet jour par jour avec Orange.',
  description: 'Programme TV sur toutes vos chaînes : en direct, ce soir {todayDate}, grille des émissions télé par Orange. A voir sur tous vos navigateurs ordinateurs, tablettes ou smartphones.',
  timeline: {
    today: `aujourd'hui`,
    tomorrow: 'demain'
  },
  accessibility: {
    title: 'Tous les programmes TV, ',
    timeline: 'Sélectionner le jour et la plage horaire'
  }
}
