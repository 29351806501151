import _get from 'lodash/get'

export default {
  /**
   * Get user
   * @param commit
   * @param getters
   * @param rootGetters
   * @param dispatch
   * @returns {Promise<T>}
   */
  getUser ({ commit }) {
    return this.$api.tvm.users.getUser()
      .then(({ user }) => {
        commit('setUser', user)
      })
  },

  /**
   * Get the current Time offset
   * @param commit
   * @returns {Promise<T>}
   */
  getTimeOffset ({ commit }) {
    return this.$api.internal.about.getAbout()
      .then(about => commit('setTimeOffset', about.data.serverDate))
      .catch(err => commit('setTimeOffset', _get(err, 'error.date')))
  },

  /**
   * Post error on logs
   * @param rootGetters
   * @param dispatch
   * @param error
   * @returns {Promise<T>}
   */
  postErrorLogs ({ rootGetters, dispatch }, { error }) {
    return this.$api.tvm.users.postErrorLogs(error)
      .catch(err => dispatch('errors/setAxiosError', { error: { ...err } }, { root: true }))
  }
}
