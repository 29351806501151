<template>
  <div id="app">
    <div class="container">
      <div class="left-section">
        <div class="content">
          {{ errorTitle }}
        </div>
        <div class="redir-text">
          {{ $t('errors.generic.redirection', {count: timerTicks}) }}
        </div>
        <div class="redir-text">
          {{ $t('errors.generic.or') }}
        </div>
        <a
          :href="homePage"
          class="redir-link">
          {{ $t('errors.generic.link') }}
        </a>
      </div>
      <div class="right-section show-from-medium">
        <img
          :src="notFoundImage"
          class="logo"
          alt="404">
      </div>
    </div>
    <footer>
      <wptv-footer :used-o-s="os" />
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import commonElements from '../components/shared/commonElements/commonElements.mixin'
import { WptvFooter } from '@wptv/components/ui/footer'

export default {
  name: 'AppError',
  components: {
    WptvFooter
  },
  mixins: [commonElements],
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      timer: null,
      timerTicks: 5,
      notFoundImage: '/images/error.jpg',
      os: ''
    }
  },
  meta: {
    oData: { page: '404' }
  },
  computed: {
    ...mapGetters({
      osName: 'useragent/getOsName'
    }),
    errorTitle () {
      return this.error && (this.error.statusCode === 404 || this.error.statusCode === 410) ? this.$t('errors.generic.notFound') : this.$t('errors.generic.unknown')
    },
    homePage () {
      return this.error && this.error.app === 'npvr' ? '/mes-enregistrements/disponibles' : '/'
    }
  },
  mounted () {
    this.timer = setInterval(() => {
      this.timerTicks--

      if (this.timerTicks === 0) {
        clearTimeout(this.timer)
      }
    }, 1000)

    setTimeout(() => {
      window.location.assign(this.homePage)
    }, 5000)
    // done to avoid error if we use the getter 'getOsName' before we set the useragent
    this.os = this.osName
  }
}
</script>
<style lang="scss" scoped>
  .container {
    background-color: $white;
    display: flex;
    flex: 1;
    align-items: center;
    padding: 20px;
    justify-content: center;

    .left-section {
      text-align: center;
      @include mediaQueries('width', (xsmall: 100%, medium: 50%));
    }

    .content {
      font-size: 45px;
      margin-bottom: 20px;
      line-height: 1.2;
    }

    .redir-text,
    .redir-link {
      margin-top: 10px;
      font-size: 20px;
    }

    .redir-link {
      font-weight: bold;
      color: $primary2;
    }
  }
</style>
