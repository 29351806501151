<template>
  <div id="app">
    <div class="box">
      <page-title />
      <nuxt />
      <wptv-scroll-top-button
        :title="$t('general.scrollTop')"
        :tealium="$stats.global.onClickScrollTopButton(page)"
        tab-target-id="o-search-input"
        data-wptv="mosaique-retour_haut" />
    </div>

    <component
      :is="player"
      v-if="player"
      ref="player"
      @player-loaded="play"
      @close-player="setFocusOnPlayerClose" />

    <component
      :is="activeModal"
      v-if="showModal"
      ref="activeModal"
      v-bind="activeModalProps"
      @closed="closeModal" />

    <assistance />
    <footer>
      <wptv-footer :used-o-s="os" />
    </footer>
    <wptv-snackbar />
  </div>
</template>

<script>
import _get from 'lodash/get'
import { mapGetters } from 'vuex'
import { WptvScrollTopButton } from '@wptv/components/ui/scrollTopButton'
import { WptvSnackbar } from '@wptv/components/ui/snackbar'
import { WptvFooter } from '@wptv/components/ui/footer'
import { setFocusOnFirstFocusableChild } from '@wptv/components/utils'
import commonElements from '../components/shared/commonElements/commonElements.mixin'
import PageTitle from '../components/shared/pageTitle/PageTitle'
import playerNpvrMixin from '../components/shared/player/playerNpvr/player-npvr.mixin'
import Assistance from '../components/shared/assistance/Assistance'
import modals from '@/components/shared/modals/modals.mixin'
import { makeCompteOneRedirectUrlHelper } from '../components/shared/utils/compteOneRedirect.utils'

export default {
  name: 'Npvr',
  components: {
    WptvScrollTopButton,
    WptvSnackbar,
    WptvFooter,
    Assistance,
    PageTitle
  },
  mixins: [
    commonElements,
    playerNpvrMixin,
    modals
  ],
  data () {
    return {
      os: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'npvr/getUser',
      isNpvrSubscribed: 'npvr/isNpvrSubscribed',
      page: 'meta/getPage',
      idToFocus: 'user/getIdToFocus',
      osName: 'useragent/getOsName'
    })
  },
  mounted () {
    this.accessNpvrOrRedirect()
    this.$root.$on('update-recordings', this.updateRecordings)
    // done to avoid error if we use the getter 'getOsName' before we set the useragent
    this.os = this.osName
  },
  beforeDestroy () {
    if (this.$refs.player) {
      this.$refs.player.togglePipMode(false)
    }
  },
  methods: {
    /**
     * Refresh data
     */
    updateRecordings () {
      this.$store.dispatch('npvr/getStatus')
      this.$store.dispatch('npvr/getRecordings', { customerOrangePopulation: _get(this.user, 'customerOrangePopulation') })
    },
    /**
     * Check for user authentication and NPVR subscription. Redirect to proper URL if needed.
     */
    accessNpvrOrRedirect () {
      if (!this.isNpvrSubscribed) {
        const redirectTargetUrl = _get(this.$config, 'confetti.public.urls.npvrSubscriptionPage')
        const url = makeCompteOneRedirectUrlHelper.bind(this)(redirectTargetUrl)
        window.location.assign(url)
      }
    },
    setFocusOnPlayerClose () {
      const elem = document.getElementById(this.idToFocus) || document.getElementsByClassName('fip-recording-content')[0]
      setFocusOnFirstFocusableChild(elem)
    }
  }
}
</script>
