import _get from 'lodash/get'

export default {
  /**
   * Fetch genres available used to filter
   * @returns {Promise<any | never>}
   */
  getGenres () {
    const path = _get(this.$config, 'confetti.public.api.tvm.paths.genres')
    const method = 'api.genres.getGenres()'

    const options = {
      cache: {
        exclude: { query: false }
      }
    }

    return this.$http.tvm.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, true))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method }))
  }
}
