import { DateTime } from 'luxon'
import capitalizeFLetter from '../capitalizeFLetter/capitalizeFLetter.filter'

export default function (stringDate) {
  const dt = DateTime.fromISO(stringDate)

  return capitalizeFLetter(dt.toLocaleString({
    year: 'numeric',
    month: 'long',
    weekday: 'long',
    day: 'numeric'
  }))
}
