import user from './user/user'
import CONSTANTS from '../../api.constants'

/**
 * Catching error to show modal and transfering redirect url if any
 * @param componentName
 * @param props
 * @param url
 * @param store
 * @returns {Promise<never>}
 * @private
 */
function _showModalAndManageRedirectOnError ({ componentName, props, url, tab }, store) {
  if (componentName) {
    store.commit('config/addModalToOpen', { componentName, props })
  }

  return Promise.reject({ url, tab })
}

// ------------------------------------------------------------------------------

/**
 * Main test to make auth requests
 * @param profile
 * @returns {boolean|boolean|*|(function(*): *)|(function(*): *)}
 */
export function profileHasAccess (profile) {
  return (
    profile.isAuthenticated &&
    profile.suspended === CONSTANTS.SUSPENDED_CODES.NA &&
    profile.isUe
  )
}

/**
 * Checkups leading to modal if not valid - various step based on options
 * @param store
 * @param profile
 * @param requiresAuth
 * @returns {Promise<SimpleCacheConfigurator.never | void>}
 */
export function accessChecks (store, profile, requiresAuth) {
  return user.isUe(profile)
    .then(() => user.isNotSuspended(profile))
    .then(() => user.hasErrorOnTokenTvInit(store))
    .then(() => user.isAuthenticated(requiresAuth, profile))
    .catch(error => _showModalAndManageRedirectOnError(error, store))
}

/**
 * Checkups to record a program
 * @param store
 * @param profile
 * @param channel
 * @param confetti
 * @returns {Promise<never>}
 */
export function recordChecks (store, profile, channel, confetti) {
  return user.isUe(profile)
    .then(() => user.isNotSuspended(profile))
    .then(() => user.hasErrorOnTokenTvInit(store))
    .then(() => user.isAuthenticated(true, profile))
    .then(() => user.hasBouquetsForChannel(store, channel, confetti))
    .then(() => user.isNpvrSubscribed(store, confetti))
    .catch(error => _showModalAndManageRedirectOnError(error, store))
}

/**
 * Checkups when trying to play a program, modal to open or url to redirect if checks fail
 * @param store
 * @param profile
 * @param userApplication
 * @param channel
 * @returns {Promise<void>}
 */
export function playChecks (store, profile, userApplication, channel, confetti) {
  return user.isUe(profile)
    .then(() => user.isNotSuspended(profile))
    .then(() => user.hasErrorOnTokenTvInit(store))
    .then(() => user.isAuthenticated(true, profile))
    .then(() => user.isNotStbOnly(userApplication, channel))
    .then(() => user.hasBouquetsForChannel(store, channel, confetti))
    .catch(error => _showModalAndManageRedirectOnError(error, store))
}

/**
 * Checkups to redirect to a fip
 * if not auth or if user has bouquets, checks are valid
 * @param store
 * @param profile
 * @param channel
 * @param confetti
 * @returns {Promise<void>}
 */
export function redirectToFipChecks (store, profile, channel, confetti) {
  return user.isAuthenticated(true, profile)
    .then(() => user.hasBouquetsForChannel(store, channel, confetti))
    .catch(({ url }) => url ? Promise.reject(url) : Promise.resolve())
}
