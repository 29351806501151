/**
 * Action when retrieving programs by channels
 * @param commit
 * @param getters
 * @param rootGetters
 * @param newPrograms
 * @param reset
 * @param before
 * @private
 */
function _onFetchProgramsByChannels ({ commit, getters, rootGetters }, newPrograms, reset, before) {
  const channels = rootGetters['channels/getChannels']

  if (reset || getters.getProgramsByChannels.length === 0) {
    commit('initProgramsByChannels', channels)
    commit('resetProgramsByChannelsWidth')
  }

  return commit('setProgramsByChannels', { newPrograms, channels, before })
}

// -----------------------------------------------------------------

export default {
  /**
   * Get programs by channels and store them
   * @param commit
   * @param rootGetters
   * @param dispatch
   * @param period
   * @param genre
   * @param after
   * @param before
   * @param limit
   * @param reset
   * @param epgIds
   * @returns {Q.Promise<unknown>}
   */
  getProgramsByChannels ({ commit, getters, rootGetters, dispatch }, { period, genres, after, before, limit, reset = false }) {
    const params = {
      mco: rootGetters['user/getMCO'],
      period,
      genres,
      after,
      before,
      limit
    }

    return this.$api.tvm.programs.getProgramsByChannels(params)
      .then(newPrograms => _onFetchProgramsByChannels({ commit, getters, rootGetters }, newPrograms, reset, before))
      .catch(err => dispatch('errors/setAxiosError', { error: { ...err } }, { root: true }))
  }
}
