import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import CONSTANTS from '../../api.constants'
import { adaptLogoToVzl } from '../../components/shared/utils/imageData.utils'

export default {
  /**
   * Get Channels
   * @param state
   * @returns {*}
   */
  getChannels (state) {
    return state.channels
  },
  /**
   * Get Channels STB
   * @param state
   * @returns {*}
   */
  getChannelsSTB (state) {
    return state.channels.filter(channel => channel.allowedDeviceCategories.includes(CONSTANTS.DEVICES_CATEGORIES.PC))
  },
  /**
   * Get ChannelStream
   * @param state
   * @returns {{}|{url: string}|{protectionData: [{laUrl: string, drmToken: string, keySystem: string}], smoothStreaming: boolean, url: string}}
   */
  getChannelStream (state) {
    return state.channelStream
  },
  /**
   * Get ChannelSTB by Id
   * @param state
   * @param getters
   * @returns {function(*=): *}
   */
  getChannelSTBById (state, getters) {
    return channelId => getters.getChannelsSTB.find(channel => parseInt(channelId) === channel.idEPG)
  },
  /**
   * Get Channel By idEPG
   * @param state
   * @returns {function(*=): *}
   */
  getChannelById (state) {
    return channelId => state.channels.find(channel => parseInt(channelId) === channel.idEPG)
  },
  /**
   * Get Channel By editoChannelId
   * @param state
   * @returns {function(*=): *}
   */
  getChannelByEditoId (state) {
    return channelId => state.channels.find(channel => channelId === channel.editoChannelId)
  },
  /**
   * Get Channel By Id
   * @param state
   * @returns {function(*=): *}
   */
  getPCChannelById (state) {
    return channelId => state.channelsPC.find(channel => parseInt(channelId) === channel.idEPG)
  },
  /**
   * Get ChannelsVzl
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @returns {*}
   */
  getChannelsVzl (state, getters, rootState, rootGetters) {
    return rootGetters['programs/getCurrentPrograms'].map((program) => {
      if (program.season) {
        program.season.serieTitle = program.season.serie.title
      }

      const nextProgram = rootGetters['programs/getNextPrograms'](program.channelId)
      if (nextProgram.season) {
        nextProgram.season.serieTitle = nextProgram.season.serie.title
      }

      return {
        currentProgram: {
          ...program,
          summary: program.synopsis
        },
        hasAccess: program.channel ? !_isEmpty(program.channel.packages.filter(element => _includes(rootGetters['npvr/getUserPackages'], element))) : false,
        id: parseInt(program.channelId),
        logos: adaptLogoToVzl(program.channel),
        name: _get(program, 'channel.name', ''),
        nextProgram: {
          ...nextProgram,
          summary: nextProgram && nextProgram.synopsis ? nextProgram.synopsis : ''
        },
        zappingNumber: _get(program, 'channel.zappingNumber'),
        bouquets: _get(program, 'channel.packages', [])
      }
    })
  },
  /**
   * Get ChannelVzl by Id
   * @param state
   * @param getters
   * @returns {function(*=): *}
   */
  getChannelVzlById (state, getters) {
    return channelId => getters.getChannelsVzl.find(channel => channel.id === parseInt(channelId))
  },
  /**
   * Get FreeAccessChannels
   * @param state
   * @returns {*}
   */
  getFreeAccessChannels (state) {
    return state.freeAccessChannels
  },
  /**
   * Get ChannelIdWillFocus
   * @param state
   * @returns {*}
   */
  getChannelIdWillFocus (state) {
    return state.channelIdWillFocus
  }
}
