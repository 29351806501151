<template>
  <div
    id="thematicModal"
    class="thematic-filters"
    tabindex="-1">
    <div class="thematic-filters-content">
      <a
        id="back"
        href="#"
        data-wptv="back-button-filters-modal"
        class="o-link-arrow back hide-from-medium"
        @click="hideFilters">
        {{ $t('modals.sort.return') }}
      </a>
      <button
        id="cross"
        :aria-label="$t('modals.sort.title')"
        data-wptv="close-button-filters-modal"
        class="close-button show-from-medium"
        @click="hideFilters">
        <span class="icon icon-delete" aria-hidden="true" />
      </button>
      <h3>
        {{ $t('modals.sort.title') }}
      </h3>
      <wptv-chips-bar
        :items="filtersItems"
        :checked-items-list="checkedFilters"
        data-wptv="filters-chips-modal"
        @chipsbar-list-updated="updateList" />
      <a
        class="ob1-link-icon"
        data-wptv="reset-button-filters-modal"
        href="#"
        @click="resetFilters">
        <span class="icon icon-delete" aria-hidden="true" />
        <span class="ob1-link-icon-text"> {{ $t('modals.sort.reset') }} </span>
      </a>
      <div class="validate">
        <wptv-button
          data-wptv="validate-button-filters-modal"
          type="wired"
          @click.native="applyFilters">
          {{ $t('modals.sort.show') }}
        </wptv-button>
      </div>
    </div>
  </div>
</template>

<script>
import { WptvChipsBar } from '@wptv/components/ui/chipsBar'
import { WptvButton } from '@wptv/components/ui/button'
import { mapGetters } from 'vuex'

export default {
  name: 'ThematiqueFilters',
  components: {
    WptvChipsBar,
    WptvButton
  },
  data () {
    return {
      filtersToApply: [...this.$store.getters['genres/getSelectedFilters']],
      sortedItems: [],
      filterChipAll: 'filter-chip-popup-all'
    }
  },
  computed: {
    ...mapGetters({
      genres: 'genres/getGenres',
      trackPage: 'meta/getPage',
      selectedFilters: 'genres/getSelectedFilters'
    }),
    filterOn () {
      return this.filtersToApply.length > 0
    },
    filtersItems () {
      return [{ id: 'popup-all', label: 'Tous' }, ...this.genres.map((e) => { return { id: `popup-${e}`, label: e } })]
    },
    checkedFilters () {
      return this.filtersToApply.map(e => e.includes('popup') ? e : `filter-chip-popup-${e}`)
    }
  },
  mounted () {
    this.focusModal()
    window.addEventListener('resize', this.focusModal)
  },
  methods: {

    /**
     * Update the selected filters list and manage the filter all checking
     * @param selectedList
     */
    updateList (selectedList) {
      const chipAllIndex = selectedList.indexOf(this.filterChipAll)
      if (selectedList.length === 0 || (selectedList.length === 1 && chipAllIndex === 0) || selectedList[selectedList.length - 1] === this.filterChipAll) {
        this.filtersToApply = [this.filterChipAll]
      } else if (selectedList.includes(this.filterChipAll)) {
        this.filtersToApply = selectedList.slice(0, chipAllIndex).concat(selectedList.slice(chipAllIndex + 1))
      } else {
        this.filtersToApply = selectedList
      }
    },
    hideFilters () {
      this.$root.$emit('showFilters', false)
    },
    removeFilter (item) {
      this.filtersToApply = this.filtersToApply.filter(filter => filter !== item)
    },
    addFilter (item) {
      this.filtersToApply.push(item)
    },
    resetFilters () {
      this.filtersToApply = ['filter-chip-popup-all']
    },
    applyFilters () {
      this.$store.commit('genres/setSelectedFilters', this.filtersToApply.map(e => e.slice(18)))
      this.hideFilters()
    },
    focusModal () {
      const modal = document.querySelector('#thematicModal') // select the modal by it's id

      // add all the elements inside modal which you want to make focusable
      if (modal) {
        const focusableElements = 'button, a, [tabindex]:not([tabindex="-1"])'

        const focusableContent = [...modal.querySelectorAll(focusableElements)]
        const focusableContentArray = Array.from(focusableContent)
        const buttonCross = document.getElementById('cross')
        const firstFocusableElement = window.getComputedStyle(buttonCross).display === 'none' ? focusableContentArray[0] : focusableContentArray[1] // get first element to be focused inside modal

        window.getComputedStyle(buttonCross).display === 'none' ? focusableContentArray.splice(focusableContentArray.find(e => e.id === 'cross'), 1) : focusableContent.splice(focusableContent.find(e => e.id === 'back'), 1)
        const lastFocusableElement = focusableContentArray[focusableContentArray.length - 1] // get last element to be focused inside modal

        document.addEventListener('keydown', function (e) {
          const isTabPressed = e.key === 'Tab' || e.keyCode === 9
          if (!isTabPressed) {
            return
          }

          if (e.shiftKey) { // if shift key pressed for shift + tab combination
            if (document.activeElement === firstFocusableElement) {
              lastFocusableElement.focus() // add focus for the last focusable element
              e.preventDefault()
            }
          } else if (document.activeElement === lastFocusableElement) { // if tab key is pressed
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            e.preventDefault()
            firstFocusableElement.focus() // add focus for the first focusable element
          }
        })
        firstFocusableElement.focus()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.thematic-filters {
  position: fixed;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);

  .thematic-filters-content {
    width: 100%;
    background: $white;
    @include mediaQueries('position', (medium: absolute));
    @include mediaQueries('top', (medium: 50%));
    @include mediaQueries('left', (medium: 50%));
    @include mediaQueries('transform', (medium: translate(-50%, -50%)));
    @include mediaQueries('max-width', (medium: 590px, large: 770px));
    min-height: 100%;
    @include mediaQueries('min-height', (medium: auto));
    padding: 20px 15px;
    @include mediaQueries('padding', (medium: 50px 45px));
  }

  .close-button {
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    background: none;
    cursor: pointer;
    @extend %focus-visible;
  }

  .filters {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: left;

    .thematique-item-wrapper {
      margin: 15px 0 0 0;
      padding-left: 0;
      padding-right: 15px;
    }
  }

  .disable-filters {
    position: relative;
    font-weight: 700;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
  }

  .validate {
    display: flex;
    position: relative;
    margin-top: 20px;
    @include mediaQueries('margin-top', (medium: 40px));
    justify-content: center;
    @include mediaQueries('justify-content', (medium: left));
  }
}
</style>
