function onClickOpenFilter (page) {
  return {
    track_page: page,
    track_zone: 'filtre',
    track_nom: 'ouverture_menu'
  }
}

function onClickThematicsItem (page, item) {
  return {
    track_page: page,
    track_zone: 'filtre',
    track_nom: 'choix',
    track_cible: item
  }
}

function onClickFilterAll (page) {
  return {
    track_page: page,
    track_zone: 'filtre',
    track_nom: 'choix',
    track_cible: 'tous'
  }
}
function onClickFilter (item, page) {
  return {
    track_page: page,
    track_zone: 'filtre',
    track_nom: 'choix',
    track_cible: item
  }
}
function onClickFilterMenuPlus (page) {
  return {
    track_page: page,
    track_zone: 'filtre',
    track_nom: 'menu_plus'
  }
}

export default {
  onClickFilterAll,
  onClickFilter,
  onClickFilterMenuPlus,
  onClickThematicsItem,
  onClickOpenFilter
}
