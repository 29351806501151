import Vue from 'vue'
import VueI18n from 'vue-i18n'
import wording from './wording'

const dateTimeFormats = {
  'fr-FR': {
    short: {
      month: 'long',
      day: 'numeric',
      weekday: 'long'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long'
    }
  }
}

Vue.use(VueI18n)

export default ({ app }) => {
  app.i18n = new VueI18n({
    dateTimeFormats,
    locale: 'fr_FR',
    messages: { fr_FR: wording }
  })
}
