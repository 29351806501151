export default {
  /**
   * Retrieve a specific program and store it
   * @param commit
   * @param rootGetters
   * @param dispatch
   * @param programId
   * @returns {Q.Promise<unknown>}
   */
  getOneprogram ({ commit, rootGetters, dispatch }, { programId }) {
    const params = {
      mco: rootGetters['user/getMCO'],
      programId
    }

    return this.$api.tvm.programs.getOne(params)
      .then((program) => {
        if (program.error && program.error.status === 404) {
          return Promise.reject(program.error)
        } else {
          commit('setOneProgram', program)
        }
      })
  },
  /**
   * Retrieve first current programs by channels and store them
   * @param commit
   * @param rootGetters
   * @param dispatch
   * @returns {Q.Promise<unknown>}
   */
  getFirstCurrentPrograms ({ commit, rootGetters, dispatch }) {
    const params = {
      mco: rootGetters['user/getMCO'],
      includeEmptyChannels: false,
      limit: 12
    }

    return this.$api.tvm.programs.getCurrentProgramsByChannels(params)
      .then(programs => commit('setCurrentPrograms', {
        programs,
        userTimeOffset: rootGetters['user/getTimeOffset'],
        channels: rootGetters['channels/getChannelsSTB'],
        notSubscribed: rootGetters['npvr/notSubscribed']
      }))
      .catch(err => dispatch('errors/setAxiosError', { error: { ...err } }, { root: true }))
  },
  /**
   * Retrieve current programs by channels and store them
   * @param commit
   * @param rootGetters
   * @param dispatch
   * @returns {Q.Promise<unknown>}
   */
  getCurrentProgramsByChannels ({ commit, rootGetters, dispatch }) {
    const params = {
      mco: rootGetters['user/getMCO']
    }

    return this.$api.tvm.programs.getCurrentProgramsByChannels(params)
      .then((programs) => {
        commit('setCurrentProgramsByChannels', programs)
        commit('setCurrentPrograms', {
          programs,
          userTimeOffset: rootGetters['user/getTimeOffset'],
          channels: rootGetters['channels/getChannelsSTB'],
          notSubscribed: rootGetters['npvr/notSubscribed']
        })
      })
      .catch((err) => {
        dispatch('errors/setAxiosError', { error: { ...err } }, { root: true })
        return Promise.reject(err)
      })
  },
  /**
   * Retrieve evening programs by channels and store them
   * @param commit
   * @param getters
   * @param rootGetters
   * @param dispatch
   * @param limit
   * @returns {Q.Promise<unknown>}
   */
  getEveningProgramsByChannels ({ commit, getters, rootGetters, dispatch }, limit) {
    const channels = rootGetters['channels/getChannels']
    const params = {
      mco: rootGetters['user/getMCO']
    }

    if (limit) {
      params.includeEmptyChannels = false
      params.limit = limit
    }

    return this.$api.tvm.programs.getEveningProgramsByChannels(params)
      .then(programsByChannels => commit('setEveningProgramsByChannels', { programsByChannels, channels }))
      .catch(err => dispatch('errors/setAxiosError', { error: { ...err } }, { root: true }))
  }
}
