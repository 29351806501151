import { profileHasAccess } from '../middleware/checkups'

/**
 * Init store on client side only
 * @param store
 * @returns {Promise<{-readonly [P in keyof *[]]: PromiseSettledResult<*[][P] extends PromiseLike<infer U> ? U : *[][P]>}>}
 */
export default ({ store }) => {
  store.commit('useragent/setUserAgent')

  if (profileHasAccess(store.getters['core/getProfile'])) {
    return Promise.allSettled([
      store.dispatch('user/getTimeOffset'),
      store.dispatch('videos/getCertificateSafari'),
      store.dispatch('videos/getCertificateChrome')
    ])
  }
}
