import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { accessChecks } from './checkups'

export default ({ route, from, store, redirect }) => {
  store.commit('meta/setMeta', _get(route, 'meta[0]'))
  store.commit('route/setName', _get(route, 'name'))
  store.commit('route/setFullPath', _get(route, 'fullPath'))

  const previousRoute = store.getters['config/getPreviousRoute']
  if (_isEmpty(previousRoute)) {
    store.commit('config/setPreviousRoute', {
      name: route.name,
      path: route.path
    })
  }

  if (from) {
    store.commit('config/setPreviousRoute', {
      name: from.name,
      path: from.path,
      query: from.query
    })

    if (!from.name?.toLowerCase().includes('recording') && ['AvailableRecordings', 'ScheduledRecordings'].includes(route?.name)) {
      store.commit('npvr/setFromLayout', {
        name: from.name,
        path: from.path,
        query: from.query
      })
    }
  }

  store.commit('errors/clear')

  // Clear modals if any still being open before switching to the new page
  if (!from || !route || from.name !== 'HomePage' || from.name !== route.name) {
    store.commit('config/clearModalsToOpen')
  }

  // Get data for auth guards
  const firstRender = process.server
  const profile = store.getters['core/getProfile']
  const requiresAuth = _get(route, 'meta[0].requiresAuth', false)

  // On first render (ssr) always run checks, with auth check if route requires it
  // Then for client side, run check only if route requires auth
  if (firstRender || requiresAuth) {
    return accessChecks(store, profile, requiresAuth)
      .catch(() => {
        if (requiresAuth) {
          redirect(from || '/')
        }
      })
  }
}
