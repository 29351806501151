import Vue from 'vue'
import Router from 'vue-router'
import scrollBehavior from './router.scrollBehavior.js'

const Home = () => import(/* webpackChunkName: "home" */ './components/live/home/Home').then(m => m.default || m)
const Evening = () => import(/* webpackChunkName: "evening" */ './components/live/evening/Evening').then(m => m.default || m)
const Programs = () => import(/* webpackChunkName: "program" */ './components/live/programs/Programs').then(m => m.default || m)
const Fip = () => import(/* webpackChunkName: "fip" */ './components/live/fip/Fip').then(m => m.default || m)
const FipRecording = () => import(/* webpackChunkName: "fip-recording" */ './components/npvr/FipRecording/FipRecording').then(m => m.default || m)
const AvailableRecordings = () => import(/* webpackChunkName: "available-recordings" */ './components/npvr/recordings/AvailableRecordings').then(m => m.default || m)
const ScheduledRecordings = () => import(/* webpackChunkName: "scheduled-recordings" */ './components/npvr/recordings/ScheduledRecordings').then(m => m.default || m)

Vue.use(Router)

export function createRouter () {
  return new Router({
    mode: 'history',
    routes: [
      {
        name: 'HomePage',
        path: '/',
        component: Home
      },
      {
        name: 'Evening',
        path: '/ce-soir',
        component: Evening
      },
      {
        name: 'Programs',
        path: '/programme-tv',
        component: Programs
      },
      {
        name: 'Fip',
        path: '/programme-tv/:programId/:programTitle?',
        component: Fip,
        props: true
      },
      {
        name: 'AvailableRecordings',
        path: '/mes-enregistrements/disponibles',
        component: AvailableRecordings,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'ScheduledRecordings',
        path: '/mes-enregistrements/programmes',
        component: ScheduledRecordings,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'FipRecording',
        path: '/mes-enregistrements/:recordingId/:recordingTitle?',
        component: FipRecording,
        props: true,
        meta: {
          requiresAuth: true
        }
      }
    ],
    scrollBehavior
  })
}
