export default {
  /**
   * Store Bouquets
   * @param state
   * @param bouquets
   */
  setBouquets (state, bouquets) {
    state.bouquets = bouquets
  }
}
