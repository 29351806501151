import _get from 'lodash/get'

function onClickAutoPromo (promoAlt, imageUrl) {
  return {
    track_page: 'home',
    track_zone: 'autopromo',
    track_nom: 'visuel',
    track_cible: `${promoAlt}-${imageUrl}`
  }
}

function onClickAutoPromoArrow (arrow) {
  return {
    track_page: 'home',
    track_zone: 'autopromo',
    track_nom: arrow
  }
}

function onClickAutoPromoDots (promo) {
  return {
    track_page: 'home',
    track_zone: 'autopromo',
    track_nom: 'tirets',
    track_cible: promo
  }
}

function onClickChannelTitleHome (channel, hasAccess, $config) {
  return {
    track_page: 'home',
    track_zone: 'mosaique',
    track_nom: 'titre_chaine',
    track_cible: _getTarget(channel, hasAccess, $config)
  }
}

function onClickCover (channel, hasAccess, notSubscribed, $config) {
  return {
    track_page: 'home',
    track_zone: 'mosaique',
    track_nom: _getName(hasAccess, notSubscribed),
    track_cible: _getTarget(channel, hasAccess, $config)
  }
}

// -------------------------------------------------------

/**
 * Get name
 * @param hasAccess
 * @param notSubscribed
 * @returns {string}
 * @private
 */
function _getName (hasAccess, notSubscribed) {
  const trackAuth = notSubscribed ? 'visuel_chaine-en_cours-non_souscrite' : 'visuel_chaine-en_cours-souscrite'
  return hasAccess ? trackAuth : 'visuel_chaine-en_cours-non_authent'
}

/**
 * Get target
 * @param channel
 * @param hasAccess
 * @param $config
 * @returns {string}
 * @private
 */
function _getTarget (channel, hasAccess, $config) {
  const channelInfo = `${_get(channel, 'id')}-${_get(channel, 'name')}`
  return hasAccess ? channelInfo : `${channelInfo}-${$config.confetti.public.authUrl}`
}

export default {
  onClickChannelTitleHome,
  onClickCover,
  onClickAutoPromo,
  onClickAutoPromoArrow,
  onClickAutoPromoDots
}
