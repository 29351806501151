import _get from 'lodash/get'
import formatTitleUrl from '../../../plugins/filters/formatTitleUrl/formatTitleUrl'

function onClickPlayRecord (lcn, channelName, id, title) {
  return {
    area: 'play_enreg',
    page: 'fip_npvr',
    zone: 'action',
    cible: `${lcn}-${channelName}/mes-enregistements/${id}/${title}`
  }
}

function onClickEditUpdateRecording (name, target) {
  return {
    track_page: 'fip_npvr',
    track_zone: 'action',
    track_nom: name,
    track_cible: target
  }
}

function onClickLiveButton (channel, program) {
  return {
    page: 'fip',
    zone: 'action',
    area: 'regarder_direct',
    type: 'clic',
    cible: `${_get(channel, 'idEPG', '')}-${_get(channel, 'name', '')}-/programmes/ ${program.id}/${formatTitleUrl(program.title)}`
  }
}

function onClickRecordButton (recording, contentUrlForFipPoster) {
  return {
    track_page: 'fip',
    track_nom: 'enregistrer',
    track_zone: 'action',
    track_type: 'clic',
    track_cible: `${recording.channel.lcn}-${recording.channel.name}-${contentUrlForFipPoster}`
  }
}

function onClickReplay (catchupId) {
  return {
    track_page: 'fip',
    track_zone: 'action',
    track_nom: 'acceder_replay',
    type: 'clic',
    track_cible: catchupId
  }
}
function onClickEditRecordings (channel, program) {
  return {
    track_page: 'fip',
    track_zone: 'action',
    track_nom: 'modifier_enreg',
    track_cible: _getProgramStats(channel, program)
  }
}
function onClickAccessRecordings (channel, program) {
  return {
    track_page: 'fip',
    track_zone: 'action',
    track_nom: 'acceder_enreg',
    track_cible: _getProgramStats(channel, program)
  }
}
/**
 * Get program stats
 * @returns {string}
 */
function _getProgramStats (channel, program) {
  const formattedTitle = formatTitleUrl(_get(program, 'title', null)) || ''
  return program && channel ? `${channel.id}-${channel.name}-/programmes/${program.id}/${formattedTitle}` : ''
}

export default {
  onClickReplay,
  onClickEditRecordings,
  onClickAccessRecordings,
  onClickRecordButton,
  onClickLiveButton,
  onClickEditUpdateRecording,
  onClickPlayRecord
}
