import APICONSTANTS from '../../api.constants'

export default {
  /**
   * Fetch and store Channels
   * @param commit
   * @param dispatch
   * @param everywherePopulation
   * @returns {Promise<unknown>}
   */
  getChannels ({ commit, dispatch }, everywherePopulation) {
    return this.$api.gateway.channels.getChannels(everywherePopulation)
      .then(response => commit('setChannels', response.channels))
      .catch(err => dispatch('errors/setGatewayError', { error: { ...err } }, { root: true }))
  },
  /**
   * Fetch and store Channels filtered for PC only
   * @param commit
   * @param dispatch
   * @param everywherePopulation
   * @returns {Promise<unknown>}
   */
  getPCChannels ({ commit, dispatch }, everywherePopulation) {
    return this.$api.gateway.channels.getChannels(everywherePopulation, APICONSTANTS.DEVICES_CATEGORIES.PC)
      .then(response => commit('setPCChannels', response.channels))
      .catch(err => dispatch('errors/setGatewayError', { error: { ...err } }, { root: true }))
  },
  /**
   * Fetch and store Channel Stream
   * @param commit
   * @param dispatch
   * @param rootGetters
   * @param payload
   * @returns {*}
   */
  getChannelStream ({ commit, dispatch, rootGetters }, payload) {
    return this.$api.tvm.channels.getChannelStream({ payload })
      .then((stream) => {
        if (stream.error) {
          dispatch('errors/setAxiosError', { error: { ...stream, channelId: payload.channelId } }, { root: true })
          return Promise.reject(stream)
        }
        commit('setChannelStream', stream)
      })
  },
  /**
   * Fetch and store FreeAccessChannels
   * @param commit
   * @param rootGetters
   * @returns {Promise<unknown>}
   */
  getFreeAccessChannels ({ commit, rootGetters }) {
    return this.$api.internal.freeAccessChannels.getFreeAccessChannels({ mco: rootGetters['user/getMCO'] })
      .then(FAC => commit('setFreeAccessChannels', FAC.freeAccessChannels))
      .catch(() => commit('setFreeAccessChannels', []))
  }
}
