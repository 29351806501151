export default {
  /**
   * Get Bouquets
   * @param state
   * @returns {*}
   */
  getBouquets (state) {
    return state.bouquets
  }
}
