import formatTitleUrl from '../../../plugins/filters/formatTitleUrl/formatTitleUrl'
import _get from 'lodash/get'

function onClickDateOption (cible) {
  return {
    track_page: 'ProgTV',
    track_zone: 'jour',
    track_nom: 'choix',
    track_cible: cible
  }
}

function onClickDateSelect () {
  return {
    track_page: 'ProgTV',
    track_zone: 'jour',
    track_nom: 'ouverture_menu'
  }
}

function onClickShowNext () {
  return {
    track_page: 'ProgTV',
    track_zone: 'chevron',
    track_nom: 'gauche'
  }
}
function onClickShowPrevious () {
  return {
    track_page: 'ProgTV',
    track_zone: 'chevron',
    track_nom: 'droite'
  }
}

function onClickChannelVisualProgTv (hasAccess, channel, $config, program, notSubscribed, isStreaming) {
  return {
    track_page: 'ProgTV',
    track_zone: 'grille',
    track_nom: _statsVisuelChaine(notSubscribed, program, hasAccess, isStreaming),
    track_cible: hasAccess ? _getProgramStats(channel, program) : _getChannelStats(channel, $config)
  }
}
function onClickChannelTitleProgTv (channel, program) {
  return {
    track_page: 'ProgTV',
    track_zone: 'grille',
    track_nom: 'titre_chaine',
    track_cible: _getProgramStats(channel, program)

  }
}

function onClickOpenMenu () {
  return {
    track_page: 'ProgTV',
    track_zone: 'tranche_horaire',
    track_nom: 'ouverture_menu'
  }
}
function onClickSelectTimeline (timezone) {
  return {
    track_page: 'ProgTV',
    track_zone: 'tranche_horaire',
    track_nom: 'choix',
    track_cible: timezone.value
  }
}

// -------------------------------------------------------

/**
 *
 * @param channel
 * @param $config
 * @returns {string}
 * @private
 */
function _getChannelStats (channel, $config) {
  return channel ? `${channel.idEPG}-${channel.name}-${$config.confetti.public.authUrl}` : ''
}
/**
 * Get program stats
 * @returns {string}
 */
function _getProgramStats (channel, program) {
  const formattedTitle = formatTitleUrl(_get(program, 'title', null)) || ''
  return program && channel ? `${channel.idEPG}-${channel.name}-/programmes/${program.id}/${formattedTitle}` : ''
}

/**
 * Retrieve correct stats based on various cases
 * @returns {string}
 */
function _statsVisuelChaine (notSubscribed, program, hasAccess, isStreaming) {
  const stat = 'visuel_chaine'
  const channelStatus = isStreaming ? 'en_cours' : 'offline'
  const userChannelStatus = notSubscribed ? 'non_souscrite' : 'souscrite'

  return `${stat}-${channelStatus}-${hasAccess ? userChannelStatus : 'non_authent'}`
}

export default {
  onClickShowNext,
  onClickShowPrevious,
  onClickChannelVisualProgTv,
  onClickChannelTitleProgTv,
  onClickOpenMenu,
  onClickSelectTimeline,
  onClickDateSelect,
  onClickDateOption
}
