export default {
  messages: {
    WARNING_SPACE: {
      title: 'Espace disque faible',
      text: 'Il ne vous reste plus que {hoursLeft} {unit} sur votre forfait de {subscribed} heures. Pensez à supprimer des enregistrements pour libérer de la place.'
    }
  },
  buttons: {
    buyMoreSpace: 'Acheter plus d\'espace de stockage'
  }
}
