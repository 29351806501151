<template>
  <section class="page-title">
    <div v-if="showTitle">
      <h1 class="sr-only">
        {{ genericTitle }}
      </h1>
    </div>
    <div
      v-if="showBack !== showBackType.HIDE_BACK && previousRouteLayout"
      class="link-back padding">
      <wptv-arrow-link
        id="first-focusable"
        :aria-label="$t(`general.retour.${label}`)"
        :label="$t(`general.retour.${label}`)"
        :back="true"
        to=""
        data-wptv="retour"
        @click.native="goBack" />
    </div>
    <div v-if="showTitle">
      <h2
        v-if="pageConfig.header.npvr"
        class="no-signature padding mb-0 pb-0">
        {{ $t(pageConfig.title) }}
      </h2>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { WptvArrowLink } from '@wptv/components/ui/arrowLink'
import _get from 'lodash/get'
import { CONFIG, SHOW_BACK_TYPE } from './pageTitle.config'

export default {
  name: 'PageTitle',
  components: { WptvArrowLink },
  data () {
    return {
      previousRouteLayout: null,
      showBackType: SHOW_BACK_TYPE,
      defaultRoute: CONFIG.defaultRoute
    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'config/getPreviousRoute',
      fromLayout: 'npvr/getFromLayout'
    }),
    hasPreviousRoute () {
      return _get(this.previousRoute, 'name') && _get(this.previousRoute, 'name') !== _get(this.$route, 'name')
    },
    routeName () {
      return _get(this.$route, 'name', CONFIG.defaultRoute)
    },
    pageConfig () {
      return CONFIG[this.routeName]
    },
    genericTitle () {
      const routeName = this.$route.name
      switch (routeName) {
        case 'AvailableRecordings':
        case 'ScheduledRecordings':
          return `${this.$t(this.pageConfig.title)} ${this.$t(CONFIG[`${routeName}`].pageTitle)}, Orange`
        case 'HomePage':
        case 'Evening':
        case 'Programs':
          return `${this.$t(CONFIG[`${routeName}`].pageTitle)}, ${this.$t(CONFIG[`${routeName}`].title)} Orange`
        default:
          return CONFIG.HomePage.title
      }
    },
    showTitle () {
      return this.pageConfig.header.showTitle
    },
    showBack () {
      return this.pageConfig.header.showBack
    },
    fallbackRoute () {
      return _get(this.pageConfig, 'fallbackRoute')
    },
    fallbackRouteLabel () {
      return this.fallbackRoute || 'default'
    },
    previousRouteLabel () {
      return this.hasPreviousRoute ? _get(this.previousRoute, 'name') : this.fallbackRouteLabel
    },
    label () {
      return this.showBack === this.showBackType.SHOW_BACK ? this.previousRouteLabel : this.previousRouteLayout.name
    }
  },
  mounted () {
    // we must get the layout back to save it
    this.previousRouteLayout = this.fromLayout
      ? this.fromLayout
      : this.previousRoute.name === this.$route.name
        ? { name: 'HomePage', path: '/', query: { filtres: 'all' } }
        : this.previousRoute
  },
  methods: {
    /**
     * Return to previous page
     */
    goBack () {
      const previousRoute = this.showBack === this.showBackType.SHOW_BACK
        ? this.previousRoute
        : this.previousRouteLayout
      this.sendStats(this.$stats.pageTitle.onClickBack(previousRoute))

      switch (this.pageConfig.header.showBack) {
        case SHOW_BACK_TYPE.SHOW_BACK_LAYOUT: // Only to get back to live from npvr
          return this.$router.push({ path: this.previousRouteLayout.path, query: this.previousRouteLayout.query })

        case SHOW_BACK_TYPE.SHOW_BACK: // standard for live and fip Recording
          if (this.hasPreviousRoute) {
            return this.$router.back() // /!\ Warning we need an history tab to get previous previous route for fip live
          } else if (this.fallbackRoute) {
            return this.$router.push({ name: this.fallbackRoute })
          } else {
            return this.$router.push({ name: this.defaultRoute })
          }
        default:
          return this.$router.push({ name: this.defaultRoute })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-title {
    position: relative;
    background-color: $white;
    flex-shrink: 0;
    flex-grow: 1;

    .link-back {
      padding-bottom: 5px;
    }
  }
</style>
