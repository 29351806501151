/**
 * get the digit wanted
 * @param data
 * @param digit
 * @returns {any}
 */
export default function (data, digit) {
  let zeroToAdd = '0'

  if (digit === 1) {
    digit += 1
  }

  for (let i = 0; i < digit - 1; i++) {
    zeroToAdd += '0'
  }

  return data !== undefined && !isNaN(data) ? (zeroToAdd + data).slice(-digit) : null
}
