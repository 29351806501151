export default function formatTitleUrl (programTitle) {
  if (programTitle) {
    return programTitle.toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036F]/g, '')
      .replace(/\s+/g, '-')
      .replace(/&/g, '-et-')
      .replace(/'/g, '-')
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, '')
      .replace(/-+/g, '-')
      .replace(/^-*/, '')
      .replace(/-*$/, '')
  } else {
    return null
  }
}
