export default {
  /**
   * Fetch and store bouquets
   * @param commit
   * @param rootGetters
   * @param dispatch
   * @returns {Promise<unknown>}
   */
  getBouquets ({ commit, rootGetters, dispatch }) {
    return this.$api.internal.bouquets.getBouquets({ mco: rootGetters['user/getMCO'] })
      .then(bouquets => commit('setBouquets', bouquets))
      .catch(err => dispatch('errors/setAxiosError', { error: { ...err } }, { root: true }))
  }
}
