import _get from 'lodash/get'
import urlJoin from 'url-join'

export default {
  /**
   * Fetch connected user datausersApi
   * @returns {Promise<any | never>}
   */
  getUser () {
    const path = urlJoin(
      'applications',
      this.getDefaultApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.user')
    )
    const method = 'api.users.getUser()'

    return this.$http.tvm.auth.get(path)
      .then((response) => {
        const headers = _get(response, 'config.headers')
        return this.$httpUtils.parseResponse(response, false).then(user => ({ user, headers }))
      })
      .catch((responseError) => {
        responseError.serverDate = _get(responseError, 'response.headers.date')
        return this.$httpUtils.catchError({ responseError, method })
      })
  },
  /**
   * Post the error logs encountered by the user
   * Note: we don't catch error with http-utils because we don't want to log neither show it
   * @returns {Promise<T | never>}
   * @private
   */
  postErrorLogs (error) {
    const path = _get(this.$config, 'confetti.public.api.tvm.paths.errorLogs')

    return this.$http.tvm.authErrorLogs.post(path, error)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => Promise.resolve(responseError))
  }
}
