export default {
  /**
   * Set Meta
   * @param state
   * @param meta
   */
  setMeta (state, meta) {
    state.meta = meta
  }
}
