export default {
  /**
   * Set Previous Route
   * @param state
   * @param previousRoute
   */
  setPreviousRoute (state, previousRoute) {
    state.previousRoute = previousRoute
  },
  /**
   * Set showAll for Home view
   * @param state
   * @param activation
   */
  setShowAllHome (state, activation) {
    state.showAllHome = activation
  },
  /**
   * Set showAll for Evening view
   * @param state
   * @param activation
   */
  setShowAllEvening (state, activation) {
    state.showAllEvening = activation
  },
  /**
   * Set showAll for AvailableRecordings view
   * @param state
   * @param activation
   */
  setShowAllAvailableRecordings (state, activation) {
    state.showAllAvailableRecordings = activation
  },
  /**
   * Set showAll for ScheduledRecordings view
   * @param state
   * @param activation
   */
  setShowAllScheduledRecordings (state, activation) {
    state.showAllScheduledRecordings = activation
  },
  /**
   * Show modal
   * @param state
   */
  showModal (state) {
    state.showModal = true
  },
  /**
   * Close modal - Remove last entry from modals to open when closing
   * @param state
   */
  closeModal (state) {
    state.showModal = false
    state.modalsToOpen.pop()
  },
  /**
   * Add modal to open
   * if modal already opende, closing/remove previous one before showing new one - else add modal
   * @param state
   * @param modalToOpen
   */
  addModalToOpen (state, modalToOpen) {
    if (state.showModal) {
      state.showModal = false
      state.modalsToOpen.pop()
    }

    state.modalsToOpen.push(modalToOpen)
  },
  /**
   * Clear all modals to open
   * @param state
   */
  clearModalsToOpen (state) {
    state.showModal = false
    state.modalsToOpen = []
  },

  /**
   * set freeAccessChannelsAcknowledged to true
   * @param {*} state
   */
  setFreeAccessChannelsAcknowledged (state) {
    state.freeAccessChannelsAcknowledged = true
  }
}
