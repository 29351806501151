import global from './global'
import utils from './utils'
import home from './home'
import evening from './evening'
import programGrid from './programGrid'
import ads from './ads'
import thematique from './thematique'
import fip from './fip'
import pageTitle from './pageTitle'
import npvr from './npvr'
import player from './player'

export default {
  global,
  utils,
  home,
  ads,
  evening,
  programGrid,
  thematique,
  fip,
  pageTitle,
  npvr,
  player
}
