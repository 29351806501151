<template>
  <div class="autopromo">
    <div
      v-if="autopromo && autopromo.length > 0"
      v-touch:swipe.left="onSwipeLeft"
      v-touch:swipe.right="onSwipeRight"
      class="slider-container"
      @mouseover="stop()"
      @mouseleave="start()">
      <div class="slider">
        <a
          v-for="(promo, i) in autopromo"
          :key="i"
          v-tealium:click="$stats.home.onClickAutoPromo(getPromoAlt(promo), getImgUrl(promo))"
          :data-pdb-zid="getPromoAdvZid(i)"
          :data-pdb-ta="getPromoAdvTracker(promo)"
          :data-pdb-idc="getPromoAdvIdc(promo)"
          :data-pdb-vi="getImgUrl(promo, 'img46', { width: 960 })"
          :data-oevent-category="getPromoAdvZid(i)"
          :data-oevent-action="getPromoAdvIdc(promo)"
          :data-oevent-label="getImgUrl(promo, 'img46', { width: 960 })"
          :data-oevent-other="getPromoAdvOther(i)"
          :class="['slide', { 'current' : currentSlideIndex === i, 'prev' : prevSlideIndex === i && autopromo.length > 1}]"
          :aria-label="getPromoAlt(promo)"
          data-pdb-sc="adv"
          tabindex="0"
          @click="clickPromoUrl(promo)"
          @keydown.prevent.enter="clickPromoUrl(promo)"
          @blur="onTabLeave"
          @keyup.tab="onTab(i)">
          <div class="placeholder">
            <wptv-icon
              name="placeholder"
              width="90px"
              height="90px" />
          </div>
          <picture>
            <source
              :srcset="getImgUrl(promo, 'img47', { width: 480 }) "
              media="(max-width: 479px)">
            <source
              :srcset="getImgUrl(promo, 'img48', { width: 600 }) "
              media="(max-width: 599px)">
            <source
              :srcset="getImgUrl(promo, 'img48', { width: 750 }) "
              media="(max-width: 735px)">
            <source
              :srcset="getImgUrl(promo, 'img46', { width: 960 })"
              media="(max-width: 1199px)">
            <img :src="getImgUrl(promo, 'img46', { width: 960 })" alt="">
          </picture>
        </a>
      </div>
      <div
        v-if="!isMobile && autopromo.length > 1"
        v-tealium:click="$stats.home.onClickAutoPromoArrow('chevron_precedent')"
        aria-hidden="true"
        tabindex="-1"
        class="arrow left-arrow"
        @click="onArrowClick(-1)">
        <wptv-icon
          :mirror="true"
          :invert="true"
          height="40px"
          width="14px"
          name="arrow" />
      </div>
      <div
        v-if="!isMobile && autopromo.length > 1"
        v-tealium:click="$stats.home.onClickAutoPromoArrow('chevron_suivant')"
        aria-hidden="true"
        tabindex="-1"
        class="arrow right-arrow"
        @click="onArrowClick(1)">
        <wptv-icon
          :invert="true"
          height="40px"
          width="14px"
          name="arrow" />
      </div>
      <div
        class="tabs"
        aria-hidden="true">
        <div
          v-for="(promo, i) in autopromo"
          :key="i"
          v-tealium:click="$stats.home.onClickAutoPromoDots(getPromoAlt(promo))"
          :class="['dash', { 'current' : currentSlideIndex === i }]"
          tabindex="-1"
          @click="currentSlideIndex = i" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _get from 'lodash/get'
import _debounce from 'lodash/debounce'
import { WptvIcon } from '@wptv/components/ui/icon'
import { makeCompteOneRedirectUrlHelper } from '../../../../shared/utils/compteOneRedirect.utils'

export default {
  name: 'Autopromo',
  components: { WptvIcon },
  props: {
    page: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      currentSlideIndex: 0,
      slideInterval: null,
      paused: false
    }
  },
  computed: {
    prevSlideIndex () {
      return this.currentSlideIndex - 1 >= 0 ? this.currentSlideIndex - 1 : this.autopromo.length - 1
    },
    ...mapGetters({
      autopromo: 'autopromo/getAutopromoCarrousel',
      profile: 'core/getProfile',
      isMobile: 'useragent/isCompatibleMobile'
    }),
    auto () {
      return this.autopromo && this.autopromo.length > 1
    }
  },
  watch: {
    auto () {
      this.setSlideInterval()
    }
  },
  mounted () {
    this.$store.dispatch('autopromo/getAutopromo')
    this.setSlideInterval()

    this.$root.$on('carousel-stop', () => (this.paused = true))
    this.$root.$on('carousel-start', () => (this.paused = false))
  },
  beforeDestroy () {
    this.clearSlideInterval()
  },
  methods: {
    onArrowClick: _debounce(function (offset) {
      this.clearSlideInterval()
      this.slide(offset)
      this.setSlideInterval()
    }, 500, { leading: true, trailing: false, maxWait: 500 }),
    slide (offset) {
      if (this.currentSlideIndex + offset >= 0 && this.autopromo && this.currentSlideIndex + offset < this.autopromo.length) {
        this.currentSlideIndex += offset
      } else if (this.currentSlideIndex + offset < 0) {
        this.currentSlideIndex = this.autopromo.length - 1
      } else {
        this.currentSlideIndex = 0
      }
    },
    stop () {
      this.clearSlideInterval()
    },
    start () {
      this.setSlideInterval()
    },
    clickPromoUrl (promo) {
      const redirectTargetUrl = _get(promo, 'response.OffersSpecification.Argumentation.argumentationValue.url')
      if (redirectTargetUrl) {
        let url
        if (!this.profile.isAuthenticated) {
          url = redirectTargetUrl
        } else {
          url = makeCompteOneRedirectUrlHelper.bind(this)(redirectTargetUrl)
        }
        window.open(url)
      }
    },
    getPromoAlt (promo) {
      return _get(promo, 'response.OffersSpecification.Argumentation.argumentationValue.alt')
    },
    getPromoAdvZid (index) {
      return `web-tv_${this.page}_top_carrousel_${index}`
    },
    getPromoAdvOther (index) {
      return JSON.stringify({
        track_refZ: this.getPromoAdvZid(index)
      })
    },
    getPromoAdvTracker (promo) {
      return _get(promo, 'response.OffersSpecification.Argumentation.argumentationValue["adv_tracker "]')
    },
    getPromoAdvIdc (promo) {
      // "<OffersSpecification.offerSpecificationCode>,<OffersSpecification.parentOfferSpecificationCode>" si OffersSpecification.parentOfferSpecificationCode est rempli sinon "<OffersSpecification.offerSpecificationCode>"
      if (_get(promo, 'response.OffersSpecification.parentOfferSpecificationCode')) {
        return `${_get(promo, 'response.OffersSpecification.offerSpecificationCode')},${_get(promo, 'response.OffersSpecification.parentOfferSpecificationCode')}`
      }
      return _get(promo, 'response.OffersSpecification.offerSpecificationCode')
    },
    getImgUrl (promo, key, format) {
      const urlTpl = _get(promo, 'response.OffersSpecification.Argumentation.argumentationValue.images.urlImageResize')
      const image = _get(promo, `response.OffersSpecification.Argumentation.argumentationValue.images[${key}]`)
      return urlTpl
        ? urlTpl
          .replace('$(IMAGE)', encodeURIComponent(image))
          .replace('$(FORMAT_NAME)', format ? (format.width + 'x') : '')
        : ''
    },
    onSwipeLeft () {
      this.onArrowClick(1)
    },
    onSwipeRight () {
      this.onArrowClick(-1)
    },
    setSlideInterval () {
      if (this.auto) {
        this.slideInterval = setInterval(() => {
          if (!this.paused) {
            this.slide(1)
          }
        }, 5000)
      }
    },
    clearSlideInterval () {
      if (this.slideInterval) {
        clearInterval(this.slideInterval)
      }
    },
    onTab (index) {
      this.clearSlideInterval()
      this.currentSlideIndex = index
    },
    onTabLeave () {
      if (this.currentSlideIndex === 0 || this.currentSlideIndex === this.autopromo.length - 1) {
        this.setSlideInterval()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.autopromo {
  position: relative;
  overflow: hidden;
  width: 100%;
  @include mediaQueries('width', (medium: 960px));
  @include mediaQueries('height', (medium: 310px));
  @media (max-width: 480px) {
    @include aspect-ratio(1.2, 1%);
  }
  @media (min-width: 480px) and (max-width: 599px) {
    height: 240px;
  }
  @media (min-width: 600px) and (max-width: 735px) {
    height: 300px;
  }
  transform: translateX(0);

  .slider-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .slider {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;

      .slide {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        transition: 0.5s ease-out;
        height: 100%;
        cursor: pointer;

        &:not(.current) {
          transition: left 0s;
          transition-delay: 0.5s;
          left: 100%;
        }

        &.prev {
          z-index: 2;
          left: -100%;
        }

        &.current {
          z-index: 3;
        }

        &:focus {
          transition: left 0s;
        }

        &:focus:not(:hover) img{
          outline: solid $primary 3px;
          outline-offset:-3px;
        }

        .placeholder {
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: $bglightgrey;
          text-align: center;

          /deep/ div#placeholder {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        picture, img {
          position: absolute;
          height: 100%;
        }
      }
    }
  }

  .arrow {
    position: fixed;
    height: 40px;
    width: 40px;
    line-height: 40px;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;

    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.6);
    }

    &.right-arrow {
      right: 0;
    }
  }

  .tabs {
    z-index: 4;
    position: fixed;
    bottom: 10px;
    width: 100%;
    text-align: center;

    .dash {
      width: 45px;
      height: 3px;
      background-color: $bglightgrey;
      display: inline-block;
      margin: 0 5px;
      transition: height 0.2s;
      cursor: pointer;

      &:hover,
      &:focus,
      &.current {
        height: 6px;
      }

      &.current {
        background-color: $primary;
      }
    }
  }
}
</style>
