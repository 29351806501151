const middleware = {}

middleware['checkups/index'] = require('../src/middleware/checkups/index.js')
middleware['checkups/index'] = middleware['checkups/index'].default || middleware['checkups/index']

middleware['checkups/user/user'] = require('../src/middleware/checkups/user/user.js')
middleware['checkups/user/user'] = middleware['checkups/user/user'].default || middleware['checkups/user/user']

middleware['route'] = require('../src/middleware/route.js')
middleware['route'] = middleware['route'].default || middleware['route']

export default middleware
