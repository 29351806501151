import getNDigitNumber from '../../../plugins/filters/getNDigitNumber/getNDigitNumber.filter'
import _get from 'lodash/get'

/**
 * Format series information to get S01E01
 * @param program
 * @returns {string}
 */
export function formatSeriesInfo (program) {
  const seasonNumber = _get(program, 'season.number', _get(program, 'seasonNumber', 0))
  const episodesCount = _get(program, 'season.episodesCount', _get(program, 'episodesCount', 0))
  const episodeNumber = _get(program, 'episodeNumber', 0)
  const title = _get(program, 'title', 'no title')

  const formattedSeason = getNDigitNumber(seasonNumber, seasonNumber.toString().length)

  let formattedEpisode = getNDigitNumber(episodeNumber, episodeNumber.toString().length)

  if (episodesCount > 99) {
    if (episodeNumber < 10) {
      formattedEpisode = getNDigitNumber(episodeNumber, episodeNumber.toString().length + 2) // '001'
    } else if (episodeNumber < 99) {
      formattedEpisode = getNDigitNumber(episodeNumber, episodeNumber.toString().length + 1) // '010'
    } else {
      formattedEpisode = getNDigitNumber(episodeNumber, episodeNumber.toString().length)
    }
  }

  let formattedSeriesInfo = ''

  if (formattedSeason === '00' || formattedEpisode === '00') {
    formattedSeriesInfo = `${title}`
  } else {
    formattedSeriesInfo = `S${formattedSeason}E${formattedEpisode} - ${title}`
  }

  return formattedSeriesInfo
}
