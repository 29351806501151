import { getUrlBoutique, isLocked } from '../../../components/live/shared/bouquets/bouquets.utils'
import { makeCompteOneRedirectUrl } from '../../../components/shared/utils/compteOneRedirect.utils'
import CONSTANTS from '../../../api.constants'

/**
 * Check if user is in Ue
 * @param profile
 * @returns {Promise<void>|Promise<never>}
 */
function isUe (profile) {
  return profile.isUe ? Promise.resolve() : Promise.reject({ componentName: 'OutOfEu' })
}

/**
 * Check if the user is not suspended
 * Pass isOwner value if suspended, showing different wording
 * @param profile
 * @returns {Promise<void>|Promise<never>}
 */
function isNotSuspended (profile) {
  return !profile.isAuthenticated || profile.suspended === CONSTANTS.SUSPENDED_CODES.NA
    ? Promise.resolve()
    : Promise.reject({
      componentName: 'Suspended',
      props: { suspended: profile.suspended }
    })
}

/**
 * Check if token tv init is on error
 * @param store
 * @returns {Promise<void>|Promise<never>}
 */
function hasErrorOnTokenTvInit (store) {
  return !store.getters['core/getErrorOnTvTokenInit'] ? Promise.resolve() : Promise.reject({ componentName: 'ErrorTvInitModal' })
}

/**
 * Check if user is authenticated | and if route requires auth
 * @param requiresAuth
 * @param profile
 * @returns {Promise<void>|Promise<never>}
 */
function isAuthenticated (requiresAuth, profile) {
  return profile.isAuthenticated || !requiresAuth ? Promise.resolve() : Promise.reject({ componentName: 'Authentication' })
}

/**
 * Check if channel and user stb only
 * @param userApplication
 * @param {*} channel
 */
function isNotStbOnly (userApplication, channel) {
  const stbOnly = (userApplication && userApplication !== 'PC') || !channel.allowedDeviceCategories.includes('W_PC')

  return stbOnly ? Promise.reject({ componentName: 'StbOnly', props: { channel } }) : Promise.resolve()
}

/**
 * check si l'utilisateur a les bouquets pour une chaine
 * sinon reject avec l'url de la boutique
 * @param store
 * @param {*} channel
 */
function hasBouquetsForChannel (store, channel, confetti) {
  const user = store.getters['user/getUser']
  const userPackages = store.getters['npvr/getUserPackages']
  const mco = store.getters['user/getMCO']
  const profile = store.getters['core/getProfile']
  const profileOptions = store.getters['core/getInstanceInfo']
  const extInstanceId = profileOptions.claims.ext_instance_id

  if (isLocked(userPackages, channel.packages)) {
    return Promise.reject({
      url: new URL(getUrlBoutique(user.sosh, mco, profile.isMobile, extInstanceId, channel.editoChannelId, confetti)),
      tab: true
    })
  }

  return Promise.resolve()
}

/**
 * Check if user has NPVR option
 * @param store
 * @param confetti
 */
function isNpvrSubscribed (store, confetti) {
  const isNpvrSubscribed = store.getters['npvr/isNpvrSubscribed']

  if (isNpvrSubscribed) {
    return Promise.resolve()
  } else {
    const apiRedirectCompteOne = confetti.public.boutique.urlBoutiqueCompteOne
    const profileOptions = store.getters['core/getInstanceInfo']
    const extInstanceId = profileOptions.claims.ext_instance_id

    const redirectTargetUrl = confetti.public.urls.npvrSubscriptionPage
    const compteOneUrl = makeCompteOneRedirectUrl(apiRedirectCompteOne, redirectTargetUrl, extInstanceId)
    const url = new URL(compteOneUrl)

    return Promise.reject({ url, tab: false })
  }
}

// ------------------------------------------------------------------------

export default {
  isUe,
  isNotSuspended,
  hasErrorOnTokenTvInit,
  isAuthenticated,
  isNotStbOnly,
  hasBouquetsForChannel,
  isNpvrSubscribed
}
