export default {
  DEFAULT_TIMEOUT: 1000,
  DEFAULT_UNDEFINED: 'UNDEFINED',
  OPTIONS: {
    UNAVAILABLE: 'UNAVAILABLE',
    ADMIN: 'ADMIN'
  },
  ERRORS: {
    EXPIRED_CODES: [41, 42]
  }
}
