import _get from 'lodash/get'
import urlJoin from 'url-join'
import axios from 'axios'

export default {
  /**
   * getAbout
   * @returns {Promise<AxiosResponse<T>>}
   */
  getAbout () {
    const path = urlJoin(_get(this.$config, 'confetti.public.root.wassup'), 'about')

    return axios.get(path)
      .then((response) => {
        response.data.serverDate = response.headers.date
        return response
      })
  }
}
