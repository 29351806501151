import _get from 'lodash/get'

export default {
  /**
   * Check if placeholder
   * @param state
   * @returns {boolean}
   */
  hasPlaceholderAvailable (state) {
    return !!state.programsByChannels.find(programsByChannel => Object.keys(programsByChannel.channel).length === 0)
  },
  /**
   * Return stored genre
   * @param state
   * @returns {*}
   */
  getGenre (state) {
    return state.genre
  },
  /**
   * Return stored period
   * @param state
   * @returns {*}
   */
  getPeriod (state) {
    return state.period
  },
  /**
   * Return stored scrollPosition
   * @param state
   * @returns {number|methods.$refs.gridProgramItems.clientWidth}
   */
  getScrollPosition (state) {
    return state.scrollPosition
  },
  /**
     * Return stored programs by channels
     * @param state
     * @returns{{}}
     */
  getProgramsByChannels (state) {
    return state.programsByChannels
  },
  /**
   * Get last Channel Id to retrieve next ones
   * @param state
   * @returns {*}
   */
  getLastChannelId (state) {
    const nextItem = state.programsByChannels.find(programChannel => Object.keys(programChannel.channel).length === 0)
    const nextItemIndex = state.programsByChannels.indexOf(nextItem)
    return _get(state.programsByChannels, `[${nextItemIndex - 1}].channel.idEPG`)
  },
  /**
   * Get Programs by channels count (ignoring empty elements (used for placeholders
   * @param state
   * @returns {number}
   */
  getProgramsByChannelsCount (state) {
    return state.programsByChannels.filter(program => Object.keys(program.channel).length !== 0).length
  },
  /**
   * Return programs by channels width
   * @param state
   * @returns {string|number}
   */
  getProgramsByChannelsWidth (state) {
    return state.programsByChannelsWidth
  },
  /**
   * Return selectedTimezone
   * @param state
   * @returns{{}}
   */
  getSelectedTimezone (state) {
    return state.selectedTimezone
  },
  /**
   * Return Date
   * @param state
   * @returns{{}}
   */
  getSelectedDate (state) {
    return state.selectedDate
  }
}
