export default {
  /**
   * Set Name
   * @param state
   * @param name
   */
  setName (state, name) {
    state.name = name
  },
  /**
   * Set FullPath
   * @param state
   * @param path
   */
  setFullPath (state, path) {
    state.fullPath = path
  }
}
