import _get from 'lodash/get'

export default {
  /**
   * Get OS Name
   * @param state
   */
  getOsName (state) {
    return _get(state, 'userAgent.os.name')
  },
  /**
   * get Browser name
   * @param state
   * @param getters
   * @returns {string|*}
   */
  getBrowserName (state, getters) {
    return getters.getOsName === 'Tizen' ? 'Chrome' : _get(state, 'userAgent.browser.name')
  },
  /**
   * Check if Android
   * @param state
   * @param getters
   * @returns {boolean}
   */
  isAndroid (state, getters) {
    return getters.getOsName === 'Android'
  },
  /**
   * Check if IOS
   * @param state
   * @param getters
   * @returns {boolean}
   */
  isIOS (state, getters) {
    return getters.getOsName === 'iOS'
  },
  /**
   * Check if IOS or IpadOS
   * @param state
   * @param getters
   * @returns {boolean}
   */
  isAppleOS (state, getters) {
    const appleOSlist = ['iOS', 'iPadOS']
    return appleOSlist.includes(getters.getOsName)
  },
  /**
   * Check if Compatible Mobile
   * @param state
   * @param getters
   * @returns {(function(*, *): boolean)|boolean|(function(): *)|(function(): *)}
   */
  isCompatibleMobile (state, getters) {
    return getters.isAndroid || getters.isIOS
  },
  /**
   * Check if safari
   * @param state
   * @param getters
   * @returns {boolean}
   */
  isSafari (state, getters) {
    return getters.getBrowserName === 'Safari' || getters.getBrowserName === 'Mobile Safari'
  }
}
