export default {
  partSelectionLabel: 'Sélection de la partie de soirée',
  pt1: '1<sup>re</sup> partie de soirée',
  pt2: '2<sup>e</sup> partie de soirée',
  pt1Alternate: 'Première partie de soirée',
  pt2Alternate: 'Deuxième partie de soirée',
  channelsColumHeader: 'Noms des chaînes',
  aucunResultat: `Aucun programme n'est disponible pour l'instant pour cette thématique.`,
  description: 'Le programme TV de ce soir {todayDate} sur tous vos écrans TV d’Orange. Guide télé gratuit de votre soirée où vous voulez, quand vous voulez !',
  title: 'Programme TV de ce soir sur tous vos écrans par Orange.',
  accessibilite: {
    title: 'Programme TV ce soir',
    voir_plus: 'Voir plus de contenu'
  },
  watchProgram: 'Regarder {title}',
  programsTableCaption: 'Programmes de la soirée',
  alternateEpisodeTitle: `Saison {seasonNumber}, épisode {episodeNumber}, {episodeTitle}`
}
