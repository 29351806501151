/**
 * Manage scroll behaviour
 * note: the saved position is null when using router link
 * note: needs to be async due to dom update taking some time and needed for scrolling
 * @param to
 * @param from
 * @param savedPosition
 * @returns {Promise<unknown>}
 */
export default function (to, from, savedPosition) {
  return new Promise((resolve) => {
    setTimeout(() => {
      const mainRoutes = ['HomePage', 'Evening', 'Programs', 'AvailableRecordings', 'ScheduledRecordings']
      const fipRoutes = ['Fip', 'FipRecording']
      // Keep position
      let position = to.path !== from.path && savedPosition ? savedPosition : false

      // For fips, back to top of the page
      // scroll to top while menu sticked but not comming back from Fips
      if (
        fipRoutes.includes(to.name) ||
        (mainRoutes.includes(to.name) && mainRoutes.includes(from.name) && to.name !== from.name)
      ) {
        position = { x: 0, y: 0 }
      }

      resolve(position)
    }, 50)
  })
}
