function onClickBack (previousRoute) {
  return {
    track_page: 'fip',
    track_zone: 'chevron',
    track_nom: 'retour',
    track_cible: previousRoute && previousRoute.path ? previousRoute.path : '/'
  }
}
export default {
  onClickBack
}
