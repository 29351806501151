import _get from 'lodash/get'

export default {
  /**
   * Get route name
   * @param state
   * @returns {*}
   */
  getRouteName (state) {
    return state.name
  },
  /**
   * Get page info
   * @param state
   * @returns {{fullPath, pageName}}
   */
  getPageInfo (state) {
    return {
      pageName: _get(state, 'name', 'no page'),
      fullPath: _get(state, 'fullPath', 'no full path')
    }
  }
}
