export default {
  /**
   * Set certificateSafari
   * @param state
   * @param certificate
   */
  setCertificateSafari (state, { certificate }) {
    const byteArray = new Uint8Array(certificate)
    const string = String.fromCharCode.apply(null, byteArray)

    state.certificateSafari = btoa(string)
  },
  /**
   * Set certificateChrome
   * @param state
   * @param certificate
   */
  setCertificateChrome (state, { certificate }) {
    const byteArray = new Uint8Array(certificate)
    const string = String.fromCharCode.apply(null, byteArray)

    state.certificateChrome = btoa(string)
  }
}
