import _get from 'lodash/get'
import urlJoin from 'url-join'

export default {
  /**
   * Fetch channel stream info
   * @param application
   * @param payload
   * @returns {Promise<any | never>}
   */
  getChannelStream ({ payload }) {
    const path = urlJoin(
      'applications',
      this.getDefaultApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.channels'),
      payload.channelId.toString(),
      _get(this.$config, 'confetti.public.api.tvm.paths.stream'))
    const method = 'api.channels.getChannelStream()'

    return this.$http.tvm.auth.get(path, {
      params: {
        sessionId: payload.sessionId,
        terminalModel: payload.terminalModel,
        terminalId: payload.terminalId
      }
    })
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method, bypass: true }))
  }
}
