export default {
  generic: {
    notFound: `Désolé, la page demandée n'est plus disponible`,
    unknown: `Une erreur est survenue`,
    or: 'ou',
    redirection: `Vous allez être redirigé(e) vers la page d'accueil de la tv en direct dans {count} ...`,
    link: `Cliquez ici`
  },
  modals: {
    NOT_ELIGIBLE: {
      title: 'Accès réservé',
      text: `Vous devez être identifié avec votre compte client Internet Orange ou Sosh, et disposer d'une offre TV pour accéder à la TV d'Orange sur votre ordinateur.`,
      theme: 'warning'
    },
    NOMADISM_FORBIDDEN: {
      title: 'Accès réservé',
      text: `Cette chaîne ne peut être visualisée qu'à domicile. Veuillez vous connecter à votre Livebox.`,
      theme: 'warning'
    },
    CHANNEL_NOT_SUBSCRIBED: {
      title: 'Avertissement',
      text: 'Cette chaîne ne fait pas partie de votre offre. Pour faire évoluer votre offre TV, rendez-vous sur la boutique en ligne.',
      theme: 'warning'
    },
    NOT_UE: {
      title: 'Accès hors Union Européenne',
      text: `La TV d'Orange sur tous les écrans est disponible depuis l'Union Européenne. La liste des pays ainsi que les restrictions sont disponibles sur <a href="{assistanceLink}" target="_blank">assistance.orange.fr</a>`,
      theme: 'info'
    },
    SIMULTANEOUS_REACH: {
      title: 'Droit de lecture simultanée limité',
      text: `Cette chaîne est actuellement visionnée sur vos autres équipements et le maximum autorisé est atteint. Veuillez d'abord arrêter un des visionnages avant d'en lancer un nouveau.`,
      theme: 'info'
    },
    MAX_TERMINALS: {
      title: 'Nombre maximum de terminaux atteint',
      text: 'Vous avez atteint le nombre maximal d\'écrans autorisés pour regarder les chaînes TV d\'Orange.<br/>Pour les regarder sur cet ordinateur, veuillez contacter votre service clients.',
      theme: 'warning'
    },
    TECHNICAL: {
      title: 'Une erreur est survenue ({statusCode}/{errorCode})',
      text: `Merci d'essayer à nouveau. </br>Si le problème persiste, veuillez contacter le support via le lien "Assistance" en bas de page.`,
      theme: 'warning'
    },
    NOT_AVAILABLE: {
      title: 'Avertissement',
      text: `Attention ce programme n'est disponible que sur votre décodeur.`,
      theme: 'warning'
    },
    GENERIC: {
      title: 'Une erreur est survenue ({statusCode}/{errorCode})',
      titleWithoutErrorCode: 'Une erreur est survenue ({statusCode})',
      text: `Merci d'essayer à nouveau. </br>Si le problème persiste, veuillez contacter le support via le lien "Assistance" en bas de page.`,
      theme: 'warning'
    },
    confirm: 'ok',
    moreInfo: 'Plus d\'informations',
    MAX_QUOTA_EXCEEDED: {
      title: 'Quota épuisé',
      text: `L'enregistrement de {programName} sur {channelName} ne peut pas être effectué ni modifié car vous avez dépassé le quota autorisé. Veuillez supprimer des enregistrements si vous souhaitez disposer plus d'espace et poursuivre l'enregistrement de votre programme.`,
      theme: 'warning'
    },
    SIMULTANEOUS_RECORDING_REACH: {
      title: 'Erreur',
      text: 'Attention! Le nombre maximal d’enregistrements simultanés autorisé est atteint. Pour effectuer cet enregistrement, vous devez supprimer un des enregistrements programmés sur ce créneau horaire.',
      theme: 'erreur'
    },
    STB_ONLY: {
      title: 'Accès limité',
      text: 'Cet enregistrement est disponible uniquement sur votre décodeur.',
      theme: 'info'
    },
    NPVR_MAX_TERMINALS: {
      title: 'Nombre maximum de terminaux atteint',
      text: `Vous avez atteint le nombre maximal d’équipements enregistrés. Vous ne pourrez pas regarder cet enregistrement sur ce navigateur internet.</br>Vous pouvez le regarder sur votre décodeur TV d'Orange ou sur un autre écran enregistré.`,
      theme: 'warning'
    },
    NPVR_OCCULTATION: {
      title: 'Enregistrement restreint',
      text: `À la demande de la chaine, l'enregistrement de ce programme est restreint sur votre zone géographique pour des raisons de droits.`,
      theme: 'info'
    },
    NPVR_SIMULTANEOUS_REACH: {
      title: ' Droit de lecture simultanée limité',
      text: `Cet enregistrement est actuellement visionné sur vos autres équipements et le maximum autorisé est atteint.</br>Veuillez d'abord arrêter un des visionnages avant d'en lancer un nouveau.`,
      theme: 'info'
    }
  }
}
