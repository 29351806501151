function onClickAds () {
  return {
    track_page: 'header',
    track_zone: 'orange_ads',
    track_nom: 'consulter_pub'
  }
}

export default {
  onClickAds
}
