export default {
  title: `TV en direct`,
  myrecordings: 'Mes enregistrements',
  storage: 'Stockage',
  storageUnavailable: 'Information de stockage indisponible',
  retour: {
    HomePage: `Retour vers Maintenant`,
    Evening: `Retour vers Ce soir`,
    Programs: `Retour vers Programme TV`,
    AvailableRecordings: `Retour vers Mes enregistrements disponibles`,
    ScheduledRecordings: `Retour vers Mes enregistrements programmés`,
    FipRecording: `Retour vers Mon enregistrement`,
    Fip: 'Retour vers le programme',
    default: `Retour vers TV en direct`
  },
  scrollTop: `Remonter en haut de page`,
  noContent: `Aucun contenu à afficher`,
  noContentThematique: `Aucun programme n'est disponible pour l'instant pour cette thématique`,
  assistance: 'Assistance TV',
  successUpdate: 'Modification prise en compte',
  successDelete: 'Suppression prise en compte',
  failureDelete: 'Une erreur est survenue lors de la suppression',
  successCreateProgrammed: `L'enregistrement a bien été programmé`,
  successCreateTimeBased: `L'enregistrement a bien démarré`,
  menu: {
    home: `Maintenant`,
    evening: `Ce soir`,
    all: `Programme TV`,
    recordings: `Mes enregistrements`
  },
  menuNpvr: {
    available: `Disponibles`,
    scheduled: `Programmés`
  },
  min: `min`,
  duration: {
    min: `{duration} min`
  },
  items: {
    noTitle: `Pas d'information disponible`,
    noSxxEyy: `Série`,
    remainingTime: `Reste {time} min`,
    remainingTimeAlternate: `Reste moins d'une minute | Reste une minute | Reste {n} minutes`
  },
  thematiques: {
    tous: `Tous`,
    plus: `Plus`,
    filters: `Thématiques`
  },
  csa: {
    1: ``,
    2: `Déconseillé aux moins de 10 ans`,
    3: `Déconseillé aux moins de 12 ans`,
    4: `Déconseillé aux moins de 16 ans`,
    5: `Déconseillé aux moins de 18 ans`
  },
  durationSuffix: 'Moins d\'une minute | Une minute | {n} minutes',
  sxxeyyAlternate: 'Saison {seasonNumber} épisode {episodeNumber}',
  moreInfosAbout: 'Plus d\'informations sur',
  deleteModal: {
    confirmButton: 'OK',
    cancelButton: 'Annuler'
  }
}
