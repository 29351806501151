/**
 * Retrieve track page
 * @param value
 * @returns {string}
 */
function getTrackPage (value) {
  switch (value) {
    case 'home':
      return 'maintenant'
    case 'ce-soir':
      return 'ce_soir'
    case 'programmes':
      return 'ProgTV'
    case 'enregistrements-disponibles':
      return 'enregistrements-disponibles'
    case 'enregistrements-programmes':
      return 'enregistrements-programmes'
    default:
      return 'maintenant'
  }
}

// ---------------------------------------------

export default {
  getTrackPage
}
