import _get from 'lodash/get'
import urlJoin from 'url-join'

export default {
  /**
   * Retrieve NPVR user status
   * @returns {Promise<any | never>}
   */
  getStatus () {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.recordingsBaseUrl'),
      _get(this.$config, 'confetti.public.api.gateway.paths.status')
    )
    const method = 'api.gateway.npvr.getStatus()'

    return this.$http.gateway.npvr.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method, type: this.$httpUtils.API_TYPE.GATEWAY }))
  },
  /**
   * Retrieve user's recordings
   * @returns {Promise<any | never>}
   */
  getRecordings (customerOrangePopulation) {
    const path = urlJoin(_get(this.$config, 'confetti.public.api.gateway.paths.recordingsBaseUrl'))
    const options = {
      params: {
        customerOrangePopulation
      }
    }
    const method = 'api.gateway.recordings.getRecordings()'
    return this.$http.gateway.npvr.auth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method, type: this.$httpUtils.API_TYPE.GATEWAY }))
  },

  /**
   * Delete a recording
   * @param recordingId
   * @returns {Promise<postcss.Result | never> | Q.Promise<never> | Promise<any>}
   */
  deleteRecording (recordingId) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.recordingsBaseUrl'),
      `${recordingId}`
    )
    const method = 'api.gateway.recordings.deleteRecording()'

    return this.$http.gateway.npvr.auth.delete(path)
      .catch(responseError => this.$httpUtils.catchError({ responseError, method, type: this.$httpUtils.API_TYPE.GATEWAY }))
  },
  /**
   * Update a recording
   * @param recordingId
   * @param queryParams
   * @returns {Promise<any | never>}
   */
  updateRecording (recordingId, queryParams) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.recordingsBaseUrl'),
      `${recordingId}`
    )
    const method = 'api.gateway.recordings.updateRecording()'

    return this.$http.gateway.npvr.auth.put(path, { ...queryParams })
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method, type: this.$httpUtils.API_TYPE.GATEWAY }))
  },

  /**
   * Create a recording
   * @param {*} recording
   * @returns {Q.Promise<never>}
   */
  createRecording (recording) {
    const path = urlJoin(_get(this.$config, 'confetti.public.api.gateway.paths.recordingsBaseUrl'))
    const method = 'api.gateway.recordings.createRecording()'

    return this.$http.gateway.npvr.auth.post(path, recording)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method, type: this.$httpUtils.API_TYPE.GATEWAY }))
  },
  /**
   * Retrieve play info for a specific recording
   * @param recordingId
   * @param params
   * @returns {Q.Promise<never>}
   */
  getPlayRecording (recordingId, params) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.playRecording'),
      `${recordingId}`
    )
    const method = 'api.gateway.recordings.getPlayRecording()'
    const options = { params }

    return this.$http.gateway.stream.auth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method }))
  }
}
