export default {
  title: 'Fiche info',
  HD: 'HD',
  direct_acces: 'Accéder au direct',
  watch: 'Regarder',
  record: 'Enregistrer',
  program_record: 'Programmer un enregistrement',
  program_record_lite: 'Programmer enregistrem.',
  directed_by: 'De :',
  and: 'et',
  with: 'Avec :',
  presented_by: 'Présenté par',
  a11y: {
    HD: 'Disponible en HD',
    hearing_impaired_subtitles_available: 'Sous titrage pour malentendant disponible',
    'vostfr-vf': 'Version originale sous-titrée et Version française disponibles',
    vm: 'Version multilingue disponible',
    vostfr: 'Version originale sous-titrée disponibles',
    vo: 'Version originale disponible',
    vf: 'Version française disponible',
    audio_description_available: 'Audio description disponible',
    country: `Pays d'origine`,
    production_year: `Produit en`
  },
  duration: `Durée: {duration}`,
  recording: {
    delete_button: `Supprimer l'enregistrement`,
    edit_button: `Modifier l'enregistrement`,
    record_schedule: `Enregistrement de {start} à {end} (durée {duration})`,
    recording_available: 'Enregistrement disponible',
    recording_in_progress: 'Enregistrement en cours',
    recording_programmed: 'Enregistrement programmé',
    recordings_access: `Accéder`,
    recordings_access_full: `Accéder à mes enregistrements`,
    recordings_edit: `Modifier`,
    recordings_edit_full: `Modifier l'enregistrement`
  },
  rerun: {
    title: `Rediffusions`,
    description: `Retrouvez toutes les rediffusions de ce programme.`,
    tomorrowPrefix: `Demain à`
  },
  replay: {
    title: `Replay`,
    description: `Retrouvez tous les replay de la chaîne {channelName}.`,
    access: `Accéder au replay de {channelName}`
  }
}
