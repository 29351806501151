export const ONEPLAYERUI_CONFIG = {
  DEFAULT_ERROR: 'no player code',
  DEFAULT_NO_CODE_FOUND_ERROR: 'UNKNOWN CODE'
}

export const PLAYER_CONFIG = {
  TERMINAL_MODEL: {
    SAFARI: 'WEB_SAFARI',
    PC: 'WEB_PC'
  },
  PROTOCOL: {
    HLS: 'HLS',
    MSS: 'MSS',
    DASH: 'DASH'
  },
  PLAYER_TYPE: {
    HLSFP: 'HLSFP',
    DASHJS: 'DASHJS'
  },
  SERVICE: {
    LIVE: 'LIVE',
    ONDEMAND: 'ONDEMAND'
  },
  VIDEO_TYPE: {
    MOVIE: 'MOVIE',
    EPISODE: 'EPISODE',
    RECORD: 'RECORD'
  },
  DRM: {
    KEY_SYSTEM_MICROSOFT: 'com.microsoft.playready',
    KEY_SYSTEM_CHROME: 'com.widevine.alpha',
    KEY_SYSTEM_APPLE: 'com.apple.fps.1_0',
    PSSH_CHROME: 'AAAARnBzc2gAAAAA7e+LqXnWSs6jyCfc1R0h7QAAACYIARIQ/////////////////////xoMdmlhY2Nlc3NvcmNhKgJTRA==',
    VIDEO_ROBUSTNESS: 'SW_SECURE_DECODE',
    AUDIO_ROBUSTNESS: 'SW_SECURE_CRYPTO',
    PSSH: 'AAAARnBzc2gAAAAA7e+LqXnWSs6jyCfc1R0h7QAAACYIARIQ/////////////////////xoMdmlhY2Nlc3NvcmNhKgJTRA=='
  },
  BACK_BREAKPOINTS: {
    SM_PLUS: 'sm+'
  },
  TIMEOUTS: {
    FLUSH_LAST_PLAYER_ERROR_TIMEOUT: 60000,
    STATS_KEEP_ALIVE_TIMEOUT: 900000
  }
}

export const RECORDINGS_CONFIG = {
  DEFAULT: {
    HD: 'HD',
    DEFINITION: 'SD',
    REGION: 'OFR',
    DEVICE_MODEL: 'WEB_PC',
    DEVICE_MODEL_SAFARI: 'WEB_SAFARI'
  },
  RECORD_STATUS: {
    RECORDED: 'RECORDED',
    RECORDING: 'IN_RECORDING'
  }
}

export const OTT_DATA_COLLECTOR_CONFIG = {
  SERVICE_TYPE: {
    LIVE: 'LIVE',
    NPVR: 'NPVR'
  }
}

export const METRICS_CONFIG = {
  DEFAULT_ENABLE: false,
  DEFAULT_SERVER_URL: '',
  DEFAULT_SENDING_TIME: '300',
  DEFAULT_EVENTS_FILTER: '50'
}

export const RECORD_CONFIG = {
  MERGE_THRESHOLD_MS: 2000
}
