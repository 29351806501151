import _get from 'lodash/get'
import _has from 'lodash/has'
import _isArray from 'lodash/isArray'
import _isPlainObject from 'lodash/isPlainObject'
import consola from 'consola'
import urljoin from 'url-join'

const API_TYPE = {
  GATEWAY: 'GATEWAY',
  TVM: 'TVM',
  USER: 'USER'
}

/**
 * Parse response to resolve or reject based on format
 * @param response
 * @param isResponseArray
 * @param customType
 * @returns {Promise<any>}
 * @private
 */
function _parseResponse (response, isResponseArray, customType) {
  let checkMethod = isResponseArray ? _isArray : _isPlainObject

  if (!isResponseArray && customType) {
    checkMethod = customType
  }

  return checkMethod(response.data)
    ? Promise.resolve(response.data)
    : Promise.reject({ message: 'error response format' })
}

/**
 * Catch error with logs
 * if bypass is true we handle the error as a resolve to continue
 * @param responseError
 * @param path
 * @param method
 * @param bypass
 * @returns {Promise<never>}
 * @private
 */
function _catchError ({ responseError, method, bypass = false, type = API_TYPE.TVM }) {
  const url = urljoin(_get(responseError, 'config.baseURL', ''), _get(responseError, 'config.url', ''))
  let errorMessage = `In ${method}, request to '${url}' failed.\n`
  errorMessage += `--> Reason: ${responseError.message}`

  if (_has(responseError, 'request._header')) {
    errorMessage += '\n\n' + '--> The request headers were: ' + '\n' + responseError.request._header
  }

  consola.error(errorMessage)

  consola.debug('responseError: ' + responseError)

  const response = {
    error: {
      type,
      data: _get(responseError, 'response.data'),
      status: _get(responseError, 'response.status'),
      date: _get(responseError, 'serverDate')
    },
    message: responseError.message,
    config: {
      method,
      url: _formatRequestUrl(responseError.config)
    }
  }
  return bypass ? Promise.resolve(response) : Promise.reject(response)
}

/**
 * format the full request url (ex: https://rp-mediation-tv-q1.woopic.com/live/v3/applications/STB4PC/programs?groupBy=channel&includeEmptyChannels=false)
 * @param {*} config
 */
function _formatRequestUrl (config) {
  const queryStr = new URLSearchParams(_get(config, 'params', {})).toString()
  return urljoin(_get(config, 'baseURL', ''), _get(config, 'url', ''), queryStr)
}

// -----------------------------------------------------

export default ({ store, $config, req }, inject) => {
  inject('httpUtils', {
    API_TYPE,
    parseResponse: _parseResponse,
    catchError: _catchError
  })
}
