import UAParser from 'ua-parser-js'

export default {
  /**
   * Set User Agent
   * @param state
   * @param userAgentString
   */
  setUserAgent (state, userAgentString) {
    state.userAgent = new UAParser(userAgentString).getResult()
  }
}
