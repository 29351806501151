export default {
  /**
   * Set autopromo to store
   * @param state
   * @param autopromo
   */
  setAutopromo (state, autopromo) {
    state.autopromo = autopromo
  }
}
