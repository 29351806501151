function onClickNpvrItemControl (name, page, zone, target) {
  return {
    track_page: page,
    track_nom: name,
    track_zone: zone,
    track_cible: target
  }
}
export default {
  onClickNpvrItemControl
}
