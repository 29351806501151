/**
 * Create a properly formatted URL to use when redirecting through TV Init
 * @param {*} apiRedirectCompteOne The URL of the TV Init API
 * @param {*} redirectTargetUrl The final URL to redirect to
 * @param {*} extInstanceId External Instance ID (for token renewal)
 * @param {*} originId Basicat Code of component source of the treatment (ex : Front)
 * @returns
 */
export function makeCompteOneRedirectUrl (apiRedirectCompteOne, redirectTargetUrl, extInstanceId, encodeUrl = true, originId = 'FLV') {
  const _redirectTargetUrl = encodeUrl ? encodeURIComponent(redirectTargetUrl) : redirectTargetUrl
  const compteOneRedirectUrl = `${apiRedirectCompteOne}?redirect=${_redirectTargetUrl}&externalId=${extInstanceId}&originId=${originId}`
  return compteOneRedirectUrl
}

/**
 * Helper version of method above but must be binded with an object that has access to confetti config and store
 * @param {*} redirectTargetUrl  The final URL to redirect to
 */
export function makeCompteOneRedirectUrlHelper (redirectTargetUrl) {
  const apiRedirectCompteOne = this.$config.confetti.public.boutique.urlBoutiqueCompteOne
  const profileOptions = this.$store.getters['core/getInstanceInfo']
  const extInstanceId = profileOptions.claims.ext_instance_id

  return makeCompteOneRedirectUrl(apiRedirectCompteOne, redirectTargetUrl, extInstanceId)
}
