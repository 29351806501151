import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import PTFinder from '../../components/live/evening/primetime.utils'
import { getLogoFromChannel, setItemImageData } from '../../components/shared/utils/imageData.utils'
import API_CONSTANTS from '../../api.constants'
import { DateTime } from 'luxon'
import conversionTimestamp from '../../plugins/filters/conversionTimestamp/conversionTimestamp.filter'
import { formatSeriesInfo } from '../../components/shared/formatEpisode/formatEpisode.utils'

/**
 * Prepare an eveningProgramsChannel to be used in Evening
 * @param programsByChannel
 * @param channels
 * @param channelId
 * @returns {any}
 * @private
 */
function _setEveningProgramsChannel (programsByChannel, channels, channelId) {
  const channel = channels.find(channel => channel.idEPG === parseInt(channelId))
  const parts = new PTFinder(programsByChannel).getParts()
  const eveningProgram = { channel }
  return channel && (parts.PT1 || parts.PT2) ? Object.assign(eveningProgram, parts) : null
}

/**
 * Get Current Program for a specific channel
 * @param programs
 * @param channel
 * @param time
 * @returns {{channelId}|*}
 * @private
 */
function _getCurrentProgramFromChannel (programs, channel, time) {
  const programsByChannel = programs[channel.idEPG]
  let currentProgram

  if (programsByChannel && !_isEmpty(programsByChannel)) {
    currentProgram = programsByChannel.find((program) => {
      const programEndingDate = program.diffusionDate + program.duration
      return program.diffusionDate * 1000 <= time && programEndingDate * 1000 + 1000 > time
    })
  }
  return currentProgram || { channelId: channel.idEPG }
}

/**
 * Get alt cover for a program
 * @param program
 * @param i18n
 * @returns {string}
 */
function getAltCover (program, i18n) {
  return [
    program.channel.name,
    program.title,
    program.csa > 1 && program.csa < 6 ? i18n.t(`general.csa.${program.csa}`) : null,
    program.diffusionDate && program.duration ? `${conversionTimestamp(program.diffusionDate)} - ${conversionTimestamp(program.diffusionDate + program.duration)}` : null,
    program.isEpisode ? formatSeriesInfo(program) : _get(program, 'genre', ''),
    program.channel.notSubscribed ? i18n.t('accessibilite.accessibilite_chaine_non_souscrite') : null,
    i18n.t('accessibilite.accessibilite_tv_en_direct')
  ].filter(Boolean).join(', ')
}

// ---------------------------------------------------------------------

export default {
  /**
   * tell if comming from fip to not update program grid
   * @param state
   * @param fromFip
   */
  setFromFip (state, fromFip) {
    state.fromFip = fromFip
  },
  /**
   * Set the one program retrieved
   * @param state
   * @param program
   */
  setOneProgram (state, program) {
    state.oneProgram = program
  },
  /**
   * Set programs used in Home
   * @param state
   * @param programs
   */
  setCurrentProgramsByChannels (state, programs) {
    state.currentProgramsByChannels = programs
  },
  /**
   * Set Current Programs for home and player (VZL)
   * @param state
   * @param programs
   * @param userTimeOffset
   * @param channels
   * @param notSubscribed
   */
  setCurrentPrograms (state, { programs, userTimeOffset, channels, notSubscribed }) {
    const time = Date.now() - userTimeOffset // in millis

    state.currentPrograms = channels
      .map((channel) => {
        let currentProgram
        const { title, csa = 0, diffusionDate = 0, duration = 0, ...programData } = currentProgram = _getCurrentProgramFromChannel(programs, channel, time)
        const { name = '', lcn = 0, ...channelData } = channel
        const isEpisode = _get(currentProgram, 'programType') === 'EPISODE'

        const program = {
          channel: {
            logo: getLogoFromChannel(channel, API_CONSTANTS.SQUARE_CHANNEL_LOGO_TYPE),
            notSubscribed: notSubscribed(channel),
            name,
            zappingNumber: lcn,
            ...channelData
          },
          title: title ?? this.app.i18n.t('general.items.noTitle'),
          imageData: setItemImageData(currentProgram, { favoriteFormat: 'RATIO_16_9', availableFormats: [] }, API_CONSTANTS.PAGES.MAINTENANT),
          endDiffusionDate: DateTime.fromMillis(diffusionDate * 1000).plus({ second: duration }).ts,
          isEpisode,
          diffusionDate,
          duration,
          csa,
          ...programData
        }
        program.altCover = getAltCover(program, this.app.i18n)

        return program
      })
  },
  /**
   * Set programs used in evening (adapt datas to match UI)
   * @param state
   * @param programsByChannels
   * @param channelsSTB
   */
  setEveningProgramsByChannels (state, { programsByChannels, channels }) {
    state.eveningProgramsByChannels = Object.keys(programsByChannels)
      .map(channelId => _setEveningProgramsChannel(programsByChannels[channelId], channels, channelId))
      .filter(Boolean)
      .sort((a, b) => channels.indexOf(a.channel) - channels.indexOf(b.channel))
  }
}
