import { DateTime } from 'luxon'
/**
 * @param {Date} dateToFormate expected a DateTime, created by luxon (ex: 'DateTime.local()' for the date of today)
 * @returns a string formated like : 'mercredi 28 avril'
 */

export default function (dateToFormate) {
  if (dateToFormate instanceof DateTime) {
    return dateToFormate.setLocale('fr').toFormat('cccc dd LLLL')
  } else {
    return ''
  }
}
