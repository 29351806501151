<template>
  <div id="app">
    <div class="box">
      <page-title />
      <div
        class="market-banner">
        <autopromo
          page="accueil"
          class="auto-promo" />
        <ads />
      </div>
      <nuxt />
      <wptv-scroll-top-button
        :title="$t('general.scrollTop')"
        :tealium="$stats.global.onClickScrollTopButton(page)"
        tab-target-id="o-search-input"
        data-wptv="mosaique-retour_haut" />
    </div>

    <thematique-filters
      v-if="showFilters"
      ref="filters" />

    <component
      :is="player"
      v-if="player"
      ref="player"
      @player-loaded="play"
      @open-shop="onOpenShop"
      @close-player="setFocusOnPlayerClose" />

    <component
      :is="activeModal"
      v-if="showModal"
      ref="activeModal"
      v-bind="activeModalProps"
      @closed="closeModal" />

    <assistance />
    <footer>
      <wptv-footer :used-o-s="os" />
    </footer>
    <wptv-snackbar />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import commonElements from '../components/shared/commonElements/commonElements.mixin'
import PageTitle from '../components/shared/pageTitle/PageTitle'
import Assistance from '../components/shared/assistance/Assistance'
import playerLiveMixin from '../components/shared/player/playerLive/player-live.mixin'
import { WptvScrollTopButton } from '@wptv/components/ui/scrollTopButton'
import { WptvSnackbar } from '@wptv/components/ui/snackbar'
import { WptvFooter } from '@wptv/components/ui/footer'
import { setFocusOnFirstFocusableChild } from '@wptv/components/utils'
import modals from '../components/shared/modals/modals.mixin'
import Autopromo from '../components/live/shared/mainHeader/autopromo/Autopromo'
import Ads from '../components/shared/ads/Ads'
import ThematiqueFilters from '../components/live/shared/thematique/filterSelector/ThematiqueFilters'
import freeAccessChannelsUtils from '../components/shared/modals/freeAccessChannel/freeAccessChannels.utils'
import CONSTANTS from '../api.constants'

export default {
  name: 'ChainesTvApp',
  components: {
    WptvScrollTopButton,
    WptvSnackbar,
    WptvFooter,
    Assistance,
    Autopromo,
    Ads,
    PageTitle,
    ThematiqueFilters
  },
  mixins: [
    commonElements,
    playerLiveMixin,
    modals
  ],
  data () {
    return {
      showFilters: false,
      os: ''
    }
  },
  computed: {
    ...mapGetters({
      page: 'meta/getPage',
      previousRoute: 'config/getPreviousRoute',
      profile: 'core/getProfile',
      idToFocus: 'user/getIdToFocus',
      user: 'npvr/getUser',
      freeAccessChannels: 'channels/getFreeAccessChannels',
      freeAccessChannelsAcknowledged: 'config/getFreeAccessChannelsAcknowledged',
      osName: 'useragent/getOsName'
    }),
    suspended () {
      return this.profile.suspended === CONSTANTS.SUSPENDED_CODES.SUSPENDED.toUpperCase() ||
      this.profile.suspended === CONSTANTS.SUSPENDED_CODES.SUSPENDED_GUEST.toUpperCase() ||
      this.profile.suspended === CONSTANTS.SUSPENDED_CODES.INVALID.toUpperCase()
    }
  },
  mounted () {
    this.$store.dispatch('programs/getCurrentProgramsByChannels')
    this.$store.commit('npvr/setFromLayout', null)

    // Check each pop-up case to see if we are in one of them, otherwise we display the freeAccessChannels pop-up
    if (this.profile.isAuthenticated && !this.suspended && this.profile.isUe) {
      // FDE: (5929) need to check free access channel clientside for window.localstorage
      if (this.profile.isAuthenticated && !this.freeAccessChannelsAcknowledged) {
        const FAC = freeAccessChannelsUtils.getFreeAccessChannels(this.user, this.freeAccessChannels)
        if (FAC[0]) {
          this.$root.$emit('open-free-access-channel-modal', { freeAccessChannel: FAC[0] })
          this.$store.commit('config/setFreeAccessChannelsAcknowledged')
        }
      }
    }

    // register events
    this.$root.$on('showFilters', toggle => this.onShowFilters(toggle))
    this.$root.$on('selectFilters', () => { this.showFilters = false })

    // Detect if the application runs as a PWA
    if (this.$route.query.standalone) {
      this.sendPwaLaunchStat()
    }
    // done to avoid error if we use the getter 'getOsName' before we set the useragent
    this.os = this.osName
  },
  beforeDestroy () {
    if (this.$refs.player) {
      this.$refs.player.togglePipMode(false)
    }
  },
  methods: {
    setFocusOnPlayerClose () {
      setFocusOnFirstFocusableChild(document.getElementById(this.idToFocus))
    },
    sendPwaLaunchStat () {
      // MHA: this is a hotfix (WEBTV-5651) to send a stat at PWA launch
      // There are limitations with this hotfix that will need to be adress (See WEBTV-5700).
      // The current behavior is:
      // - set a timer to check every second if utag.js has been loaded
      // - when utag.js is loaded, wait 10s more to let the user validate the consent popin.

      const utagLoadTimer = setInterval(() => {
        if (window.utag && window.utag.link) {
          setTimeout(() => {
            this.sendStats(this.$stats.global.onLoadPwa())
          }, 10000)
          clearInterval(utagLoadTimer)
        }
      }, 1000)
    },
    onShowFilters (toggle) {
      this.showFilters = toggle
      if (!toggle) {
        setFocusOnFirstFocusableChild(document.getElementById('btnMore'))
      }
    }
  }
}
</script>

<style lang="scss">
  #app {
    background-color: $white;
  }

  .layout-header {
    display: flex;
    align-items: center;
  }

  #liveSearchEngine {
    display: flex;
    align-items: center;
    z-index: 9;
    top: 0;
    @include mediaQueries('right', (
      xsmall: $global-margin--small,
      medium: $global-margin--medium,
      large: $global-margin--large,
      xlarge: $global-margin--xlarge
    ));
  }

  #modal p {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0.5rem;
  }

  .market-banner {
    position: relative;
    max-width: 1440px;
    background: $bglightgrey;
    align-items: center;
    @include mediaQueries('display', (medium: flex));

    .auto-promo{
      @include mediaQueries('display', (xsmall: none, medium: flex));
    }

    .ads {
      display: flex;
      flex: 1;
      text-align: center;
      position: relative;
      vertical-align: middle;
      justify-content: center;
    }
  }
</style>
