export default () => ({
  previousRoute: {},
  showAllHome: false,
  showAllEvening: false,
  showAllAvailableRecordings: false,
  showAllScheduledRecordings: false,
  showModal: false,
  modalsToOpen: [],
  freeAccessChannelsAcknowledged: false
})
