import { DateTime } from 'luxon'

/**
 * Algo pour essayer de trouver les programmes première partie de soirée et deuxième partie de soirée
 * Basé sur les specs confluence
 * @see https://confluence.sii-ouest.fr/display/LIVE/Choix+PT1+et+PT2
 */
export default class PTFinder {
  /**
   * Set les programmes à parcourir pour trouver les PT1 et PT2
   * A appeler avant getPars
   * @param {Array} programs Tableau des programmes d'une chaine
   */
  constructor (programs) {
    this.pt1 = null
    this.pt2 = null
    this.programs = programs
  }

  /**
   * Si PT1 termine après 22h29 alors on met le même programme pour PT1 e PT2
   * Si PT1 termine avant 22h30 on prend le premier programme de plus de 15 minutes avant minuit qui a une cover
   */
  _findPT2WithPT1 () {
    if (!this.pt1) {
      throw new Error('You must find PT1 first')
    }
    if (this.pt1.diffusionDate + this.pt1.duration > this._getFuturTimestamp(22, 29)) {
      this.pt2 = this.pt1
    } else if (this.pt1.diffusionDate + this.pt1.duration < this._getFuturTimestamp(22, 30)) {
      this.pt2 = this.programs.find(program => program.duration >= 900 && (this._isAfterPT1(program) && this._isBeforeMidnight(program)) && program.covers.length > 0)
    }
  }

  /**
   * Le programme commence il après PT1 ?
   * @param {Object} program
   */
  _isAfterPT1 (program) {
    if (!this.pt1) {
      throw new Error('You must find PT1 first')
    }
    return program.diffusionDate > this.pt1.diffusionDate + this.pt1.duration
  }

  /**
   * Le programme commence il avant minuit ?
   * @param {Object} program
   */
  _isBeforeMidnight (program) {
    return program.diffusionDate < this._getFuturTimestamp(24)
  }

  /**
   * Retourne le programme avec le dayPart correspondant, sinon undefined
   * @param {String} dayPart "OTHER" or "PT1" or "PT2" or "PT3"
   */
  _getPrimeTime (dayPart = 'PT1') {
    return this.programs.find(program => program.dayPart === dayPart)
  }

  /**
   * Si pas de PT1 ni PT2 alors on prend
   * Pour PT1, le premier programme démarrant entre 20h et 22h de plus de 15 minutes qui a une cover
   * Pour PT2, le premier programme démarrant entre 22h et 23h de plus de 15 minutes qui a une cover
   */
  _findEligibleParts () {
    this.pt1 = this.programs.find(program => this._isTimestampBetweenHours(20, 22, program.diffusionDate) && program.duration > 900 && program.covers.length > 0)
    this.pt2 = this.programs.find(program => this._isTimestampBetweenHours(22, 23, program.diffusionDate) && program.duration > 900 && program.covers.length > 0)
  }

  /**
   * Retourne si un timestamp est entre deux heures
   * @param {Number} start heure de départ
   * @param {*} stop heure de fin
   * @param {*} date timestamp
   */
  _isTimestampBetweenHours (start, stop, date) {
    return date >= this._getFuturTimestamp(start) && date <= this._getFuturTimestamp(stop)
  }

  /**
   * Retourne le timestamp d'une heure en param
   * @param {Number} hour
   * @param minute
   * @param second
   */
  _getFuturTimestamp (hour, minute = 0, second = 0) {
    return DateTime.local().set({ hour, minute, second, millisecond: 0 }).ts / 1000
  }

  /**
   * Essai de trouver PT1 et PT2 selon algo
   */
  getParts () {
    if (!this.programs) {
      throw new Error('You must call setPrograms first')
    }
    this.pt1 = this._getPrimeTime('PT1')
    this.pt2 = this._getPrimeTime('PT2')

    // On a trouvé PT1 mais pas PT2, on essaye de le determiner
    if (this.pt1 && !this.pt2) {
      this._findPT2WithPT1()
    }
    // Ca n'a rien donné, autre facon de determiner PT1 et PT2
    if (!this.pt1 && !this.pt2) {
      this._findEligibleParts()
    }

    return { PT1: this.pt1, PT2: this.pt2 }
  }
}
