import CONSTANTS from './constants'

/**
 * Remove empty spaces/line breaks from string
 * @param value
 * @returns {*}
 */
export function minify (value) {
  return value.replace(/[\r\n]/gm, '').replace(/ +(?= )/g, '')
}

/**
 * Retrieve env info
 * @returns {{dev: boolean, analyze: boolean}}
 */
export function getEnv () {
  return {
    dev: process.env.NODE_ENV === 'development',
    analyze: process.env.BUILD_TYPE === 'analyze'
  }
}

/**
 * Get option from api response to compare - process with default value and force uppercase
 * @param option
 * @returns {string}
 */
export function getOption (option) {
  return (option || '').toUpperCase()
}

/**
 * Get option and parse it to return a boolean
 * @param option
 * @returns {boolean}
 */
export function getBooleanOption (option = null) {
  return JSON.parse(option)
}

/**
 * Check if this error is 401/41 or 401/42 which is expired token
 * @param error
 * @return {boolean}
 */
export function tokenIsEpired (error) {
  // Ignore errors when no response is available (i.e. request timeout)
  if (!error.response) {
    return false
  }

  return error.response.status === 401 && CONSTANTS.ERRORS.EXPIRED_CODES.includes(error.response.data?.code)
}
