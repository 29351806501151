import { mapGetters } from 'vuex'
import _get from 'lodash/get'
import API from '../api.config'

const ErrorModal = () => import('./errorModal/ErrorModal')
const ErrorTvInitModal = () => import('./errorTvInitModal/ErrorTvInitModal')
const Authentication = () => import('./authentication/Authentication')
const StbOnly = () => import('./stbOnly/StbOnly')
const OutOfEu = () => import('./outOfEu/OutOfEu')
const FreeAccessChannel = () => import('./freeAccessChannel/FreeAccessChannel')
const EpgRecording = () => import('./editRecording/epgRecording/EpgRecording')
const TimeRecording = () => import('./editRecording/timeRecording/TimeRecording')
const QuotaExceeded = () => import('./quotaExceeded/QuotaExceeded')
const DeleteRecording = () => import('./deleteRecording/DeleteRecording')
const DeleteRecordingInvitation = () => import('./deleteRecordingInvitation/DeleteRecordingInvitation')
const Suspended = () => import('./suspended/Suspended')

export default {
  computed: {
    ...mapGetters({
      lastError: 'errors/getLastError',
      showModal: 'config/getShowModal',
      firstModalToOpen: 'config/getFirstModalToOpen'
    })
  },
  data () {
    return {
      activeModal: null,
      activeModalProps: null,
      lastErrorWatcher: null,
      firstModalToOpenWatcher: null
    }
  },
  components: {
    ErrorModal,
    ErrorTvInitModal,
    Authentication,
    StbOnly,
    OutOfEu,
    FreeAccessChannel,
    EpgRecording,
    TimeRecording,
    QuotaExceeded,
    DeleteRecording,
    DeleteRecordingInvitation,
    Suspended
  },
  mounted () {
    this.$root.$on('open-authentication-modal', props => this.openModal('Authentication', props))
    this.$root.$on('open-out-of-eu-modal', props => this.openModal('OutOfEu', props))
    this.$root.$on('open-stb-modal', props => this.openModal('StbOnly', props))
    this.$root.$on('open-epg-recording-modal', props => this.openModal('EpgRecording', props))
    this.$root.$on('open-time-recording-modal', props => this.openModal('TimeRecording', props))
    this.$root.$on('open-exceeded-quota-modal', props => this.openModal('QuotaExceeded', props))
    this.$root.$on('open-delete-recording-modal', props => this.openModal('DeleteRecording', props))
    this.$root.$on('open-delete-recording-invitation-modal', props => this.openModal('DeleteRecordingInvitation', props))
    this.$root.$on('open-tv-init-error-modal', props => this.openModal('ErrorTvInitModal'))
    this.$root.$on('open-suspended-modal', props => this.openModal('Suspended', props))
    this.$root.$on('open-free-access-channel-modal', props => this.openModal('FreeAccessChannel', props))

    this.lastErrorWatcher = this.$store.watch(() => this.lastError, this.onError)

    if (this.firstModalToOpen) {
      this.onFirstModalToOpen()
    }

    this.firstModalToOpenWatcher = this.$store.watch(() => this.firstModalToOpen, this.onFirstModalToOpen)
  },
  beforeDestroy () {
    this.$root.$off()

    this.lastErrorWatcher()
    this.firstModalToOpenWatcher()
  },
  methods: {
    /**
     * Open modal - load dynamically
     * @param componentName
     * @param props
     */
    openModal (componentName, props = null) {
      this.$store.commit('config/addModalToOpen', { props, componentName })
    },
    /**
     * Closing modal (will set v-if to false)
     */
    closeModal () {
      this.$store.commit('config/closeModal')
      const focusableEls = document.body.querySelectorAll('button, [href], input, a, selector, [tabindex="0"]')
      const firstFocusable = document.querySelector('#first-focusable') || focusableEls[0]
      firstFocusable.focus()
    },
    /**
     * Called when first modal to open changes and open modal if any
     */
    onFirstModalToOpen () {
      if (!!this.firstModalToOpen && !this.showModal) {
        this.activeModalProps = this.firstModalToOpen.props
        this.activeModal = this.firstModalToOpen.componentName
        this.$store.commit('config/showModal')
      }
    },
    /**
     * Open modal when error occured / throw error page if 404
     */
    onError () {
      const errorCode = Object.keys(API.ERRORS_CODES)
        .find(code => API.ERRORS_CODES[code]
          .includes(_get(this.lastError, 'errorCode'))
        ) || 'GENERIC'
      switch (errorCode) {
        case 'NOT_AVAILABLE':
          this.$nuxt.error({ statusCode: 404 })
          break

        case 'NOT_AUTHENTIFIED':
          this.openModal('Authentication')
          break

        default:
          if (this.lastError && this.lastError.type === 'API') {
            this.openModal('ErrorModal', { code: errorCode, error: this.lastError })
          }
      }
    }
  }
}
