import { DateTime } from 'luxon'

const timezones = [
  {
    value: '00h-05h',
    min: 0,
    max: 5
  },
  {
    value: '05h-08h',
    min: 5,
    max: 8
  },
  {
    value: '08h-12h',
    min: 8,
    max: 12
  },
  {
    value: '12h-16h',
    min: 12,
    max: 16
  },
  {
    value: '16h-20h',
    min: 16,
    max: 20
  },
  {
    value: '20h-00h',
    min: 20,
    max: 24
  }
]

const dates = {
  DEFAULT_DAYS_BEFORE: 7,
  DEFAULT_DAYS_AFTER: 7
}

// --------------------------------------------------------------------------

/**
 * Get Timezone - pc/server offset to get correct timezone
 * @returns {*}
 * @param timeOffset
 */
function getTimezone (timeOffset) {
  const now = new Date(Date.now() - timeOffset)
  return this.timezones.find(timezone => now.getHours() >= timezone.min && now.getHours() < timezone.max)
}

/**
 *
 * @param timezone - the selected timezone format: { min: 20, max: 24, value: "20h-24h"}
 * @param dateOffset - the days offset between today and the selected date (ex: yesterday -> -1, tommorrow -> 1)
 * @returns {string}
 *
 * We need two timestamp formated for UNIX : min and max (8h-12h of tomorrow for example)
 * The Math.trunc remove what's after the coma (15484574884000,4787 --> 15484574884000)
 */
function getPeriod (timezone, dateOffset = 0) {
  let filterMinTimestamp = Math.trunc((_getFilterTimestamp(timezone.min) / 1000))
  filterMinTimestamp += (dateOffset * 86400) /* 86400 = 1 day convert in timestamp */
  const filterMaxTimestamp = Math.trunc(filterMinTimestamp + ((timezone.max - timezone.min) * 3600))

  return `${filterMinTimestamp * 1000},${filterMaxTimestamp * 1000}`
}

/**
 * Return the evening start time for period parameter
 * @returns {string}
 * @private
 */
function getEveningStartTime () {
  const dateTonightStart = new Date()
  dateTonightStart.setHours(20)
  dateTonightStart.setMinutes(0)
  dateTonightStart.setSeconds(0)
  const result = dateTonightStart.getTime().toString()
  // On remplace les 3 derniers caracteres par des 0 pour tvm ...
  return result.slice(0, result.length - 3).concat('000')
}

/**
 * Return the evening end time for period parameter
 * @returns {string}
 * @private
 */
function getEveningEndTime () {
  const dateTonightEnd = new Date()
  dateTonightEnd.setDate(dateTonightEnd.getDate() + 1)
  dateTonightEnd.setHours(1)
  dateTonightEnd.setMinutes(0)
  dateTonightEnd.setSeconds(0)
  const result = dateTonightEnd.getTime().toString()
  // On remplace les 3 derniers caracteres par des 0 pour tvm ...
  return result.slice(0, result.length - 3).concat('000')
}

function isPrimetimeOrCurrentPeriod (period, offset) {
  const primetimeZone = this.timezones.find(timezone => timezone.value === '20h-00h')
  const primetimePeriod = getPeriod(primetimeZone)
  const currentPeriod = getPeriod(this.getTimezone(offset))
  return period === primetimePeriod || period === currentPeriod
}

// --------------------------------------------------------------------------

function _getFilterTimestamp (hour, minute = 0, second = 0) {
  return DateTime
    .fromObject({ hour, minute, second })
    .toMillis()
}

// --------------------------------------------------------------------------

export default {
  timezones,
  dates,
  getTimezone,
  getPeriod,
  getEveningStartTime,
  getEveningEndTime,
  isPrimetimeOrCurrentPeriod
}
