export const SHOW_BACK_TYPE = {
  SHOW_BACK: 'SHOW_BACK',
  SHOW_BACK_LAYOUT: 'SHOW_BACK_LAYOUT',
  HIDE_BACK: 'HIDE_BACK'
}

export const CONFIG = {
  defaultRoute: 'HomePage',
  HomePage: {
    title: `general.title`,
    pageTitle: 'general.menu.home',
    header: {
      showBack: SHOW_BACK_TYPE.HIDE_BACK,
      showTitle: true
    }
  },
  Evening: {
    title: 'general.title',
    pageTitle: 'general.menu.evening',
    header: {
      showBack: SHOW_BACK_TYPE.HIDE_BACK,
      showTitle: true
    }
  },
  Programs: {
    title: 'general.title',
    pageTitle: 'general.menu.all',
    header: {
      showBack: SHOW_BACK_TYPE.HIDE_BACK,
      showTitle: true
    }
  },
  Fip: {
    title: `fip.title`,
    header: {
      showBack: SHOW_BACK_TYPE.SHOW_BACK,
      showTitle: false
    }
  },
  AvailableRecordings: {
    title: `general.menu.recordings`,
    pageTitle: 'general.menuNpvr.available',
    header: {
      showBack: SHOW_BACK_TYPE.SHOW_BACK_LAYOUT,
      showTitle: true,
      npvr: true
    }
  },
  ScheduledRecordings: {
    title: `general.menu.recordings`,
    pageTitle: 'general.menuNpvr.scheduled',
    header: {
      showBack: SHOW_BACK_TYPE.SHOW_BACK_LAYOUT,
      showTitle: true,
      npvr: true
    }
  },
  FipRecording: {
    title: `fip.title`,
    header: {
      showBack: SHOW_BACK_TYPE.SHOW_BACK,
      showTitle: false,
      npvr: true
    },
    fallbackRoute: 'AvailableRecordings'
  }
}
