export default {
  STORAGE_WARNING_THRESHOLD: 90, // Percentage
  STORAGE_CRITICAL_THRESHOLD: 100, // Percentage
  MAX_SUBSCRIBED_OPTION: 300,
  MAX_PACKAGE_STORAGE: 300, // Max storage
  NPVR_OPTIONS: ['OA_nPVR_100', 'OA_nPVR_300'],
  RECORDING_STATE: {
    RECORDED: 'RECORDED',
    IN_RECORDING: 'IN_RECORDING',
    FUTURE_RECORDING: 'FUTURE_RECORDING'
  },
  RECORDING_TYPES: {
    TIMEBASED: 'TimeBased',
    EPGBASED: 'EpgBased'
  },
  RECORDING_ACTIONS: {
    CREATE: 'CREATE',
    EDIT: 'EDIT'
  },
  VALID_RECORDINGS: [
    'RECORDED',
    'IN_RECORDING',
    'FUTURE_RECORDING'
  ],
  CSA_CODES: {
    TP: 1,
    10: 2,
    12: 3,
    16: 4,
    18: 5
  }
}
