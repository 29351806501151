import Vue from 'vue'
import { statsPlugin } from '@wptv/stats'
import statsUsage from './usage'

export default ({ app }, inject) => {
  // Add plugin, including tealium directive/mixin and view hook
  if (process.client) {
    Vue.use(statsPlugin, { app })
  }

  // Inject helper $stats for all stats usage
  inject('stats', statsUsage)
}
