import _get from 'lodash/get'
import logger from '@wptv/logger'
import axios from 'axios'

export default {
  /**
   * Get Certificate
   * @param isAppleOS
   * @returns {Promise<T | never>}
   * @private
   */
  getCertificate (isAppleOS) {
    const certificateType = isAppleOS ? 'fairplayCertificateUrl' : 'widevineCertificateUrl'
    const path = _get(this.$config, `confetti.public.playerResources[${certificateType}]`)

    return axios.get(path, { responseType: 'arraybuffer' })
      .then(response => Promise.resolve(response.data))
      .catch((error) => {
        logger.error('Error::api.certificat.getCertificate():', error.message)
        logger.debug(error)
        return Promise.reject({
          error: {
            data: _get(error, 'response.data'),
            path
          },
          message: error.message
        })
      })
  }
}
