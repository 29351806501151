export default {
  MOSAIC_NB_VISIBLE: 12,
  DEFAULT_COVER_FORMAT: 'PHOTO_169',
  DEFAULT_COVER_PATH: 'pathWebtv',
  DEFAULT_CHANNEL_LOGO_TYPE: 'webTVLogo',
  SQUARE_CHANNEL_LOGO_TYPE: 'webTVSquare',
  DEVICES_CATEGORIES: {
    AIRPLAY: 'W_AIRPLAY',
    CHROMECAST: 'W_CHROMECAST',
    LACLETV: 'W_LACLETV',
    LACLETV2: 'W_LACLETV2',
    LEBLOC: 'W_LEBLOC',
    PC: 'W_PC',
    XBOX: 'W_XBOX',
    ANDROID: 'M_ANDROID',
    IOS: 'M_IOS',
    WP10: 'M_WP10',
    WP8: 'M_WP8',
    TABLET: {
      ANDROID: 'M_TABLET_ANDROID',
      IOS: 'M_TABLET_IOS',
      WP10: 'M_TABLET_WP10',
      WP8: 'M_TABLET_WP8',
      W8: 'W_TABLET_W8'
    },
    STB: 'S_STB'
  },
  LANGUAGES: {
    VM: 'VM',
    VF: 'VF',
    VOST: 'VOST'
  },
  PAGES: {
    FIP: 'FIP',
    MAINTENANT: 'MAINTENANT',
    CE_SOIR: 'CE_SOIR',
    PROG_TV: 'PROG_TV',
    NPVR: 'NPVR'
  },
  DEFAULT_QUALITY: 85,
  OLD_API_URL: 'p/',
  REST_API_URL: 'images/',
  FORMATS: {
    FIP: {
      RATIO_16_9: [],
      PHOTO_169: [],
      RATIO_4_3: [
        {
          media: '(max-width:320px)',
          format: '275x200'
        },
        {
          media: '(max-width:736px)',
          format: '720x'
        }
      ]
    },
    CE_SOIR: {
      RATIO_16_9: [
        {
          media: '(max-width:736px)',
          format: '244x'
        },
        {
          media: '(max-width:960px)',
          format: '136x'
        },
        {
          media: '(max-width:1200px)',
          format: '190x'
        },
        {
          media: '(min-width:1201px)',
          format: '210x'
        }
      ]
    },
    MAINTENANT: {
      RATIO_16_9: [
        {
          media: '(max-width:736px)',
          format: '240x'
        },
        {
          media: '(max-width:960px)',
          format: '276x'
        },
        {
          media: '(max-width:1200px)',
          format: '272x'
        },
        {
          media: '(min-width:1201px)',
          format: '310x'
        }
      ]

    },
    PROG_TV: {
      RATIO_16_9: [
        {
          media: '(max-width:736px)',
          format: '120x'
        },
        {
          media: '(max-width:960px)',
          format: '192x'
        },
        {
          media: '(max-width:1200px)',
          format: '150x'
        },
        {
          media: '(min-width:1201px)',
          format: '192x'
        }
      ]
    },
    NPVR: {
      PHOTO_169: [
        {
          media: '(max-width:736px)',
          format: '244x'
        },
        {
          media: '(max-width:960px)',
          format: '280x'
        },
        {
          media: '(max-width:1200px)',
          format: '256x'
        },
        {
          media: '(min-width:1201px)',
          format: '310x'
        }
      ]
    }
  },
  MAG_TV: {
    GENRES: {
      ASTUCES: 'Astuces',
      CINE_SERIES: 'Ciné Séries',
      EN_BREF: 'En Bref',
      FAMILLE: 'Famille',
      JEUX_CONCOURS: 'Jeux Concours',
      PASS_VIDEO: 'Pass Vidéo',
      SPORTS: 'Sports',
      VOD: 'VOD'
    },
    ITEMS_NUMBER: 2,
    AUTOPROMO_NUMBER: 1,
    DEFAULT_SORT_ORDER: 'desc'
  },
  SUSPENDED_CODES: {
    NA: 'NA',
    SUSPENDED: 'suspended',
    SUSPENDED_GUEST: 'suspended_guest',
    INVALID: 'homecheckstatus_invalid',
    NOT_FOUND: 'option_not_found ',
    NOT_EXISTING: 'not_existing'
  }
}
