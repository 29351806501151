import { formatedGenre } from '../../components/shared/utils/formatGenre.utils'

export default {
  /**
   * Set Genres
   * @param state
   * @param genres
   */
  setGenres (state, genres) {
    state.genres = genres
  },
  /**
   * Set FormatedGenres
   * @param state
   * @param genres
   */
  setFormatedGenres (state, genres) {
    state.formatedGenres = genres.map((e) => {
      // replacement of accents and whitespaces
      const decoratedGenre = formatedGenre(e)
      const tempObj = {}
      tempObj[`${decoratedGenre}`] = e
      return tempObj
      // reduce to have only one object instead of a list of objects
      // easier to get the value corresponding of a key
    }).reduce((previous, current) => {
      return Object.assign(current, previous)
    })
  },
  /**
   * set filters
   * @param {*} state
   * @param {*} item
   */
  setSelectedFilters (state, filters) {
    state.selectedFilters = filters
  },
  /**
   * add a filter to selectedFilters
   * @param {*} state
   * @param {*} item
   */
  addFilter (state, item) {
    if (!state.selectedFilters.includes(item)) {
      state.selectedFilters.push(item)
    }
  },
  /**
   * remove a filter from selectedFilters
   * @param {*} state
   * @param {*} item
   */
  removeFilter (state, item) {
    state.selectedFilters = state.selectedFilters.filter(filter => filter !== item)
  },
  /**
   * reset all filters
   * @param {*} state
   */
  resetFilters (state) {
    state.selectedFilters = []
  }
}
