export default {
  /**
   * get CertificateSafari
   * @param state
   * @returns {string}
   */
  getCertificateSafari (state) {
    return state.certificateSafari
  },
  /**
   * get CertificateChrome
   * @param state
   * @returns {string}
   */
  getCertificateChrome (state) {
    return state.certificateChrome
  }
}
