import _get from 'lodash/get'
import fingerprint from '@wptv/fingerprint'

export default {
  /**
   * Get Id To Focus
   * @param state
   * @returns {*}
   */
  getIdToFocus (state) {
    return state.idToFocus
  },
  /**
   * Get User
   * @param state
   * @returns {*}
   */
  getUser (state) {
    return state.user
  },
  /**
   * Get Default Application
   * @param state
   */
  getApplication (state) {
    return _get(state, 'user.application')
  },
  /**
   * get MCO
   * return OFR in case of null
   * @param state
   * @returns {string}
   */
  getMCO (state) {
    return _get(state, 'user.mco') || 'OFR'
  },
  /**
   * Check if Sosh
   * @param state
   */
  isSosh (state) {
    return _get(state, 'user.sosh', false)
  },
  /**
   * Get Time Offset
   * @param state
   */
  getTimeOffset (state) {
    return _get(state, 'timeOffset', 0)
  },
  /**
   * Get unique ID (using fingerprint lib - same as HASPlayer getTerminalId())
   * return a fake terminalId if no householdId (not auth)
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @returns {*}
   */
  getUniqueId (state, getters, rootState, rootGetters) {
    const browser = fingerprint.browser()
    const os = fingerprint.os()
    const householdId = _get(state.user, 'householdId')
    const suffixFP = rootState.useragent.userAgent.os.name.includes('Mac') &&
                     rootState.useragent.userAgent.ua.includes('Safari') &&
                     !rootState.useragent.userAgent.ua.includes('Chrome')
      ? '_FP'
      : ''

    const browserName = rootGetters['useragent/getOsName'] === 'Tizen' ? 'Chrome' : browser.name

    const terminalId = householdId
      ? [
          os.name.replace(/[()]/g, ''),
          os.bits,
          browserName,
          householdId
        ].join('-')
      : ''
    return (terminalId + suffixFP).replace(' ', '')
  }
}
