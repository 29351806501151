import _filter from 'lodash/filter'
import _reject from 'lodash/reject'
import _intersection from 'lodash/intersection'
import _get from 'lodash/get'
import pipe from './pipe.utils'

const channelIdMecStorageKey = 'live/channelIdMec'

/**
 * Set Acknowledge FAC Checked
 * @param channelId
 * @param checked
 */
function setAcknowledgeFACChecked (id, checked) {
  const channelIdsMec = _getChannelIdsMecInStorage()

  if (checked) {
    channelIdsMec.push(id)
  } else {
    channelIdsMec.splice(channelIdsMec.indexOf(id))
  }

  return window.localStorage.setItem(channelIdMecStorageKey, JSON.stringify(channelIdsMec))
}

/**
 * Retrieve checked channels
 * @param user
 * @param freeAccessChannels
 * @returns {*}
 */
function getFreeAccessChannels (user, freeAccessChannels) {
  const userPackages = _get(user, 'rights.packages', [])
  return pipe(
    _filterCurrentFreeAccessChannels,
    filteredCurrentFAC => _rejectUserPackages(filteredCurrentFAC, userPackages),
    _rejectBouquetsChecked
  )(freeAccessChannels, _get(user, 'timeOffset', 0))
}

// -------------------------------------------------------------------------------------

function _filterCurrentFreeAccessChannels (freeAccessChannels, userTimeOffset) {
  return _filter(freeAccessChannels, (FAC) => {
    // return true if programs begin before now
    return FAC.begin <= Date.now() - userTimeOffset && FAC.end >= Date.now() - userTimeOffset
  })
}

function _rejectUserPackages (freeAccessChannels, userPackages) {
  return _reject(freeAccessChannels, (FAC) => {
    // return true if intersection between FAC bouquets and user bouquets is not null
    return _intersection(FAC.packs, userPackages).length > 0
  })
}
function _rejectBouquetsChecked (freeAccessChannels) {
  return _filter(freeAccessChannels, (freeAccessChannel) => {
    return !_getChannelIdsMecInStorage().includes(freeAccessChannel.id)
  })
}

function _getChannelIdsMecInStorage () {
  const localStorageValue = window.localStorage.getItem(channelIdMecStorageKey)
  return localStorageValue ? JSON.parse(localStorageValue) : []
}

// -------------------------------------------------------------------------------------

export default {
  getFreeAccessChannels,
  setAcknowledgeFACChecked
}
