import { DateTime } from 'luxon'
import capitalizeFLetter from '../capitalizeFLetter/capitalizeFLetter.filter'

/**
 *
 * @param {*} timestamp // (in seconds)
 * @param {*} tomorrowPrefix
 */
export default function (timestamp, tomorrowPrefix = null) {
  const dt = DateTime.fromMillis(timestamp * 1000, { locale: 'fr' })

  // Special case for tomorrow prefix (see WEBTV-5017)
  if (tomorrowPrefix) {
    const currentDt = DateTime.local().plus({ days: 1 })

    if (currentDt.toISODate() === dt.toISODate()) {
      const hour = capitalizeFLetter(dt.toLocaleString({
        hour: 'numeric',
        minute: '2-digit'
      }).replace(':', 'H'))

      return `${tomorrowPrefix} ${hour}`
    }
  }

  return capitalizeFLetter(dt.toLocaleString({
    month: 'long',
    weekday: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  }).replace(':', 'H'))
}
