import _get from 'lodash/get'
import formatTitleUrl from '../../../plugins/filters/formatTitleUrl/formatTitleUrl'

function onClickShowMoreEvening () {
  return {
    track_page: 'ce_soir',
    track_zone: 'mosaique',
    track_type: 'clic',
    track_nom: 'voir_plus'
  }
}

function onClickChannelVisual (hasAccess, channel, $config, program, notSubscribed, isStreaming) {
  return {
    track_page: 'ce_soir',
    track_zone: 'mosaique',
    track_nom: _statsVisuelChaine(notSubscribed, program, hasAccess, isStreaming),
    track_cible: hasAccess ? _getProgramStats(channel, program) : _getChannelStats(channel, $config)
  }
}

function onClickChannelTitleEvening (channel, program) {
  return {
    track_page: 'ce_soir',
    track_zone: 'mosaique',
    track_nom: 'titre_chaine',
    track_cible: _getProgramStats(channel, program)
  }
}

// -------------------------------------------------------

/**
 *
 * @param channel
 * @param $config
 * @returns {string}
 * @private
 */
function _getChannelStats (channel, $config) {
  return channel ? `${channel.id}-${channel.name}-${$config.confetti.public.authUrl}` : ''
}
/**
 * Get program stats
 * @returns {string}
 */
function _getProgramStats (channel, program) {
  const formattedTitle = formatTitleUrl(_get(program, 'title', null)) || ''
  return program && channel ? `${channel.id}-${channel.name}-/programmes/${program.id}/${formattedTitle}` : ''
}

/**
 * Retrieve correct stats based on various cases
 * @returns {string}
 */
function _statsVisuelChaine (notSubscribed, program, hasAccess, isStreaming) {
  const stat = 'visuel_chaine'
  const channelStatus = isStreaming ? 'en_cours' : 'offline'
  const userChannelStatus = notSubscribed ? 'non_souscrite' : 'souscrite'

  return `${stat}-${channelStatus}-${hasAccess ? userChannelStatus : 'non_authent'}`
}

export default {
  onClickShowMoreEvening,
  onClickChannelTitleEvening,
  onClickChannelVisual
}
