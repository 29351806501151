export default {
  boutiqueGenerique: 'Cette chaine ne fait pas partie de votre offre.<br>Pour faire évoluer votre offre TV, rendez-vous sur la boutique en ligne',
  notClientOrange: `Vous devez être identifié avec votre compte client Internet Orange ou Sosh, et disposer d'une offre TV pour accéder à la TV d'Orange sur votre ordinateur.`,
  authLabel: `S'identifier`,
  record: {
    tooltip: {
      begin: 'Enregistrer',
      stop: `Arrêter l'enregistrement`,
      loading: 'Chargement...'
    },
    snackbar: {
      begin: `L'enregistrement de votre programme débute immédiatement et se terminera automatiquement à {time}.`,
      stop: `Arrêt pris en compte. L'enregistrement s'arrêtera à {time}.`,
      warning: `Action impossible. Vous pouvez supprimer l'enregistrement depuis la page « Mes enregistrements »`
    }
  },
  incompatibleConfiguration: {
    title: `Problème de configuration`,
    content: `La configuration de votre navigateur ne permet pas de visionner vos chaînes TV.`,
    button: {
      label: `Configurations compatibles`,
      aria: `Ouvrir l'aide sur les configurations compatibles`
    }
  },
  streamIssue: {
    title: `Une erreur est survenue`,
    content: `Merci d'essayer à nouveau. Si le problème persiste, veuillez contacter le support via le lien "Contact" du menu Aide.`,
    button: {
      label: `Relancer la lecture`,
      aria: `Relancer la lecture`
    }
  },
  playerRestricted: {
    2382: {
      title: `Droit de lecture simultanée limité`,
      content: `Cette chaîne est actuellement visionnée sur vos autres équipements et le maximum autorisé est atteint. Veuillez d'abord arrêter un des visionnages avant d'en lancer un nouveau.`
    },
    2383: {
      title: `Nombre maximum de terminaux atteint`,
      content: `Vous avez atteint le nombre maximal d’équipements enregistrés. Vous ne pourrez pas regarder cette chaîne sur ce navigateur internet.`
    },
    button: {
      label: `Relancer la lecture`,
      aria: `Relancer la lecture`
    }
  },
  mediaRestricted: {
    title: `Une erreur est survenue`,
    content: `Votre configuration ne permet pas de lire certaines chaînes protégées. Nous vous invitons à changer de navigateur ou à déconnecter vos écrans externes.`,
    button: {
      label: `Relancer la lecture`,
      aria: `Relancer la lecture`
    }
  },

  others: {
    title: `Une erreur est survenue`,
    content: `Nous vous conseillons d'actualiser la page. Si le problème persiste, veuillez contacter le support via le lien "Contact" du menu Aide.`,
    button: {
      label: `Recharger la page`,
      aria: `Recharger la page`
    }
  },
  default: {
    title: `Une erreur est survenue`,
    content: `Merci d'essayer à nouveau. Si le problème persiste, veuillez contacter le support via le lien "Contact" du menu Aide.`,
    button: {
      label: `Relancer la lecture`,
      aria: `Relancer la lecture`
    }
  }
}
