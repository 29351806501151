import _get from 'lodash/get'
import API_CONFIG from '../../../components/shared/api.config'

/**
 * Generate a axios error object
 * @param {Object} axiosError - axios error info
 * @param {pageName: String, fullPath: String} pageInfo - page info
 * @returns {Object} - a formated error
 *            {status: Number,
 *            statusText: String,
 *            url: String,
 *            method: ['get'|'post'],
 *            tvmCode: String,
 *            code: String,
 *            type: String,
 *            origin: String,
 *            tvmMessage: String,
 *            tvmDescription: String,
 *            pageName: String,
 *            fullPath: String,
 *            contentType: String}}
 */
export function formatAxiosError (axiosError, { pageName = '', fullPath = '', nbInSession, portalVersion = '' }) {
  return {
    nbInSession,
    status: _get(axiosError, 'error.status', -1),
    statusText: _get(axiosError, 'error.statusText', '-'),
    url: _get(axiosError, 'config.url', 'no url'),
    method: _get(axiosError, 'config.method', 'no method'),
    tvmCode: String(_get(axiosError, 'error.data.code', '-')),
    errorCode: String(_get(axiosError, 'error.data.code', '-')),
    channelId: _get(axiosError, 'channelId', 'no channel id'),
    channelName: _get(axiosError, 'channelName', 'no channel name'),
    contentId: _get(axiosError, 'contentId', 'no content id'),
    contentTitle: _get(axiosError, 'contentTitle', 'no content title'),
    errorMessage: _get(axiosError, 'message', 'no error message'),
    type: 'API',
    origin: API_CONFIG.ORIGIN,
    tvmMessage: _get(axiosError, 'error.data.message', 'no tvm message'),
    tvmDescription: _get(axiosError, 'error.data.description', 'Problème réseau'),
    pageName,
    fullPath,
    portalVersion,
    contentType: pageName
  }
}

/**
 *
 * @param gatewayError
 * @param pageName
 * @param fullPath
 * @param nbInSession
 * @param portalVersion
 * @returns {{fullPath: string, method, origin: string, contentId, errorCode: string, contentTitle, type: string, nbInSession, pageName: string, url, gatewayCode: string, gatewayDescription, programName, statusText, channelName, gatewayMessage, contentType: string, channelId, status, portalVersion: string}}
 */
export function formatGatewayError (gatewayError, { pageName = '', fullPath = '', nbInSession, portalVersion = '' }) {
  return {
    nbInSession,
    status: _get(gatewayError, 'error.status', 600),
    statusText: _get(gatewayError, 'error.statusText', 'no status'),
    url: _get(gatewayError, 'config.url', 'no url'),
    method: _get(gatewayError, 'config.method', 'no method'),
    gatewayCode: String(_get(gatewayError, 'error.data.code', -1)),
    errorCode: String(_get(gatewayError, 'error.data.code', -1)),
    channelId: _get(gatewayError, 'channelId', 'no channel id'),
    channelName: _get(gatewayError, 'channelName', 'no channel name'),
    programName: _get(gatewayError, 'programName', 'no program name'),
    contentId: _get(gatewayError, 'contentId', 'no content id'),
    contentTitle: _get(gatewayError, 'contentTitle', 'no content title'),
    type: 'API',
    origin: API_CONFIG.ORIGIN,
    gatewayMessage: _get(gatewayError, 'error.data.message', 'no tvm message'),
    gatewayDescription: _get(gatewayError, 'error.data.description', 'Un problème réseau est apparu.'),
    pageName,
    fullPath,
    portalVersion,
    contentType: pageName
  }
}

/**
 * Format raw player error into readable error for tvm
 * @param playerError
 * @param pageName
 * @param fullPath
 * @param nbInSession
 * @param portalVersion
 * @returns {{fullPath: string, origin: string, errorCode, type: string, nbInSession, pageName: string, extMessage, status: string, portalVersion: string}}
 */
export function formatPlayerError (playerError, { pageName = '', fullPath = '', nbInSession, portalVersion = '' }) {
  // Add 'WARNING:' prefix to the error codes 20 and 21 when they are marked as warning (WEBTV-4484)
  return {
    channelId: _get(playerError, 'channelId', 'no channel id'),
    nbInSession,
    errorCode: playerError.isWarning ? `WARNING:${_get(playerError, 'message', 'no error code found')}` : _get(playerError, 'message', 'no error code found'),
    data: JSON.stringify(_get(playerError, 'data', 'no data')),
    extMessage: _get(playerError, 'extMessage', 'no ext Message found'),
    status: String(_get(playerError, 'data.status', _get(playerError, 'status', 'no status found'))),
    type: 'PLAYER',
    origin: API_CONFIG.ORIGIN,
    pageName,
    fullPath,
    portalVersion
  }
}

/**
 * Format raw generic error into readable error for tvm
 * @param genericError
 * @param pageName
 * @param fullPath
 * @param nbInSession
 * @param portalVersion
 * @returns {*&{fullPath: string, origin: string, type: string, nbInSession, pageName: string, contentType: string, portalVersion: string}}
 */
export function formatGenericError (genericError, { pageName = '', fullPath = '', nbInSession, portalVersion = '' }) {
  return {
    ...genericError,
    nbInSession,
    type: 'GENERIC',
    origin: API_CONFIG.ORIGIN,
    pageName,
    fullPath,
    portalVersion,
    contentType: pageName
  }
}

/**
 *
 * @param error
 * @returns {{}}
 */
export function formatTokenError (error) {
  const isTimeOut = error.code === API_CONFIG.ERROR_TOKEN.ECONNABORTED
  const code = isTimeOut ? API_CONFIG.ERROR_TOKEN.TOKEN_TIMEOUT : error.message?.split(' ').pop() // last item in message is code and error.code is undefined

  return {
    name: error.name || '',
    message: error.message || '',
    origin: API_CONFIG.ORIGIN,
    errorCode: `${API_CONFIG.ERROR_TOKEN.PREFIX}${code}`
  }
}
