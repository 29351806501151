<template>
  <div
    class="ads show-from-large"
    aria-hidden="true"
    tabindex="-1">
    <div class="ads-box">
      <div
        :id="pageId"
        v-tealium:click="$stats.ads.onClickAds()"
        :class="['ads-content', { 'label-top': labelPosition === 'top'}]" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _get from 'lodash/get'

export default {
  name: 'Ads',
  props: {
    labelPosition: {
      type: String,
      default: 'right'
    }
  },
  computed: {
    ...mapGetters({ isCompatibleMobile: 'useragent/isCompatibleMobile' }),
    pageId () {
      return this.isCompatibleMobile ? _get(this.$config, 'confetti.public.ads.mobileAdsPageId') : _get(this.$config, 'confetti.public.ads.desktopAdsPageId')
    }
  },
  mounted () {
    if (window.oan_displayAd && window.oan_refresh_slot && window.googletag && window.googletag.pubads) {
      window.oan_displayAd(this.pageId)
      window.oan_refresh_slot()
    }
  }
}
</script>

<style lang="scss" scoped>
.ads-box {
  width: 370px;
  margin-left: 30px;
  padding: 22px 35px;
  background: $bglightgrey;
}
.ads-content {
  position: relative;

  &:before {
    content: "Publicit\E9";
    font-size: .75rem;
    line-height: 10px;
    color: $textgrey;
    position: absolute;
    text-transform: uppercase;
    transform: rotate(-90deg);
    letter-spacing: 0.03rem;
    top: 20px;
    right: -39px;
  }

  &.label-top {
    &:before {
      transform: none;
      top: -14px;
      right: 0;
    }
  }
}
</style>
