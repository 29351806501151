import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import formatTitleUrl from '../../../plugins/filters/formatTitleUrl/formatTitleUrl'
import API_CONSTANT from '../../../api.constants'

/**
 * Apply SEO (with item title) to to image name in url
 * note: hopefully improve in the future when image apis are merged.
 * @param itemTitle
 * @param imageUrl
 * @returns {string}
 * @private
 */
function _getImageUrlWithSeo (itemTitle, imageUrl) {
  try {
    const seoPart = formatTitleUrl(itemTitle)
    let filename = imageUrl.split('%2F').pop() // get the last part of image url (after the last %2F) wich is the image filename
    // ---------------- patch 22/08/2022 ---------------------------
    // FDE: patch to remove one to many "/" before image filename that nobody seems to know where it comes from
    // TODO: remove that part when APIs will do their job and fix their sh*t (this is never going to happen... ever)
    filename = filename.replace('/', '')
    // ----------------- End of patch ------------------------------
    const sliceIndex = imageUrl.lastIndexOf('%2F') // get the index of the last %2F occurence
    const urlRoot = imageUrl.slice(0, sliceIndex) // get the urlRoot (all what's before last %2F occurence)
    return `${urlRoot}%2F${seoPart}%7C${filename}` // bring them all, and in the darkness bind them
  } catch {
    return imageUrl
  }
}

/**
 * retrieve image based on item data and by checking item data with a specific order
 * @param item
 * @param favoriteFormat
 * @param availableFormats
 * @private
 */
function _getImage (item, favoriteFormat, availableFormats) {
  const imagesCovers = [
    ..._get(item, 'images', []),
    ..._get(item, 'covers', [])
  ]
  let image = {}

  if (imagesCovers.length > 0) {
    image = imagesCovers[0].type
      ? _findImageGateway('PHOTO_169', imagesCovers, favoriteFormat, availableFormats)
      : _findImageTVM('RATIO_16_9', imagesCovers, favoriteFormat, availableFormats)
  }
  return { ...image }
}

/**
 *
 * @param imageFormat
 * @param imagesCovers
 * @param favoriteFormat
 * @param availableFormats
 * @returns {*|{url: string}}
 * @private
 */
function _findImageTVM (imageFormat, imagesCovers, favoriteFormat, availableFormats = []) {
  const format = [favoriteFormat, availableFormats[0], imageFormat].find(format => imagesCovers.some(item => item.format === format))
  return imagesCovers.find(item => item.format === format) || { url: '' }
}

/**
 *
 * @param imageFormat
 * @param imagesCovers
 * @param favoriteFormat
 * @param availableFormats
 * @returns {*|{url: string}}
 * @private
 */
function _findImageGateway (imageFormat, imagesCovers, favoriteFormat, availableFormats = []) {
  const type = [favoriteFormat, availableFormats[0], imageFormat].find(type => imagesCovers.some(item => item.type === type))
  return imagesCovers.find(item => item.type === type) || { url: '' }
}

/**
 * get image picture to adapt image fetching based on resolution
 * Add webp/quality to improve, add no webp fallback
 * @param image
 * @param isProxyMedia
 * @param page
 * @returns {*}
 * @private
 */
function _getImagePicturesRestApi (image, isProxyMedia, page) {
  const pictures = []
  const imageType = image?.type || 'RATIO_16_9'

  if (API_CONSTANT.FORMATS[page][imageType] !== undefined) {
    if (isProxyMedia && API_CONSTANT.FORMATS[page][imageType].length > 0) {
      API_CONSTANT.FORMATS[page][imageType].forEach(({ media, format }) => {
        const queryParamFormat = format ? `format=${format}&` : ''
        pictures.push({ media, source: `${_getImageSource(image)}?${queryParamFormat}saveas=webp&saveasquality=${API_CONSTANT.DEFAULT_QUALITY}`, type: 'image/webp' })
      })
    }
  }

  return pictures
}

/**
 *
 * @param image
 * @returns {string|*}
 * @private
 */
function _getImageSource (image) {
  return image.pathWebtv || image.url
}

// ------------------------------------------------------------

/**
 * set Item image data
 * @param item
 * @param mosaicFormat
 * @param favoriteFormat
 * @param availableFormats
 * @returns {{image: {}, isProxyMedia: boolean, pictures: {}, ratio: (string)}}
 */
export function setItemImageData (item, { favoriteFormat, availableFormats = [] }, page) {
  const isProxyMedia = !_isEmpty(item.images) || !_isEmpty(item.covers)
  const image = _getImage(item, favoriteFormat, availableFormats)
  const source = image.pathWebtv || image.url
  image.url = source ? _getImageUrlWithSeo(item.title, source) : ''
  const pictures = _getImagePicturesRestApi(image, isProxyMedia, page)
  const ratio = favoriteFormat === 'PHOTO_169' || favoriteFormat === 'RATIO_16_9' ? '16-9' : '3-4'

  return { isProxyMedia, ratio, image, pictures }
}

/**
 * Retrieve logo from GTW Channel
 * @param channel
 * @param definition
 */
export function getLogoFromChannel (channel, definition) {
  const logoWebTV = _get(channel, 'logos', []).find(logo => logo.definitionType === definition)
  return _get(logoWebTV, 'listLogos[0].path', '')
}

/**
 * Recreate logo object to please player api (and Antoine)
 * @param channel
 * @returns {{square: *, default: *}}
 */
export function adaptLogoToVzl (channel) {
  return {
    square: getLogoFromChannel(channel, API_CONSTANT.SQUARE_CHANNEL_LOGO_TYPE),
    default: getLogoFromChannel(channel, API_CONSTANT.DEFAULT_CHANNEL_LOGO_TYPE)
  }
}
