import _get from 'lodash/get'
import user from './user'
import gatewayRecordings from './gateway/recordings'
import tvmUsers from './tvm/users'
import tvmPrograms from './tvm/programs'
import tvmChannels from './tvm/channels'
import tvmGenres from './tvm/genres'
import internalFreeAccessChannels from './internal/freeAccessChannels'
import aboutApi from './internal/about'
import externalAutopromo from './external/autopromo'
import externalCertificate from './external/certificate'
import gatewayChannels from './gateway/channels'
import gatewayPlayHistory from './gateway/playHistory'

/**
 * Factory to create api object for specific module
 * @param $config
 * @param $http
 * @param $httpUtils
 * @param store
 * @returns *
 */
function apiFactory ($config, $http, $httpUtils, store) {
  // Helper to retrieve user application from store and fallback with confetti
  const getDefaultApplication = () => store.getters['user/getApplication'] || _get($config, 'confetti.public.api.tvm.defaultApplication')
  const getSTBApplication = () => store.getters['user/getApplication'] || _get($config, 'confetti.public.api.tvm.STBApplication')

  return module => ({ $config, $http, $httpUtils, getDefaultApplication, getSTBApplication, ...module })
}

/**
 * Create $api
 * $http and $httpUtils are init in @wptv/core
 * @param req
 * @param store
 * @param $config
 * @param $http
 * @param $httpUtils
 * @param inject
 */
export default ({ store, $config, $http, $httpUtils }, inject) => {
  const api = apiFactory($config, $http, $httpUtils, store)

  inject('api', {
    external: {
      autopromo: api(externalAutopromo),
      certificate: api(externalCertificate)
    },
    internal: {
      freeAccessChannels: api(internalFreeAccessChannels),
      about: api(aboutApi)
    },
    gateway: {
      recordings: api(gatewayRecordings),
      channels: api(gatewayChannels),
      playHistory: api(gatewayPlayHistory)
    },
    user: api(user),
    tvm: {
      users: api(tvmUsers),
      programs: api(tvmPrograms),
      channels: api(tvmChannels),
      genres: api(tvmGenres)
    }
  })
}
