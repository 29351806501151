import { getUrlBoutique } from '../../../live/shared/bouquets/bouquets.utils'

export default {
  data () {
    return {
      player: '',
      currentChannel: {}
    }
  },
  mounted () {
    this.$root.$on('setPlayerChannel', this.init)
  },
  methods: {
    init (channel) {
      this.currentChannel = channel
      if (this.player === '') {
        this.player = () => import(/* webpackChunkName: "live-player" */ './playerLive').then(m => m.default || m)
      } else {
        this.play()
      }
    },
    play () {
      if (this.$refs.player) {
        this.$refs.player.play(this.currentChannel.idEPG)
      }
    },
    onOpenShop (editoChannelId) {
      const isSosh = this.$store.getters['user/isSosh']
      const mco = this.$store.getters['user/getMCO']
      const profile = this.$store.getters['core/getProfile']
      const profileOptions = this.$store.getters['core/getInstanceInfo']
      const extInstanceId = profileOptions?.claims?.ext_instance_id
      const url = getUrlBoutique(isSosh, mco, profile.isMobile, extInstanceId, editoChannelId, this.$config.confetti)
      window.open(url, '_blank')
    }
  }
}
