import _get from 'lodash/get'
import urlJoin from 'url-join'
import programsUtils from '../../../../components/live/programs/programs.utils'

export default {
  /**
   * Retrieve a program with a specific ID
   * @param mco
   * @param programId
   * @returns {Q.Promise<never>}
   */
  getOne ({ mco, programId }) {
    const path = urlJoin(
      'applications',
      this.getSTBApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.programs'),
      programId.toString()
    )
    const method = 'api.programs.getOne()'

    const options = {
      params: {
        mco
      },
      cache: {
        exclude: { query: false }
      }
    }

    return this.$http.tvm.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method, bypass: true }))
  },
  /**
   * Retrieve current programs by channels
   * @param mco
   * @param limit
   * @param includeEmptyChannels
   * @returns {Q.Promise<never>}
   */
  getCurrentProgramsByChannels ({ mco, limit, includeEmptyChannels = null }) {
    const path = urlJoin(
      'applications',
      this.getDefaultApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.programs')
    )
    const params = {
      params: {
        groupBy: 'channel',
        period: 'current',
        epgIds: 'all',
        mco,
        limit
      },
      cache: {
        exclude: { query: false }
      }
    }

    if (includeEmptyChannels !== null) {
      params.params.includeEmptyChannels = includeEmptyChannels
    }
    const method = 'api.programs.getByChannels()'

    return this.$http.tvm.notAuth.get(path, params)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method }))
  },
  /**
   * Retrieve Evening programs by channels
   * @param mco
   * @param includeEmptyChannels
   * @param limit
   * @returns {Q.Promise<never>}
   */
  getEveningProgramsByChannels ({ mco, includeEmptyChannels = null, limit }) {
    const path = urlJoin(
      'applications',
      this.getSTBApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.programs')
    )

    const params = {
      params: {
        groupBy: 'channel',
        epgIds: 'all',
        mco,
        period: `${programsUtils.getEveningStartTime()},${programsUtils.getEveningEndTime()}`,
        limit
      },
      cache: {
        exclude: { query: false }
      }
    }
    const method = 'api.programs.getEveningProgramsByChannels()'

    if (includeEmptyChannels !== null) {
      params.params.includeEmptyChannels = includeEmptyChannels
    }

    return this.$http.tvm.notAuth.get(path, params)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method }))
  },
  /**
   * Retrieve programs by channel - filtered by period/genre
   * @param mco
   * @param period
   * @param genres
   * @param epgIds
   * @returns {Q.Promise<never>}
   */
  getProgramsByChannels ({ mco, period, genres, after, before, limit }) {
    const path = urlJoin(
      'applications',
      this.getSTBApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.programs')
    )

    const params = {
      params: {
        groupBy: 'channel',
        includeEmptyChannels: false,
        period,
        mco
      },
      cache: {
        exclude: { query: false }
      }
    }

    if (before) {
      params.params.before = before
    }

    if (after) {
      params.params.after = after
    }

    if (limit) {
      params.params.limit = limit
    }

    if (genres) {
      // FDE : convert genres to string because Array format doesn't seem to work very well api side
      params.params.genres = genres.join(',')
    }
    const method = 'api.programs.getProgramsByChannelAndPeriod()'

    return this.$http.tvm.notAuth.get(path, params)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method }))
  }
}
