export default {
  /**
   * Get Genres
   * @param state
   * @returns {*}
   */
  getGenres (state) {
    return state.genres
  },
  /**
   * Get FormatedGenres
   * @param state
   * @returns {*}
   */
  getFormatedGenres (state) {
    return state.formatedGenres
  },
  /**
   * Get Selected Filters
   * @param {Object} state
   * @returns {Array}
   */
  getSelectedFilters (state) {
    return state.selectedFilters
  }
}
