function onStartRecord (channel, programDetailedId, recordName) {
  return {
    page: 'player',
    zone: 'control',
    area: 'enregistrer',
    cible: `${channel.idEPG}-${channel.name}-/programmes/${programDetailedId}/${recordName}`,
    type: 'clic'
  }
}

function onStopRecord (channel, currentProgramDetailed) {
  return {
    page: 'player',
    zone: 'control',
    area: 'arreter_enregistrement',
    cible: `${channel.idEPG}-${channel.name}-/programmes/${currentProgramDetailed.id}/${currentProgramDetailed.title}`,
    type: 'clic'
  }
}

function onKeepAlive (channelData) {
  return {
    page: 'player',
    zone: 'calcul',
    area: 'keepalive_session',
    cible: channelData.id + '-' + channelData.name,
    detail: 'keepalive_session-calcul-player'
  }
}

function onChangeChannelClick (channel) {
  return {
    area: 'vzl-choix_programme',
    cible: `${channel.id}-${channel.name}-/programmes/${channel.currentProgram.id}/${channel.currentProgram.title}`
  }
}

function onChangeChannelValid (channel) {
  return {
    area: 'numero_chaine-valide',
    cible: `${channel.id}-${channel.name}-/programmes/${channel.currentProgram.id}/${channel.currentProgram.title}`,
    type: 'valide'
  }
}

function onPlayInLive (channel) {
  return {
    area: 'retour_au_direct',
    cible: `${channel.id}-${channel.name}-/programmes/${channel.currentProgram.id}/${channel.currentProgram.title}`
  }
}

function onPreviousNextZoneTap (channel, previous) {
  return {
    area: previous ? 'chaine_precedente' : 'chaine_suivante',
    cible: `${channel.id}-${channel.name}-/programmes/${channel.currentProgram.id}/${channel.currentProgram.title}`,
    type: 'double_tap_clic'
  }
}

function onPreviousNextKeyPress (channel, previous) {
  return {
    area: previous ? 'chaine_precedente' : 'chaine_suivante',
    cible: `${channel.id}-${channel.name}-/programmes/${channel.currentProgram.id}/${channel.currentProgram.title}`,
    type: 'fleche'
  }
}

function onPreviousNextClick (channel, previous) {
  return {
    area: previous ? 'chaine_precedente' : 'chaine_suivante',
    cible: `${channel.id}-${channel.name}-/programmes/${channel.currentProgram.id}/${channel.currentProgram.title}`,
    type: 'clic'
  }
}

export default {
  onStartRecord,
  onStopRecord,
  onKeepAlive,
  onChangeChannelClick,
  onChangeChannelValid,
  onPlayInLive,
  onPreviousNextZoneTap,
  onPreviousNextKeyPress,
  onPreviousNextClick
}
