import Vue from 'vue'

function mapPlayHistory (state, playHistory) {
  state.recordings.forEach((recording) => {
    Vue.set(
      recording,
      'playHistory',
      playHistory.find(item => recording.recordingId === item.id)
    )
  })
}

export default {
  /**
   * Set User
   * @param state
   * @param user
   */
  setUser (state, user) {
    state.user = user
  },
  /**
   * Set Status
   * @param state
   * @param status
   */
  setStatus (state, status) {
    state.status = status
  },
  /**
   * Set Recordings
   * @param state
   * @param recordings
   */
  setRecordings (state, recordings) {
    state.recordings = recordings
    if (state.playHistoryHaveBeenCalled) {
      mapPlayHistory(state, state.playHistory)
    }
  },
  /**
   * Set layout from wich we're comming
   * @param state
   * @param fromLayout
   */
  setFromLayout (state, fromLayout) {
    state.fromLayout = fromLayout
  },

  /**
   *
   * @param state
   * @param recordingId
   */
  deleteRecording (state, recordingId) {
    state.recordings.splice(state.recordings.findIndex(recording => recording.recordingId === recordingId), 1)
  },
  /**
   * @param {*} state
   * @param {*} playHistory
   */
  setPlayHistory (state, playHistory) {
    state.playHistory = playHistory // needed to remap playHistory client side
    mapPlayHistory(state, playHistory)

    state.playHistoryHaveBeenCalled = true
  },
  /**
   * @param {*} state
   * @param {*} singlePlayHistory
   */
  setSinglePlayHistory (state, playHistory) {
    const recording = state.recordings.find((recording) => {
      return recording.recordingId === playHistory.id
    })

    Vue.set(recording, 'playHistory', playHistory)
  },
  /**
   * @param {*} state
   * @param {*} articleId
   * @param {*} timecode
   */
  setPlayHistoryTimecode (state, { articleId, timecode }) {
    const recording = state.recordings.find(recording => recording.recordingId === articleId)

    if (recording) {
      Vue.set(recording.playHistory, 'timecode', timecode)
    }
  }
}
