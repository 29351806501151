import _get from 'lodash/get'

export default {
  /**
   * Fetch user from gateway API
   * @returns {Promise<any | never>}
   */
  getUser () {
    const options = {
      params: {
        customerTarget: _get(this.$config, 'confetti.public.api.user.params.customerTarget')
      }
    }
    const method = 'api.user.getUser()'
    return this.$http.user.auth.get('', options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method, type: this.$httpUtils.API_TYPE.USER }))
  }
}
