<template>
  <div id="app">
    <div class="box">
      <page-title />
      <nuxt />
      <wptv-scroll-top-button
        :title="$t('general.scrollTop')"
        :tealium="$stats.global.onClickScrollTopButton(page)"
        tab-target-id="o-search-input"
        data-wptv="mosaique-retour_haut" />
    </div>

    <component
      :is="player"
      v-if="player"
      ref="player"
      @player-loaded="play"
      @close-player="setFocusOnPlayerClose" />

    <component
      :is="activeModal"
      v-if="showModal"
      ref="activeModal"
      v-bind="activeModalProps"
      @closed="closeModal" />

    <assistance />
    <footer>
      <wptv-footer :used-o-s="os" />
    </footer>
    <wptv-snackbar />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import commonElements from '../components/shared/commonElements/commonElements.mixin'
import PageTitle from '../components/shared/pageTitle/PageTitle'
import Assistance from '../components/shared/assistance/Assistance'
import playerLiveMixin from '../components/shared/player/playerLive/player-live.mixin'
import { WptvScrollTopButton } from '@wptv/components/ui/scrollTopButton'
import { WptvSnackbar } from '@wptv/components/ui/snackbar'
import { WptvFooter } from '@wptv/components/ui/footer'
import { setFocusOnFirstFocusableChild } from '@wptv/components/utils'
import modals from '../components/shared/modals/modals.mixin'

export default {
  name: 'FipLayout',
  components: {
    WptvScrollTopButton,
    WptvSnackbar,
    WptvFooter,
    Assistance,
    PageTitle
  },
  mixins: [
    commonElements,
    playerLiveMixin,
    modals
  ],
  data () {
    return {
      os: ''
    }
  },
  computed: {
    ...mapGetters({
      page: 'meta/getPage',
      currentPrograms: 'programs/getCurrentProgramsByChannels',
      osName: 'useragent/getOsName'
    })
  },
  mounted () {
    if (!Object.keys(this.currentPrograms).length) {
      this.$store.dispatch('programs/getCurrentProgramsByChannels')
    }
    // done to avoid error if we use the getter 'getOsName' before we set the useragent
    this.os = this.osName
  },
  beforeDestroy () {
    if (this.$refs.player) {
      this.$refs.player.togglePipMode(false)
    }
  },
  methods: {
    setFocusOnPlayerClose () {
      setFocusOnFirstFocusableChild(document.getElementsByClassName('poster-wrapper')[0])
    }
  }
}
</script>
