export default {
  /**
   * Retrieve autopromo Data and store them
   * @param commit
   * @param rootGetters
   * @param dispatch
   * @returns {Promise<unknown>}
   */
  getAutopromo ({ commit, rootGetters, dispatch }) {
    return this.$api.external.autopromo.getAutopromo(rootGetters['useragent/isCompatibleMobile'])
      .then(autopromo => commit('setAutopromo', autopromo))
      .catch(err => dispatch('errors/setAxiosError', { error: { ...err } }, { root: true }))
  }
}
