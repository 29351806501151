import { minify } from '../shared/utils'

/**
 * Get OAds
 * @param env
 * @returns {*|string}
 */
export default (env) => !env.analyze ? minify(`
    var oan_protocol=(window.location.protocol === 'https:') ? 'https:' : 'http:';
    var oan_pageId = "ora_tv.direct.js";
    var oan_urlMed = oan_protocol + "//cdn.adgtw.orangeads.fr/mediation/" + oan_pageId;
    var adgw_js = document.createElement("script");
    adgw_js.src = oan_urlMed;

    document.getElementsByTagName("head")[0].appendChild(adgw_js);
`) : ''
