import _filter from 'lodash/filter'
/**
 *
 * @param programsByChannels
 * @param newProgramsForChannel
 * @param channel
 * @private
 */
function _addToProgramsByChannels (programsByChannels, newProgramsForChannel, channel) {
  if (newProgramsForChannel) {
    const emptyProgram = programsByChannels.find(item => Object.keys(item.channel).length === 0)

    emptyProgram.channel = channel
    emptyProgram.programs = newProgramsForChannel
  }
}

/**
 * Remove placeholder if no new programs (length equal to 1)
 * @param programsByChannel
 * @param newPrograms
 * @returns {boolean}
 * @private
 */
function _removePlaceholder (programsByChannel, newPrograms, before) {
  return !!before || Object.keys(newPrograms).length !== 1 || Object.keys(programsByChannel.channel).length !== 0
}

/**
 * filters channels that have not been added to program list yet
 * @param {*} channels
 * @param {*} programsByChannels
 */
function _filterMissingChannels (channels, programsByChannels) {
  return _filter(channels, channel => !programsByChannels.some(program => program.channel.idEPG === channel.idEPG))
}

// -----------------------------------------------------------------------------

export default {
  /**
   * Set selected period
   * @param state
   * @param period
   */
  setPeriod (state, period) {
    state.period = period
  },
  /**
   * Set selected timezone
   * @param state
   * @param selectedTimezone
   */
  setSelectedTimezone (state, selectedTimezone) {
    state.selectedTimezone = selectedTimezone
  },
  /**
   * Set selected date
   * @param state
   * @param selectedDate
   */
  setSelectedDate (state, selectedDate) {
    state.selectedDate = selectedDate
  },
  /**
   * Set scroll position in store to come back to the previous position if coming from another page
   * @param state
   * @param scrollWidth
   * @param scrollPosition
   * @param currentProgramsByChannelsWidth
   * @param columnCount
   */
  setScrollPosition (state, { scrollWidth, scrollPosition, currentProgramsByChannelsWidth, columnCount }) {
    if (scrollPosition <= 0) {
      state.scrollPosition = 0
    } else if (currentProgramsByChannelsWidth && (scrollPosition + scrollWidth) > currentProgramsByChannelsWidth) {
      const columnWidth = columnCount > 0 ? Math.round(scrollWidth / columnCount) : 0
      state.scrollPosition = currentProgramsByChannelsWidth - (scrollWidth - columnWidth)
    } else {
      state.scrollPosition = scrollPosition
    }
  },
  /**
   * Set programsByChannelsWidth
   * @param state
   * @param programsByChannelsWidth
   */
  setProgramsByChannelsWidth (state, programsByChannelsWidth) {
    state.programsByChannelsWidth = programsByChannelsWidth
  },
  /**
   * Reset programsByChannelsWidth
   * @param state
   */
  resetProgramsByChannelsWidth (state) {
    state.programsByChannelsWidth = 0
  },
  /**
   * Init or reset the programs by Channels - create the structure using channelsSTB
   * @param state
   * @param channels
   */
  initProgramsByChannels (state, channels) {
    state.programsByChannels = channels.map(channel => ({
      channelId: channel.idEPG,
      channel: {},
      programs: []
    }))
  },
  /**
   * Set/Add programs by channels to the existing store value
   * @param state
   * @param programs
   * @param channelsSTB
   */
  setProgramsByChannels (state, { newPrograms, channels, before }) {
    const filteredChannels = _filterMissingChannels(channels, state.programsByChannels)
    state.programsByChannels = filteredChannels
      .reduce((programsByChannels, channel) => {
        _addToProgramsByChannels(programsByChannels, newPrograms[channel.idEPG], channel)
        return programsByChannels
      }, state.programsByChannels)
      .filter(programsByChannel => _removePlaceholder(programsByChannel, newPrograms, before))
  }
}
