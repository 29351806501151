export default {
  broadcastDate: `{date}`,
  duration: `{duration}min`,
  delete: 'Supprimer',
  deleteLabel: 'Supprimer cette programmation',
  edit: 'Modifier',
  editLabel: 'Modifier cette programmation',
  availableRecordingsTitle: 'Enregistrements disponibles',
  scheduledRecordingsTitle: 'Enregistrement programmés',
  availableRecordingsDesc: `Enregistrements disponibles TV d'Orange`,
  scheduledRecordingsDesc: `Enregistrements programmés TV d'Orange`,
  fipDesc: `Enregistrement {title} TV d'Orange`,
  emptyAvailable: `Vous n'avez pas encore de programmes enregistrés disponibles.`,
  emptyScheduled: ` Vous n’avez pas encore programmé d’enregistrement.`,
  titleDefault: 'Enregistrement',
  broadcastTime: 'diffusé le {date}',
  broadcast: 'Diffusé'
}
