export default {
  /**
   * Get Previous route
   * @param state
   * @returns {{}|{name: *}|string}
   */
  getPreviousRoute (state) {
    return state.previousRoute
  },
  /**
   * Get showAll from Home view
   * @param state
   * @returns {boolean}
   */
  getShowAllHome (state) {
    return state.showAllHome
  },
  /**
   * Get showAll from Evening view
   * @param state
   * @returns {boolean}
   */
  getShowAllEvening (state) {
    return state.showAllEvening
  },
  /**
   * Get showAll from AvailableRecordings view
   * @param state
   * @returns {boolean}
   */
  getShowAllAvailableRecordings (state) {
    return state.showAllAvailableRecordings
  },
  /**
   * Get showAll from scheduledRecordings view
   * @param state
   * @returns {boolean}
   */
  getShowAllScheduledRecordings (state) {
    return state.showAllScheduledRecordings
  },
  /**
   * Get Show modal
   * @param state
   * @returns {boolean|(() => void)}
   */
  getShowModal (state) {
    return state.showModal
  },
  /**
   * Get first  modal to open if any
   * @param state
   * @returns {*|null}
   */
  getFirstModalToOpen (state) {
    return state.modalsToOpen.length !== 0 ? state.modalsToOpen[state.modalsToOpen.length - 1] : null
  },
  /**
   * Get first  modal to open if any
   * @param state
   * @returns {*|null}
   */
   getFreeAccessChannelsAcknowledged (state) {
    return state.freeAccessChannelsAcknowledged
  }
}
