export default {
  /**
   * Set Channels
   * @param state
   * @param channels
   */
  setChannels (state, channels) {
    state.channels = channels.sort((a, b) => a.lcn > b.lcn ? 1 : -1)
  },
  /**
   * Set Channels
   * @param state
   * @param channels
   */
  setPCChannels (state, channels) {
    state.channelsPC = channels.sort((a, b) => a.lcn > b.lcn ? 1 : -1)
  },
  /**
   * Set ChannelStream
   * @param state
   * @param stream
   */
  setChannelStream (state, stream) {
    state.channelStream = stream
  },
  /**
   * Set FreeAccessChannels
   * @param state
   * @param freeAccessChannels
   */
  setFreeAccessChannels (state, freeAccessChannels) {
    state.freeAccessChannels = freeAccessChannels
  },
  /**
   * Set ChannelIdWillFocus
   * @param state
   * @param id
   */
  setChannelIdWillFocus (state, id) {
    state.channelIdWillFocus = id
  },
  /**
   * reset ChannelIdWillFocus
   * @param state
   */
  resetChannelIdWillFocus (state) {
    state.channelIdWillFocus = ''
  }
}
