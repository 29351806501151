export default {
  /**
   * Fetch and store Genres
   * @param commit
   * @param dispatch
   * @returns {Q.Promise<unknown>}
   */
  getGenres ({ commit, dispatch }) {
    return this.$api.tvm.genres.getGenres()
      .then((genres) => {
        commit('setGenres', genres)
        commit('setFormatedGenres', genres)
      })
      .catch(err => dispatch('errors/setAxiosError', { error: { ...err } }, { root: true }))
  }
}
