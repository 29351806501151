import _get from 'lodash/get'

export default {
  /**
   * Get Autopromo carousel informations
   * @param state
   * @returns {*[]|*}
   */
  getAutopromoCarrousel (state) {
    const carrousel = _get(state.autopromo, '0.TV.carrousel')
    return _get(carrousel, '[0].coredata.main') === 'ZZZ' ? [] : carrousel
  }
}
