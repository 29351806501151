import _get from 'lodash/get'
import axios from 'axios'

export default {
  /**
   * Fetch Autopromo from advise
   * @param isCompatibleMobile
   * @returns {Promise<T | never>}
   */
  getAutopromo (isCompatibleMobile) {
    const path = _get(this.$config, 'confetti.public.advise.baseUrl')
    const method = 'api.autopromo.getAutopromo()'

    return axios.get(path, {
      headers: { accept: 'application/json' },
      withCredentials: true,
      params: {
        targets: _get(this.$config, 'confetti.public.advise.target'),
        canal: isCompatibleMobile ? _get(this.$config, 'confetti.public.advise.canalMobile') : _get(this.$config, 'confetti.public.advise.canalDesktop'),
        canalPhysique: _get(this.$config, 'confetti.public.advise.canalPhysique')
      }
    })
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method, bypass: true }))
  }
}
