export default {
  ORIGIN: 'LIVE',
  ERRORS_CODES: {
    NOT_AUTHENTIFIED: ['41', '40'],
    NOT_ELIGIBLE: ['2306'],
    NOMADISM_FORBIDDEN: ['2380', '2400'],
    CHANNEL_NOT_SUBSCRIBED: ['2381', '2401'],
    NOT_UE: ['2308'],
    SIMULTANEOUS_REACH: ['2382', '2402'],
    MAX_TERMINALS: ['2383', '2403'],
    TECHNICAL: ['2301', '2305', '2302', '61', '62', '1', '68', '2309', '2303', '2300', '5'],
    NOT_AVAILABLE: ['60'],
    MAX_QUOTA_EXCEEDED: ['2444', '2449'],
    SIMULTANEOUS_RECORDING_REACH: ['2440'],
    STB_ONLY: ['2461'],
    NPVR_SIMULTANEOUS_REACH: ['2462', '2470', '21'],
    NPVR_OCCULTATION: ['6', '2486'],
    NPVR_MAX_TERMINALS: ['2463']
  },
  ERROR_TOKEN: {
    PREFIX: 'TOKEN_',
    TOKEN_TIMEOUT: 'TIMEOUT',
    ECONNABORTED: 'ECONNABORTED'
  }
}
