import _get from 'lodash/get'

export default {
  /**
   * Fetch Free Access Channel Info
   * @param mco
   * @returns {Promise<T | never>}
   */
  getFreeAccessChannels ({ mco }) {
    const path = _get(this.$config, 'confetti.public.api.internal.paths.freeAccessChannels')
    const method = 'api.channels.getFreeAccessChannels()'

    const options = {
      params: { mco },
      cache: {
        exclude: { query: false }
      }
    }

    return this.$http.internal.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, method }))
  }
}
