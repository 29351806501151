import _get from 'lodash/get'
import getOCommon from '../../common-scripts/oCommon'
import getOAds from '../../common-scripts/oAds'

/**
 * Core module added to store
 * @param options
 * @returns {CancelToken|string|*}
 */
export default options => ({
  namespaced: true,
  state: {
    pageConfig: {},
    instanceInfo: {},
    wassupPULO: '',
    wassupHeaders: {},
    profile: {},
    networkInfo: {},
    errorOnTvTokenInit: null
  },
  mutations: {
    /**
     * Set error state
     * @param state
     * @param error
     */
    setErrorOnTokenTvInit (state, error) {
      state.errorOnTvTokenInit = error
    },
    /**
     * Set Network Info
     * @param state
     * @param networkInfo
     */
    setNetworkInfo (state, networkInfo) {
      state.networkInfo = networkInfo || {
        inAllowedCountry: true,
        isNomad: true
      }
    },
    /**
     * Set Profile
     * @param state
     * @param profile
     */
    setProfile (state, profile) {
      state.profile = profile
    },
    /**
     * Set PageConfig
     * @param state
     * @param pageConfig
     */
    setPageConfig (state, { req, $config, env }) {
      state.pageConfig = {
        maxymiserTest: $config.confetti?.public?.abTesting?.campaignEnabled || false,
        oCommon: getOCommon(env, $config),
        idZone: !env.dev && !env.analyze && req.idZoneUrl ? req.idZoneUrl : '',
        oLoadUrl: !env.analyze ? _get($config, 'confetti.private.commonElements.oLoadUrl') : '',
        oAds: getOAds(env),
        allowPageIndexing: _get($config, 'confetti.private.allowPageIndexing'),
        preconnects: [
          { rel: 'preconnect', href: _get($config, 'confetti.public.root.rp'), crossorigin: 'anonymous' },
          { rel: 'preconnect', href: 'https://cps.purpledrm.com', crossorigin: 'anonymous' },
          { rel: 'preconnect', href: 'https://proxymedia.woopic.com', crossorigin: 'anonymous' }
        ]
      }
    },
    /**
     * Set Instance info, containing token tv init and claims used for setting user profile
     * @param state
     * @param instanceInfo
     */
    setInstanceInfo (state, instanceInfo) {
      state.instanceInfo = instanceInfo || {}
    },
    setToken (state, token) {
      state.instanceInfo.token = token
    },
    /**
     * Set wassup header for auth requests
     * Needed to to auth calls on front side in dev
     * @param state
     * @param wassupHeaders
     */
    setWassupHeaders (state, { headers, env }) {
      state.wassupHeaders = env.dev || env.analyze ? {
        'x-wassup-sid': headers['x-wassup-sid'],
        x_wassup_uit: headers.x_wassup_uit,
        x_wassup_ulv: headers.x_wassup_ulv,
        x_wassup_nat: headers.x_wassup_nat,
        x_wassup_bearer: headers.x_wassup_bearer
      } : {}
    },
    /**
     * Set Wassup PULO in store from wassup headers
     * @param state
     * @param headers
     */
    setWassupPULO (state, headers) {
      state.wassupPULO = headers.x_wassup_pulo || ''
    },
  },
  getters: {
    /**
     * Retrieve error status
     * @param state
     * @returns {*}
     */
    getErrorOnTvTokenInit (state) {
      return state.errorOnTvTokenInit
    },
    /**
     * Retrieve network info
     * @param state
     * @returns {{}|*}
     */
    getNetworkInfo (state) {
      return state.networkInfo
    },
    /**
     * Check is in allowed country
     * @param state
     * @returns
     */
    isUe (state) {
      return _get(state.networkInfo, 'inAllowedCountry', false)
    },
    /**
     * Get Profile
     * @param state
     * @returns {*}
     */
    getProfile (state) {
      return state.profile
    },
    /**
     * Retrieve instance info
     * @param state
     * @returns {function(): *}
     */
    getInstanceInfo (state) {
      return state.instanceInfo
    },
    /**
     * Get Page config with common elements, ads.. etc
     * @param state
     * @returns {function(): *}
     */
    getPageConfig (state) {
      return state.pageConfig
    },
    /**
     * Retrieve wassup headers for auth
     * @param state
     * @returns {{}|*}
     */
    getWassupHeaders (state) {
      return state.wassupHeaders
    },
    /**
     * Retrieve tv init token
     * @param state
     * @returns {CancelToken}
     */
    getTokenTvInit (state) {
      return state.instanceInfo.token
    },
    /**
     * Get wassup PULO
     * @param state
     */
    getWassupPULO (state) {
      return state.wassupPULO
    }
  }
})
