import { DateTime } from 'luxon'

export default {
  /**
   * Set Id To Focus
   * @param state
   * @param id
   */
  setIdToFocus (state, id) {
    state.idToFocus = id
  },
  /**
   * Set user
   * @param state
   * @param user
   */
  setUser (state, user) {
    state.user = user
  },
  /**
   * Set Time Offset
   * @param state
   * @param serverDate
   */
  setTimeOffset (state, serverDate) {
    state.timeOffset = serverDate ? DateTime.local().toMillis() - DateTime.fromHTTP(serverDate).setZone('Europe/Paris').toMillis() : 0
  }
}
