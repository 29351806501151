import _get from 'lodash/get'

export default {
  /**
   * Retrieve the user information and store it
   * @param commit
   * @param dispatch
   * @param getters
   * @returns {Promise<T>}
   */
  getUser ({ commit }) {
    return this.$api.user.getUser().then(response => commit('setUser', response))
  },
  /**
   * Retrieve user status and store it
   * @param commit
   * @param dispatch
   * @returns {Promise<T>}
   */
  getStatus ({ commit, dispatch }) {
    return this.$api.gateway.recordings.getStatus()
      .then(response => commit('setStatus', response.status))
      .catch(err => dispatch('errors/setGatewayError', { error: { ...err } }, { root: true }))
  },
  /**
   * Retrieve user's recordings
   * @param commit
   * @param dispatch
   * @param customerOrangePopulation
   * @param bypass
   * @returns {Promise<T>}
   */
  getRecordings ({ commit, dispatch }, { customerOrangePopulation, bypass = false }) {
    return this.$api.gateway.recordings.getRecordings(customerOrangePopulation)
      .then(response => commit('setRecordings', response.recordings))
      .catch(err => dispatch('errors/setGatewayError', { error: { ...err } }, { root: true, bypass }))
  },

  /**
   * delete user's recordings
   * @param commit
   * @param dispatch
   * @param recordingId
   * @param recording
   * @returns {Promise<unknown>}
   */
  deleteRecording ({ commit, dispatch }, recordingId) {
    return this.$api.gateway.recordings.deleteRecording(recordingId)
      .then(() => {
        commit('deleteRecording', recordingId)
        dispatch('getStatus')
      })
  },
  /**
   * modify user's recordings
   * @param commit
   * @param dispatch
   * @param getters
   * @param recording
   * @param queryParams
   * @returns {Promise<T>}
   */
  updateRecording ({ commit, dispatch, rootGetters }, { recording, queryParams }) {
    return this.$api.gateway.recordings.updateRecording(recording.recordingId, queryParams)
      .then(() => {
        return Promise.all([
          dispatch('getRecordings', {
            customerOrangePopulation: rootGetters['npvr/getCustomerOrangePopulation']
          }),
          dispatch('getStatus')
        ])
      })
      .catch((error) => {
        dispatch('errors/setGatewayError', {
          error: { ...error, programName: _get(recording, 'name'), channelName: _get(recording, 'channel.name') }
        }, { root: true })
        return Promise.reject()
      })
  },

  /**
   *
   * @param commit
   * @param dispatch
   * @param rootGetters
   * @param recording
   * @param bypass
   * @returns {Promise<unknown>}
   */
  createRecording ({ commit, dispatch, rootGetters }, { recording, channelName, bypass = false }) {
    return this.$api.gateway.recordings.createRecording(recording)
      .then(() => {
        dispatch('getRecordings', {
          customerOrangePopulation: rootGetters['npvr/getCustomerOrangePopulation']
        })
        dispatch('getStatus')
      })
      .catch((error) => {
        dispatch('errors/setGatewayError', {
          error: { ...error, programName: _get(recording, 'name'), channelName }
        }, { root: true, bypass })
        return Promise.reject(error)
      })
  },

  /**
   *
   * @param commit
   * @param dispatch
   * @param recordingId
   * @param params
   * @returns {Promise<postcss.Result> | Q.Promise<any>}
   */
  getPlayRecording ({ commit, dispatch }, { recordingId, params }) {
    return this.$api.gateway.recordings.getPlayRecording(recordingId, params)
      .catch((err) => {
        dispatch('errors/setGatewayError', { error: { ...err } }, { root: true })
        return Promise.reject(err)
      })
  },
  /**
   * @param commit
   * @param dispatch
   * @returns {Promise}
   */
  fetchAllPlayHistory ({ commit, dispatch }) {
    return this.$api.gateway.playHistory.getAllPlayHistory()
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response, bypass: true }, { root: true })
        } else {
          commit('setPlayHistory', response)
        }
      })
  },
  /**
   * @param commit
   * @param dispatch
   * @param itemId
   * @returns {Promise}
   */
  fetchSinglePlayHistoryById ({ commit, dispatch }, itemId) {
    return this.$api.gateway.playHistory.getSinglePlayHistory(itemId)
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response, bypass: true }, { root: true })
        } else {
          commit('setSinglePlayHistory', response)
        }
      })
  },
  /**
   * Update the video timecode
   * @param commit
   * @param articleId
   * @param timecode
   * @returns {Promise}
   */
  updateTimecode ({ commit, dispatch }, { articleId, timecode }) {
    return this.$api.gateway.playHistory.updateTimecode(articleId, timecode)
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response, bypass: true }, { root: true })
        } else {
          commit('setPlayHistoryTimecode', { articleId, timecode })
          dispatch('fetchSinglePlayHistoryById', articleId)
        }
      })
  }
}
