import _last from 'lodash/last'

export default {
  /**
   * Get last Error
   * @param state
   */
  getLastError (state) {
    return _last(state.errors)
  },
  /**
   * get Error count
   * @param state
   * @returns {*}
   */
  getErrorCount (state) {
    return state.errors.length
  }
}
