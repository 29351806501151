import _get from 'lodash/get'

export default {
  /**
   * Return true if coming from fip
   * @param state
   * @returns {boolean}
   */
  getFromFip (state) {
    return state.fromFip
  },
  /**
   * Return specific program in store
   * @param state
   * @returns {{}|{toto: number}}
   */
  getOneProgram (state) {
    return state.oneProgram
  },
  /**
   * Retrieve currentProgramsByChannels
   * @param state
   * @returns {{}|[]|{'1': [{duration: number, diffusionDate: number, id: number}, {duration: number, diffusionDate, id: number}, {id: number}]}|{'1': [{duration: number, diffusionDate: number, id: number}, {duration: number, diffusionDate, id: number}]}}
   */
  getCurrentProgramsByChannels (state) {
    return state.currentProgramsByChannels
  },
  /**
   * Return current programs for Home
   * @param state
   * @returns {*}
   */
  getCurrentPrograms (state) {
    return state.currentPrograms
  },
  /**
   * Search current program and return following index if available
   * @param {*} state
   * @param {*} getters
   * @param {*} rootState
   * @param {*} rootGetters
   */
  getNextPrograms (state, getters, rootState, rootGetters) {
    return (channelId) => {
      const time = Date.now() - rootGetters['user/getTimeOffset']
      const currentProgramsByChannel = _get(state.currentProgramsByChannels, channelId, [])
      const currentProgramIndex = currentProgramsByChannel.findIndex(currentProgram => (
        currentProgram.diffusionDate * 1000 <= time &&
        (currentProgram.diffusionDate + currentProgram.duration) * 1000 >= time
      ))

      return currentProgramIndex !== -1 ? _get(currentProgramsByChannel, currentProgramIndex + 1, {}) : {}
    }
  },
  /**
   * Get Evening programs by Channels
   * @param state
   * @returns {{}}
   */
  getEveningProgramsByChannels (state) {
    return state.eveningProgramsByChannels
  }
}
