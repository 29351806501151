export default {
  openGraph: {
    name: `WEB TV ORANGE`,
    defaultTitle: `TV d'Orange`,
    defaultDescription: `Programmes TV ORANGE`
  },
  twitter: {
    defaultDescription: `TV en direct et en replay où et quand vous le voulez`
  }
}
