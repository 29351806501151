<template>
  <div class="assistance">
    <a
      v-tealium:click="$stats.global.onClickAssistance(page)"
      :href="assistanceLink"
      data-wptv="assistance"
      target="_blank"
      rel="noopener"
      class="div-link">
      <wptv-icon
        aria-hidden="true"
        width="30px"
        height="44px"
        name="assistance"
        class="icon-assistance" />
      {{ $t('general.assistance') }}
      <wptv-icon
        :mirror="true"
        aria-hidden="true"
        class="arrow-icon-assistance"
        name="arrow-left-orange"
        height="15px"
        width="13px" />
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { WptvIcon } from '@wptv/components/ui/icon'

export default {
  name: 'Assistance',
  components: { WptvIcon },
  computed: {
    ...mapGetters({
      page: 'meta/getPage'
    }),
    assistanceLink () {
      if (this.page === 'enregistrements-disponibles') {
        return this.$config.confetti.public.urls.support.npvrAssistance
      } else if (this.page === 'enregistrements-programmes') {
        return this.$config.confetti.public.urls.support.npvrAssistance
      } else if (this.page === 'fip_npvr') {
        return this.$config.confetti.public.urls.support.npvrAssistance
      } else {
        return this.$config.confetti.public.urls.support.liveAssistance
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .assistance {
    padding: 5px 0;
    @include mediaQueries('margin', (large: 0 auto));
    max-width: 1440px;

    .div-link {
      font-weight: 700;
      cursor: pointer;
      max-width: 300px;
      font-size: 16px;
      margin: 0 calc(1.5625% + 15px);
      @include mediaQueries('margin', (medium:0 30px, large:0 45px, xlarge:0 calc(3.125% + 15px)));

      .arrow-icon-assistance {
        margin-left: 8px;
      }
    }
  }
</style>
