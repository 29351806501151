import urlJoin from 'url-join'
import _get from 'lodash/get'

export default {
  domain: 'NPVR',

  /**
   * @returns {Promise}
   */
  getAllPlayHistory () {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.articles')
    )
    const method = 'api.playHistory.getAllPlayHistory()'

    return this.$http.gateway.playHistory.auth.get(path, {
      params: {
        detailed: false,
        domains: this.domain,
        onlyActive: false
      }
    }).then(response => this.$httpUtils.parseResponse(response, true))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },

  /**
   * @param articleId
   * @returns {Promise}
   */
  getSinglePlayHistory (articleId) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.articles'),
      articleId
    )
    const method = 'api.playHistory.getSinglePlayHistory()'

    return this.$http.gateway.playHistory.auth.get(path, {
      params: {
        detailed: false,
        domain: this.domain
      }
    }).then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },

  /**
   * Update timecode of given article
   * @param articleId ID of the video
   * @param timecode Playing timecode to update
   * @returns {Promise<any | never>}
   */
  updateTimecode (articleId, timecode) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.articles'),
      articleId
    )
    const method = 'api.playHistory.updateTimecode()'

    // This PUT request doesn't give any response so, just check for errors.
    return this.$http.gateway.playHistory.auth.put(path, { timecode, domain: this.domain })
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  }
}
