export default {
  /**
   * Store error
   * @param state
   * @param err
   */
  setError (state, err) {
    state.errors.push(err)
  },

  /**
   * Clear all errors
   * @param {*} state
   */
  clear (state) {
    state.errors = []
  }
}
