export default {
  /**
   * Retrieve certificate safari data and store it
   * @param commit
   * @param dispatch
   * @param certificateUrl
   * @returns {Promise<T>}
   */
  getCertificateSafari ({ commit }) {
    return this.$api.external.certificate.getCertificate(true)
      .then(response => commit('setCertificateSafari', { certificate: response }))
      .catch(() => commit('setCertificateSafari', { certificate: '' }))
  },
  /**
   * Retrieve certificate chrome data and store it
   * @param commit
   * @returns {Q.Promise<unknown>}
   */
  getCertificateChrome ({ commit }) {
    return this.$api.external.certificate.getCertificate(false)
      .then(response => commit('setCertificateChrome', { certificate: response }))
      .catch(() => commit('setCertificateChrome', { certificate: '' }))
  }
}
